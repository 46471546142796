import { AutoFixHigh } from "@mui/icons-material";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useForm } from "react-final-form";

const templates = [
  {
    name: "ความผิดฯไม่ตรงตามประกาศ ปปง.",
    detail: "ตรวจสอบแล้วความผิดมูลฐานไม่ตรงตามประกาศ ปปง.",
  },
];

export default function PredefineRejectDesc() {
  const form = useForm();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButton = (text) => {
    form.change("status_note", text);
    handleClose();
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {templates.map((item, index) => (
          <MenuItem key={index} onClick={() => handleButton(item.detail)}>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        type="button"
        size="small"
        variant="contained"
        color="primary"
        onClick={handleMenu}
      >
        <AutoFixHigh fontSize="inherit" />
      </IconButton>
    </>
  );
}
