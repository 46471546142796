const bankAccountTypes = [
  { value: 1, label: "ผู้เสียหาย" },
  { value: 2, label: "ผู้ต้องสงสัย" },
  { value: 3, label: "บริษัท" },
  { value: 4, label: "Crypto" },
  { value: 5, label: "อื่นๆ" },
  { value: 6, label: "ม้ารับเงินแถว 1 AOC" },
];

export default bankAccountTypes;
