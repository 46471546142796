import { format, isValid } from "date-fns";

// Invalid Date is a Date, whose time value is NaN.
export const isInvalidDateObject = (value) =>
  value instanceof Date && isNaN(value);

// use value as mui picker did
// https://github.com/mui/mui-x/blob/master/packages/x-date-pickers/src/LocalizationProvider/LocalizationProvider.tsx
// export const defaultDates = {
//   minDate: new Date(Date.parse("1900-01-01T00:00:00+07:00")),
//   maxDate: new Date(Date.parse("2099-12-31T00:00:00+07:00")),
// };

// https://stackoverflow.com/questions/2587345/why-does-date-parse-give-incorrect-results
// Date.parse("1900-01-01T00:00:00Z") return UTC time, i know this wrong
// but joi Date.parse("YYYY-MM-DD") also return stored data as UTC not local time (if time 00:00 exist Date.parse return correct local)
export const defaultJoiDates = {
  minDate: new Date(Date.parse("1900-01-01T00:00:00Z")),
  maxDate: new Date(Date.parse("2099-12-31T00:00:00Z")), // give: Thu Dec 31 2099 07:00:00 GMT+0700 (Indochina Time)
  forceUTC: (date) => {
    if (isValid(date)) {
      return new Date(Date.parse(format(date, "yyyy-MM-dd'T'00:00:00'Z'")));
    }

    return new Date(NaN);
  },

  minDateTime: new Date(Date.parse("1900-01-01T00:00:00")),
  maxDateTime: new Date(Date.parse("2099-12-31T00:00:00+07:00")),
};
