const banks = {
  9: {
    id: 1,
    name: "ธนาคารกรุงเทพ",
    short_name: "BBL",
    code: "002",
    address: null,
    manager_title: null,
  },
  11: {
    id: 2,
    name: "ธนาคารกสิกรไทย",
    short_name: "KBNK",
    code: "004",
    address: null,
    manager_title: null,
  },
  7: {
    id: 3,
    name: "ธนาคารกรุงไทย",
    short_name: "KTB",
    code: "006",
    address: null,
    manager_title: null,
  },
  12: {
    id: 4,
    name: "ธนาคารทหารไทยธนชาต",
    short_name: "TTB",
    code: "011",
    address: null,
    manager_title: null,
  },
  6: {
    id: 5,
    name: "ธนาคารไทยพาณิชย์",
    short_name: "SCB",
    code: "014",
    address: null,
    manager_title: null,
  },
  10: {
    id: 6,
    name: "ธนาคารกรุงศรีอยุธยา",
    short_name: "BAY",
    code: "025",
    address: null,
    manager_title: null,
  },
  1: {
    id: 7,
    name: "ธนาคารเกียรตินาคินภัทร",
    short_name: "KKP",
    code: "069",
    address: null,
    manager_title: null,
  },
  13: {
    id: 8,
    name: "ธนาคารซีไอเอ็มบีไทย",
    short_name: "CIMBT",
    code: "022",
    address: null,
    manager_title: null,
  },
  14: {
    id: 9,
    name: "ธนาคารทิสโก้",
    short_name: "TSCO",
    code: "067",
    address: null,
    manager_title: null,
  },
  15: {
    id: 10,
    name: "ธนาคารยูโอบี",
    short_name: "UOB",
    code: "024",
    address: null,
    manager_title: null,
  },
  4: {
    id: 11,
    name: "ธนาคารไทยเครดิตเพื่อรายย่อย",
    short_name: "TCD",
    code: "071",
    address: null,
    manager_title: null,
  },
  34: {
    id: 11,
    name: "ธนาคารไทยเครดิตเพื่อรายย่อย",
    short_name: "TCD",
    code: "071",
    address: null,
    manager_title: null,
  },
  20: {
    id: 12,
    name: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    short_name: "LHB",
    code: "073",
    address: null,
    manager_title: null,
  },
  8: {
    id: 13,
    name: "ธนาคารไอซีบีซี (ไทย)",
    short_name: "ICBCT",
    code: "070",
    address: null,
    manager_title: null,
  },
  2: {
    id: 14,
    name: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
    short_name: "SME",
    code: "098",
    address: null,
    manager_title: null,
  },
  18: {
    id: 15,
    name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    short_name: "BAAC",
    code: "034",
    address: null,
    manager_title: null,
  },
  31: {
    id: 16,
    name: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
    short_name: "EXIM",
    code: "035",
    address: null,
    manager_title: null,
  },
  5: {
    id: 17,
    name: "ธนาคารออมสิน",
    short_name: "GSB",
    code: "030",
    address: null,
    manager_title: null,
  },
  16: {
    id: 18,
    name: "ธนาคารอาคารสงเคราะห์",
    short_name: "GHB",
    code: "033",
    address: null,
    manager_title: null,
  },
  17: {
    id: 19,
    name: "ธนาคารอิสลามแห่งประเทศไทย",
    short_name: "ISBT",
    code: "066",
    address: null,
    manager_title: null,
  },
  // {
  //   id: 20,
  //   name: "ไม่ทราบ",
  //   short_name: "NONE",
  //   code: null,
  //   address: null,
  //   manager_title: null,
  // },
  3: {
    id: 21,
    name: "ธนาคารแห่งประเทศจีน",
    short_name: "BOC",
    code: "052",
    address: null,
    manager_title: null,
  },
};

export default banks;
