import { Divider, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import * as React from "react";
import { Form } from "react-final-form";
import SubmitBtn from "../../../SubmitBtn";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import FieldInput from "../../../form-fields/FieldInput";

function Hr03MeetingModal({ open, handleClose, onSubmit, step }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    height: step === 8 ? 470 : 350,
    width: 500,
  };

  const validateRules = () => ({
    ...(step === 8
      ? {
          num_of_card_id: Joi.number().required().messages({
            "number.base": "กรุณาระบุจำนวนรายการที่เป็นตัวเลข", // "Please specify a numeric value for the number of items"
            "any.required": "กรุณาระบุจำนวนรายการ", // "Please specify the number of items"
          }),
        }
      : {}),

    hr_type: Joi.any().required().messages({
      "any.required": "กรุณาระบุหน่วยงานที่ต้องการมอบหมาย", // "Please specify the department to assign"
    }),

    aml_meeting_id: Joi.number().required().messages({
      "number.base": "กรุณาระบุครั้งที่เข้าประชุม", // "Please specify a numeric value for the meeting count"
      "any.required": "กรุณาระบุครั้งที่เข้าประชุม", // "Please specify the number of meetings attended"
    }),
  });

  const validate = (values, props) => {
    const errors = {};
    const vResult = schema().validate(values, {
      abortEarly: false,
      allowUnknown: true,
    });

    if (vResult.error) {
      let details = vResult.error.details;
      // console.log({details})
      details.forEach(({ context, message }) => {
        _.set(errors, context.label, message);
        errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
      });
    }
    return errors;
  };

  const schema = () => Joi.object(validateRules());
  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({
        handleSubmit,
        error,
        submitting,
        pristine,
        initialValues,
        submitFailed,
        form,
        values,
        loading,
        modifiedSinceLastSubmit,
      }) => {
        return (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            BackdropProps={{
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.0)",
              },
              timeout: 500,
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <Box sx={{ ...style }}>
                <h3 className=" font-medium text-lg  my-2">
                  ระบุข้อมูลสำหรับใช้ในการประมวลผล
                </h3>
                <Divider />
                <Box
                  sx={{
                    height: step === 8 ? 250 : 140,
                    mb: 8,
                  }}
                >
                  <Grid container spacing={2}>
                    {step === 8 && (
                      <Grid item xs={12} md={12} mt={2}>
                        <FieldInput
                          name="num_of_card_id"
                          label="จำนวนรายการ"
                          controlProps={{ fullWidth: true }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={12} mt={2}>
                      <FieldAsyncSelect
                        name="hr_type"
                        label="ประเภท HR"
                        controlProp={{
                          sx: {
                            width: "100%",
                          },
                        }}
                        required
                        basePath={`/api/hr_types`}
                        itemFormat={(row) => {
                          return {
                            value: row.id,
                            label: row?.code,
                          };
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} my={2}>
                      <FieldAsyncSelect
                        name="aml_meeting_id"
                        label="ครั้งที่เข้าประชุม"
                        controlProp={{
                          sx: {
                            width: "100%",
                          },
                        }}
                        required
                        basePath={`/api/hramlmeeting`}
                        itemFormat={(row) => {
                          return {
                            value: row.id,
                            label: row?.name,
                          };
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Stack direction="row" spacing={2} mt={2}>
                  <SubmitBtn
                    variant="contained"
                    submitting={loading}
                    pristine={pristine && !modifiedSinceLastSubmit}
                  >
                    ตกลง
                  </SubmitBtn>

                  <Button onClick={handleClose} variant="outlined">
                    ยกเลิก
                  </Button>
                </Stack>
              </Box>
            </form>
          </Modal>
        );
      }}
    </Form>
  );
}

export default Hr03MeetingModal;
