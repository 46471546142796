import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../apis";
import letterTypes from "../../../data/selects/letterTypes";
import requestStatusSelect from "../../../data/selects/requestStatus";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";
import { useQuery } from "react-query";
import { useFormState } from "react-final-form";

export default function AssignReportSearchForm({
  loading,
  onReset,
  handleSubmit,
  form,
  pristine,
  SetOrgNameLabel,
  SetConditionTypeNameLabel,
  SetOrgName,
  SetConditionTypeName,
  search,
}) {
  const report_static_assign_case = useSelector(
    (state) => state.auth.user?.permissions?.report_static_assign_case ?? []
  );
  const formState = useFormState();
  const didRequest = useRef(false);
  const [fetchInfo, SetFetchInfo] = useState(false);
  const [reportType, setReportType] = useState(1);

  const { data: orgCodeOptions, refetch: orgCodeRefetch } = useQuery(
    [
      "reportType",
      "/api/reports/org_code",
      { value: "code", label: "fullname" },
    ],
    () =>
      reportType &&
      api.get(`/api/reports/org/1`).then((res) => {
        return res?.data?.map((row) => ({
          value: row.ORG_CODE,
          label: row.ORG_ABBR,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    setReportType(formState.values["report_type"]);
    orgCodeOptions &&
      SetOrgNameLabel(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
  }, [formState.values]);

  useEffect(() => {
    if (search) {
      form.change("org_code", search);
      SetOrgName(orgCodeOptions.filter((el) => el.value === search)[0]?.label);
      form.submit();
    }
  }, [search]);

  useEffect(() => {
    if (!didRequest.current && orgCodeOptions) {
      SetOrgName(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
      didRequest.current = true;
    }
  }, [orgCodeOptions]);

  // {url}/api/reports/list?
  // start_date=2024-01-01&
  // end_date=2024-10-01&
  // report_type={id}&
  // org_code={org_code}&
  // condition_type={id}

  // report_type->ใช่ key จาก หน่วยงานที่ดูแล
  // org_code->ใช่ org code ของหน่วยงานตำรวจ,code ของ bank
  // condition_type->

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {report_static_assign_case.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="org_code"
              label="หน่วยงาน"
              options={orgCodeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              disabled={!reportType}
              readOnly={!reportType}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            {report_static_assign_case.includes("search") && (
              <Stack direction="row" spacing={2} alignItems="center">
                <SubmitBtn
                  variant="contained"
                  startIcon={<Search />}
                  submitting={loading}
                  pristine={pristine}
                >
                  ค้นหา
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={loading || pristine}
                  onClick={(event) => {
                    form.reset();
                    SetOrgName(
                      orgCodeOptions.filter(
                        (el) =>
                          el.value === form.getFieldState("org_code").initial
                      )[0]?.label
                    );
                    if (onReset) onReset(event);
                  }}
                >
                  Reset
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
