import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo, useCallback, useEffect } from "react";
import { format, startOfToday } from "date-fns";
import { useQuery } from "react-query";
import api from "../../../../apis";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";

const ProvidedInternally2 = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const titleChart = " ";

  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: "Kanit, sans-serif", // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#005C9E", "#B6AAFF"],
      yaxis: { title: { text: "การใช้งาน (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const {
    data: ProvidedInternally2Data,
    refetch: ProvidedInternally2DataRefetch,
    isLoading: ProvidedInternally2DataIsLoading,
    isError: ProvidedInternally2DataIsError,
  } = useQuery(["ProvidedInternally2Data", date, type], () =>
    api
      .get(`/api/dashboard/apiRequest`, {
        params: {
          date: date,
          report_type: type,
          api_type: "pol_requests",
        },
      })
      .then((res) => res.data)
  );

  const series = useCallback((data) => {
    return [
      {
        name: "Success",
        data: data?.success,
      },
      {
        name: "Fail",
        data: data?.fail,
      },
    ];
  }, []);

  const card = useMemo(() => {
    return [
      {
        title: "API สร้างหมาย สำเร็จ",
        color: "#005C9E",
        count: ProvidedInternally2Data?.count_request_all,
      },
      {
        title: "API สร้างหมาย ไม่สำเร็จ",
        color: "#B6AAFF",
        count: ProvidedInternally2Data?.count_response_all,
      },
    ];
  }, [ProvidedInternally2Data]);

  useEffect(() => {
    ProvidedInternally2DataRefetch();
  }, [date, type, ProvidedInternally2DataRefetch]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography
            variant="h6"
            sx={{
              marginTop: titleChart === " " ? "32px" : "0px",
            }}
          >
            {titleChart}
          </Typography>
        </Grid>

        {ProvidedInternally2DataIsLoading ? (
          <>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={5} sm={12} key={index} >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                      minWidth: "200px",
        
                    }}
                  >
                       <p
                       style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <LineChart
                options={options(
                  "API การสร้างหมายคำขอ",
                  ProvidedInternally2Data?.labal
                )}
                series={series(ProvidedInternally2Data)}
              />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default ProvidedInternally2;
