import { Card, CardContent, Skeleton } from "@mui/material";
import React from "react";

const ChartSkeleton = () => {


return(
    <>
    <Card
        sx={{
          width: "100%",
          height: "320px", // Adjust height
          marginTop: "10px",
        }}
      >
        <CardContent>
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
        </CardContent>
      </Card>
    </>
)
}

export default ChartSkeleton;