import { ExpandMore, Link as LinkIcon, StarBorder } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { useMatch, useResolvedPath } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import api from "../../apis";

export default function CaseMainMenuBotton({ drawerOpen, pathname }) {
  const to = "/cases";
  const exact = false;

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: exact ? true : false });

  let resolveCaseMgt = useResolvedPath("/cases/:id/mgt");
  let matchCaseMgt = useMatch({ path: resolveCaseMgt.pathname, end: false });

  const [activeCaseInfo, setActiveCaseInfo] = useState(false);
  const [activeAccount, setActiveAccount] = useState(false);
  const [activePersonal, setActivePersonal] = useState(false);
  const [activeTelephone, setActiveTelephone] = useState(false);
  const [activeLetter, setActiveLetter] = useState(false);



  const buttonMenuSX = (pl) => {
    if (!drawerOpen)
      return {
        textAlign: "center",
        justifyContent: "center",
        flex: 1,
      };
    else {
      return {
        margin: 1,
        borderRadius: 999,
        "&.Mui-selected": {
          backgroundColor: "#0077FF",
          color: "white",
        },
      };
    }
  };

  const buttonRoundedSX = (path) => {
    if (!drawerOpen) {
      if (path === pathname)
        return {
          color: "white",
          backgroundColor: "#0077FF",
          padding: 0.5,
          height: 32,
          width: 32,
          borderRadius: 999,
        };
    } else {
      if (path === pathname) return { color: "white" };
    }
  };

  // useEffect(() => {
  //   api
  //     .get("/api/cases", {
  //       params: {
  //         page_size: 1,
  //         page: 0,
  //       },
  //     })
  //     .then(({ data }) => {
  //       setCaseTotal(data.total);
  //     })
  //     .catch(() => {})
  //     .finally(() => {});
  // }, []);



  const { data: caseTotal } = useQuery("caseCount", () =>
    api
      .get(`/api/cases`, {
        params: {
          page_size: 1,
          page: 0,
        },
      })
      .then((res) => res.data.total),
      {
        staleTime: 60 * 1000,
      }
  );

  

  return (
    <>
      <ListItemButton
        component={ReactRouterLink}
        selected={match ? true : false}
        to={to}
        sx={buttonMenuSX}
      >
        <ListItemIcon
          className={!drawerOpen && "flex justify-center items-center"}
        >
          <StarBorder sx={buttonRoundedSX("/cases")} />
        </ListItemIcon>
        {drawerOpen && <ListItemText primary="รายการCase" />}
        {drawerOpen && (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip
                label={caseTotal ?? 0}
                color="error"
                size="small"
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
              />
            </Stack>
          </Stack>
        )}
        {matchCaseMgt && <ExpandMore />}
      </ListItemButton>
      <Collapse in={matchCaseMgt ? true : false} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ScrollLink
            to="case-info"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveCaseInfo(true);
            }}
            onSetInactive={() => {
              setActiveCaseInfo(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeCaseInfo}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="ข้อมูลเบื้องต้น" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="tx-account"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveAccount(true);
            }}
            onSetInactive={() => {
              setActiveAccount(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeAccount}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="รายการเดินบัญชี" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="personal"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActivePersonal(true);
            }}
            onSetInactive={() => {
              setActivePersonal(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activePersonal}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="ข้อมูลบุคคล" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="telephone"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveTelephone(true);
            }}
            onSetInactive={() => {
              setActiveTelephone(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeTelephone}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="ข้อมูลโทรศัพท์" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="letter"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveLetter(true);
            }}
            onSetInactive={() => {
              setActiveLetter(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeLetter}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="รายการคำขอ" />
            </ListItemButton>
          </ScrollLink>
        </List>
      </Collapse>
    </>
  );
}
