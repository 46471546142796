import { Dashboard, Download, Search } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getUnixTime, isAfter, parseISO, startOfToday } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "../../../apis";
import letterTypes from "../../../data/selects/letterTypes";
import requestStatusSelect from "../../../data/selects/requestStatus";
import { defaultJoiDates } from "../../../helpers/dateHelper";
import validationMsg from "../../../helpers/validationMsg";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import ReportCSV from "./exportCSV";
import ReportExcel from "./exportExcel";
import fileDownload from "js-file-download";
import LocalStorageService from "../../../helpers/LocalStorageService";
import { format, subMonths } from "date-fns";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  width: 1000,
  height: 640,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function OperatorSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  rows,
  searchForm,
  isAdminPage = false,
  pageSize,
  page,
  version,
  setVersion,
}) {
  const location = useLocation();
  const email = LocalStorageService.getUsername();

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [successToat, setSuccessToat] = useState(false);

  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const [dataDashboard, setDataDashboard] = React.useState([]);

  const [filterRequestStatusSelect, setFilterRequestStatusSelect] =
    React.useState(null);
  const openDowload = Boolean(anchorElDowload);
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const request_new = useSelector(
    (state) => state.auth.user.permissions.request_new ?? []
  );
  const request_contact_person = useSelector(
    (state) => state.auth.user.permissions.request_contact_person ?? []
  );
  const request_reject = useSelector(
    (state) => state.auth.user.permissions.request_reject ?? []
  );
  const request_checked = useSelector(
    (state) => state.auth.user.permissions.request_checked ?? []
  );
  const request_sent = useSelector(
    (state) => state.auth.user.permissions.request_sent ?? []
  );
  const request_success = useSelector(
    (state) => state.auth.user.permissions.request_success ?? []
  );
  const request_email_success = useSelector(
    (state) => state.auth.user.permissions.request_email_success ?? []
  );
  const qState = useSelector((state) => state.auth.user.permissions);
  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const [openDashboard, setOpenDashboard] = useState(false);
  const handleCloseDashboard = () => {
    setOpenDashboard(false);
  };

  useEffect(() => {
    const filter = requestStatusSelect.filter(
      (el) =>
        (el.value === "new" && request_new.includes("list")) ||
        (el.value === "sent_to_contact_person" &&
          request_contact_person.includes("list")) ||
        (el.value === "checked" && request_checked.includes("list")) ||
        (el.value === "sent_to_bank" && request_sent.includes("list")) ||
        (el.value === "reject" && request_reject.includes("list")) ||
        (el.value === "recived_from_bank" &&
          request_success.includes("list")) ||
        (el.value === "sent_to_pol" &&
          request_email_success.includes("list")) ||
        isAdminPage
    );
    setFilterRequestStatusSelect(filter);
  }, [
    isAdminPage,
    request_checked,
    request_contact_person,
    request_email_success,
    request_new,
    request_reject,
    request_sent,
    request_success,
  ]);
  const columns = [
    {
      field: "name",
      headerName: "ประเภทหมายคำขอ",
      sortable: false,
      flex: 1,
    },
    {
      field: "count",
      headerName: "คำร้องขอ",
      sortable: false,
      maxWidth: 75,
      align: "center",
    },
    {
      field: "recived_from_bank",
      headerName: "ตอบกลับ",
      sortable: false,
      maxWidth: 75,
      align: "center",
    },
  ];

  const fetchDashboard = async () => {
    try {
      setIsLoading(true);
      let pathName = location?.pathname;
      // Create an AbortController instance
      const abortController = new AbortController();
      if (pathName === "/requests") {
        const promise = await api
          .get(
            `/api/${
              location?.pathname?.includes("admin") ? "admin/" : ""
            }pol_requests/dashboard`,
            {
              params: {
                ...searchForm,
              },
              signal: abortController.signal, // Add the signal to the request config
            }
          )
          .then((res) => res.data);
        // Add the abort controller to the promise
        promise.cancel = () => abortController.abort();

        return setDataDashboard(promise);
      } else if (pathName === "/admin/request-mgt/sent_to_contact_person") {
        const promise = await api
          .get(`/api/admin/pol_requests/dashboard`, {
            params: {
              ...searchForm,
              status: "sent_to_contact_person",
            },
            signal: abortController.signal, // Add the signal to the request config
          })
          .then((res) => res.data);
        // Add the abort controller to the promise
        promise.cancel = () => abortController.abort();
        return setDataDashboard(promise);
      } else if (pathName === "/admin/request-mgt/checked") {
        const promise = await api
          .get(`/api/admin/pol_requests/dashboard`, {
            params: {
              ...searchForm,
              status: "checked",
            },
            signal: abortController.signal, // Add the signal to the request config
          })
          .then((res) => res.data);

        // Add the abort controller to the promise
        promise.cancel = () => abortController.abort();

        return setDataDashboard(promise);
      } else if (pathName === "/admin/request-mgt") {
        const promise = await api
          .get(`/api/admin/pol_requests_all/dashboard`, {
            signal: abortController.signal, // Add the signal to the request config
          })
          .then((res) => res.data);

        // Add the abort controller to the promise
        promise.cancel = () => abortController.abort();

        return setDataDashboard(promise);
      }
    } finally {
      setVersion(getUnixTime(new Date()));
      setIsLoading(false);
    }
  };

  const checkPermissionSearch = useCallback(
    (locationURL) => {
      let type = locationURL.pathname.split("/")[3] ?? "";
      if (type === "sent_to_contact_person") {
        if (qState?.new_request?.includes("search")) {
          return true;
        } else {
          return false;
        }
      } else if (type === "checked") {
        if (qState?.approved_request?.includes("search")) {
          return true;
        } else {
          return false;
        }
      } else {
        if (qState?.all_request?.includes("search")) {
          return true;
        } else {
          return false;
        }
      }
    },
    [qState?.approved_request, qState?.new_request, qState?.all_request]
  );

  const checkPermissionDowload = useCallback(
    (locationURL) => {
      let type = locationURL.pathname.split("/")[3] ?? "";
      if (type === "sent_to_contact_person") {
        if (qState?.new_request?.includes("download")) {
          return true;
        } else {
          return false;
        }
      } else if (type === "checked") {
        if (qState?.approved_request?.includes("download")) {
          return true;
        } else {
          return false;
        }
      } else {
        if (qState?.all_request?.includes("download")) {
          return true;
        } else {
          return false;
        }
      }
    },
    [qState?.approved_request, qState?.new_request, qState?.all_request]
  );

  const DownloadBtn = (
    <LoadingButton
      id="export-button-excel"
      type="button"
      variant="outlined"
      startIcon={<Download />}
      loadingPosition="start"
      // onClick={() => handleDownload("/api/", setPdfDownloading)}
      aria-controls={openDowload ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={openDowload ? "true" : undefined}
      onClick={handleClickDowload}
      loading={isLoadingExcel}
      disabled={!values.start_date || !values.end_date}
    >
      ดาวน์โหลด
    </LoadingButton>
  );

  return (
    <>
      <Dialog
        open={successToat}
        onClose={() => {
          setSuccessToat(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            flex={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: 80,
                color: "#4caf50",
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              เรากำลังส่งข้อมูลการดาวน์โหลดไฟล์ไปทางอีเมล์ของท่าน
              กรุณาตรวจสอบไฟล์การดาวน์โหลดทางอีเมล์นี้
              <span className=" text-blue-600  underline ml-2">{email}</span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessToat(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit} noValidate>
        {(checkPermissionSearch(location) || requests.includes("search")) && (
          <Grid container sx={{ mb: 3 }} spacing={2}>
            {showStatusField && (
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldAutoComplete
                  name="status"
                  label="สถานะ"
                  options={filterRequestStatusSelect}
                  controlProp={{
                    sx: {
                      width: "100%",
                    },
                  }}
                  // inputProps={{ sx: { fullWidth: true } }}
                  // controlProp={{ sx: { fullWidth: true } }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <FieldAsyncSelect
                name="bank_id"
                label="หน่วยงานข้อมูล"
                controlProp={{
                  sx: {
                    width: "100%",
                  },
                }}
                basePath={`/api/banks`}
                // filter={{ type: "bank" }}
                itemFormat={(row) => {
                  return {
                    value: row.id,
                    label: `${row.name} (${row.short_name})`,
                  };
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <FieldAutoComplete
                name="type_id"
                label="ประเภทคำขอ"
                options={letterTypes}
                controlProp={{
                  sx: {
                    width: "100%",
                  },
                }}
                //   inputProps={{ sx: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12} xl={4}>
              <Stack
                direction={{
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                }}
                spacing={2}
              >
                <FieldDatePicker
                  name="start_date"
                  label="ตั้งแต่วันที่"
                  pickerProps={{
                    inputFormat: "dd/MM/yyyy",
                    disableFuture: true,
                    openTo: "year",
                    views: ["year", "month", "day"],
                    allowSameDateSelection: true,
                  }}
                />
                <FieldDatePicker
                  name="end_date"
                  label="ถึงวันที่"
                  pickerProps={{
                    inputFormat: "dd/MM/yyyy",
                    disableFuture: true,
                    openTo: "year",
                    views: ["year", "month", "day"],
                    allowSameDateSelection: true,
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <FieldInput
                name="search"
                label="คำค้นหา"
                controlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <FieldInput
                name="setid"
                label="setid"
                controlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <FieldInput
                name="fullname_search"
                label="ชื่อ-นามสกุล"
                controlProps={{ fullWidth: true }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item md={12} lg={6}>
            {(checkPermissionSearch(location) ||
              requests.includes("search")) && (
              <Stack direction="row" spacing={2} alignItems="center">
                <SubmitBtn
                  variant="contained"
                  startIcon={<Search />}
                  submitting={loading}
                  pristine={pristine && !modifiedSinceLastSubmit}
                >
                  ค้นหา
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                  onClick={(event) => {
                    form.reset();
                    if (onReset) onReset(event);
                  }}
                >
                  Reset
                </Button>
              </Stack>
            )}
          </Grid>
          <Grid item md={12} lg={6}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                variant="contained"
                color="success"
                startIcon={<Dashboard />}
                onClick={() => {
                  if (!version) {
                    fetchDashboard();
                  }
                  setOpenDashboard(true);
                }}
                disabled={loading}
              >
                ข้อมูลสรุปข้อมูลหมายคำขอ
              </Button>
              {(checkPermissionDowload(location) ||
                requests.includes("download")) && (
                <>
                  {!values.start_date || !values.end_date ? (
                    <Tooltip title="กรุณาเลือกวันที่ ที่ค้นหาก่อนใช้งานปุ่มดาวน์โหลด">
                      <span>{DownloadBtn}</span>
                    </Tooltip>
                  ) : (
                    DownloadBtn
                  )}
                </>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorElDowload}
                open={openDowload}
                onClose={handleCloseDownload}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={async () => {
                    setIsLoadingExcel(true);
                    handleCloseDownload();

                    console.log("page", page);
                    console.log("pageSize", pageSize);
                    console.log("pageValues", values);

                    try {
                      if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("sent_to_contact_person")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "excel",
                              status: "sent_to_contact_person",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.xlsx";
                        fileDownload(res.data, filename);
                      } else if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("checked")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "excel",
                              status: "checked",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.xlsx";
                        fileDownload(res.data, filename);
                      } else if (
                        !location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("requests")
                      ) {
                        const res = await api.get(
                          `/api/mgt/pol_requests/list_request`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "excel",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.xlsx";
                        fileDownload(res.data, filename);
                      } else if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("request-mgt")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "excel",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );

                        if (res) {
                          setSuccessToat(true);
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingExcel(false);
                    }

                    // ReportExcel(rows);
                  }}
                >
                  Excel
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    setIsLoadingExcel(true);
                    handleCloseDownload();
                    try {
                      if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("sent_to_contact_person")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "csv",
                              status: "sent_to_contact_person",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.csv";
                        fileDownload(res.data, filename);
                      } else if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("checked")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "csv",
                              status: "checked",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.xlsx";
                        fileDownload(res.data, filename);
                      } else if (
                        !location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("requests")
                      ) {
                        const res = await api.get(
                          `/api/mgt/pol_requests/list_request`,
                          {
                            responseType: "blob",
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "csv",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );
                        const contentDisposition =
                          res.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download.csv";
                        fileDownload(res.data, filename);
                      } else if (
                        location?.pathname?.includes("admin") &&
                        location?.pathname?.includes("request-mgt")
                      ) {
                        const res = await api.get(
                          `/api/admin/pol_requests/list_request_all`,
                          {
                            params: {
                              page: page ?? 0,
                              page_size: pageSize ?? 10,
                              export: "csv",
                              start_date:
                                values.start_date ??
                                format(subMonths(new Date(), 1), "yyyy-MM-dd"),
                              end_date:
                                values.end_date ??
                                format(new Date(), "yyyy-MM-dd"),
                              ...values,
                            },
                          }
                        );

                        if (res) {
                          setSuccessToat(true);
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingExcel(false);
                    }

                    // ReportExcel(rows);
                  }}
                >
                  CSV
                </MenuItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={openDashboard}
        onClose={handleCloseDashboard}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataGrid
                disableColumnMenu
                columns={columns}
                rows={dataDashboard}
                rowCount={dataDashboard?.length}
                loading={isLoading}
                pagination={false}
                pageSize={100}
                width="100%"
                autoHeight
                hideFooter
                rowHeight={42}
                sx={{
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#efefef",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "center",
                    fontWeight: 700,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    handleCloseDashboard();
                  }}
                >
                  ปิด
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  const now = new Date();
  if (!errors.start_date && values.start_date) {
    const startDate = parseISO(values.start_date);
    if (isAfter(startDate, now)) {
      errors.start_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (!errors.end_date && values.end_date) {
    const endDate = parseISO(values.end_date);
    if (isAfter(endDate, now)) {
      errors.end_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (
    !errors.start_date &&
    !errors.end_date &&
    values.start_date &&
    values.end_date &&
    isAfter(parseISO(values.start_date), parseISO(values.end_date))
  ) {
    errors.start_date = "ไม่ถูกต้อง";
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  type_id: Joi.number().min(1).max(10).allow(null),
  bank_id: Joi.number().allow(null),
  start_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .allow(null),
  end_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
  setid: Joi.string().allow(null),
  fullname_search: Joi.string().allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
