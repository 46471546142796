import {
  Alert,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import {
  ChevronLeft,
  ChevronRight,
  Recommend,
  ThumbDown,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { Form } from "react-final-form";
import RequestFormReadOnly, {
  mapToInit,
} from "../requests/request-forms/RequestFormReadOnly";
import { LoadingButton } from "@mui/lab";
import Document from "react-pdf/dist/umd/Document";
import Page from "react-pdf/dist/umd/Page";
import DialogDescForm from "../../dialogs/DescFormDialog";
import Joi from "joi";
import validationMsg from "../../../helpers/validationMsg";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import PredefineRejectDesc from "./PredefineRejectDesc";
import ReqDownloadListBtn from "../../customs/ReqDownloadListBtn";
import { useSelector } from "react-redux";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function NewRequestView({ caseId, reqId, handleCancel ,status }) {
  const qState = useSelector((state) => state.auth.user.permissions);
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pdfContainer = useRef();
  const pdfContainerLoadingWidth = useRef();
  const rednderPagination = () => {
    if (numPages === null) {
      return undefined;
    }

    return (
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
      >
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev - 1);
          }}
          disabled={pageNumber <= 1}
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev + 1);
          }}
          disabled={pageNumber >= numPages}
        >
          <ChevronRight />
        </Button>
      </ButtonGroup>
    );
  };
  // for fetch info
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(status === "sent_to_contact_person" ? `/api/admin/contact_person/cases/${caseId}/pol_requests/${reqId}`  : `/api/cases/${caseId}/pol_requests/${reqId}`)
      .then(({ data }) => {
        if (data.status !== "sent_to_contact_person") {
          throw new Error("status not equal to sent_to_contact_person");
        }

        isSubscribed && setItem(data);

        const pdfLink = data.links?.find((link) => link.name === "export_pdf");

        if (!pdfLink?.link) {
          return undefined;
        }

        return api.get(pdfLink.link, {
          responseType: "arraybuffer",
        });
      })
      .then((response) => {
        if (!response) {
          return undefined;
        }

        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=".+"/.exec(contentDisposition)
          ? decodeURI(/filename="(.+)"/.exec(contentDisposition)[1])
          : "download";

        isSubscribed && setPdfFile({ filename, data: response.data });
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        isSubscribed && setLoading(false);
      });

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reqId]);

  const [submitting, setSubmitting] = useState(false);
  const handleConfirm = () => {
    setSubmitting(true);
    api
      .put(`/api/admin/contact_person/cases/${caseId}/pol_requests/${reqId}/checked`)
      .then(() => {
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch(({ response }) => {
        handleCancel({
          type: "error",
          message: response?.error ?? "เกิดข้อผิดพลาด",
        });
      })
      .finally(() => {
        // setConfirming(false);
      });
  };

  // reject confirmation
  const [cfReject, setCfReject] = useState(false);
  const handleCfRejectClose = () => setCfReject(false);
  const handleReject = (formValues) => {
    return api
      .put(`/api/admin/contact_person/cases/${caseId}/pol_requests/${reqId}/reject`, formValues)
      .then(() => {
        handleCfRejectClose();
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch(({ response }) => {
        handleCfRejectClose();
        handleCancel({
          type: "error",
          message: response?.data?.message
            ? response?.data?.message
            : response?.data?.error
            ? response?.data?.error
            : "เกิดข้อผิดพลาด",
        });
      })
      .finally(() => {});
  };

  const modalStyle = {
    ...masterModalStyle,
    height: !loading && item ? "100%" : undefined,
    // width: pdfContainerLoadingWidth.current
    //   ? `${pdfContainerLoadingWidth.current}px`
    //   : "auto",
    width: pdfContainer?.current?.offsetWidth >= 338 ? "auto" : "80%",
  };

  return (
    <Box sx={modalStyle} ref={pdfContainer}>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Form
        validate={validateReject}
        onSubmit={handleReject}
        component={DialogDescForm}
        open={cfReject}
        onClose={handleCfRejectClose}
        cfBtnProps={{ label: "ยืนยัน", color: "error" }}
        title="ยืนยันการปฏิเสธ"
        content={
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">
              โปรดอธิบายเหตุผลการปฎิเสธนี้
            </Typography>
            <PredefineRejectDesc />
          </Stack>
        }
        fieldProps={{
          name: "note",
          label: "เหตุผล",
          inputProps: {
            multiline: true,
            maxRows: 6,
            minRows: 3,
            autoFocus: true,
          },
        }}
      />
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ตรวจสอบรายการคำขอ (ID: {reqId})
        </Typography>
        {!loading && item?.status && getChip(item.status)}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        {!loading &&
        item?.links?.length > 0 &&
        (qState?.new_request?.includes("download") ||
          requests.includes("download")) ? (
          <ReqDownloadListBtn links={item?.links} />
        ) : (
          <></>
        )}
        {!loading && pdfFile && (
          <>
            {rednderPagination()}
            <Typography variant="subtitle1">
              Page {pageNumber} of {numPages}
            </Typography>
          </>
        )}
      </Stack>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {loading || !item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <>
            <Grid container spacing={2} mt={2}>
              {pdfFile ? (
                <Grid item xs={12}>
                  <Document
                    file={pdfFile.data}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={
                        pdfContainer?.current?.offsetWidth >= 338 ? 1.4 : 1.0
                      }
                      onLoadSuccess={() => {
                        pdfContainerLoadingWidth.current = null;
                      }}
                    />
                  </Document>
                </Grid>
              ) : (
                <Form
                  // validate={validate}
                  readOnly
                  onSubmit={() => undefined}
                  component={RequestFormReadOnly}
                  initialValues={mapToInit(item)}
                />
              )}
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              {(qState?.new_request?.includes("approve") ||
                requests.includes("confirm")) && (
                <Grid item md={12} lg={4}>
                  <LoadingButton
                    type="button"
                    variant="contained"
                    startIcon={<Recommend />}
                    onClick={() => handleConfirm()}
                    loading={submitting}
                    loadingPosition="start"
                  >
                    ตรวจแล้ว
                  </LoadingButton>
                </Grid>
              )}
              <Grid item md={12} lg={8}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  {(qState?.new_request?.includes("denied") ||
                    qState.all_request.includes("denied") ||
                    requests.includes("delete")) && (
                    <LoadingButton
                      type="button"
                      variant="contained"
                      color="error"
                      startIcon={<ThumbDown />}
                      onClick={() => setCfReject(true)}
                      loading={submitting}
                      loadingPosition="start"
                    >
                      ปฏิเสธ
                    </LoadingButton>
                  )}
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      handleCancel();
                    }}
                    disabled={submitting}
                  >
                    ปิด
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  );
}

const schema = Joi.object({
  note: Joi.string().required(),
}).error(validationMsg());

const validateReject = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};
