import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo ,useEffect } from "react";
import { format, startOfToday } from "date-fns";
import { useQuery } from "react-query";
import api from "../../../../apis";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";
import { useCallback } from "react";

const ExternallyProvided2 = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const titleChart = " ";

  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#50B238", "#439BFF"],
      yaxis: { title: { text: "การใช้งาน (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const series = useCallback((data) => {
    return [
      {
        name: "Success",
        data: data?.success,
      },
      {
        name: "Fail",
        data: data?.fail,
      },
    ];
  }, []);

  const {
    data: ExternallyProvided2Data,
    refetch: ExternallyProvided2DataRefetch,
    isLoading: ExternallyProvided2DataIsLoading,
    isError: ExternallyProvided2DataIsError,
  } = useQuery(["ExternallyProvided2Data",date, type], () =>
    api
      .get(`/api/dashboard/tpo`,{
        params: { date, report_type: type },
      })
      .then((res) => res.data)
  );

  const card = useMemo(() => {
    return [
      {
        title: "API ดึง TPO สำเร็จ",
        color: "#50B238",
        count: ExternallyProvided2Data?.count_request_all,
      },
      {
        title: "API ดึง TPO ไม่สำเร็จ",
        color: "#439BFF",
        count: ExternallyProvided2Data?.count_response_all,
      },
    ];
  }, [ExternallyProvided2Data]);

  useEffect(() => {
    ExternallyProvided2DataRefetch();
  }, [date, type, ExternallyProvided2DataRefetch]);

  useEffect(() => {
    console.log("ExternallyProvided2Data", ExternallyProvided2Data);
  }, [ExternallyProvided2Data])
  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography
            variant="h6"
            sx={{
              marginTop: titleChart === " " ? "32px" : "0px",
            }}
          >
            {titleChart}
          </Typography>
        </Grid>

        {ExternallyProvided2DataIsLoading ? (
          <>
            {" "}
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>{" "}
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={5} xs={12} sm={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                      minWidth: "200px",
                    }}
                  >
                       <p
                       style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <LineChart options={
                options("API ดึงข้อมูลจาก Thai Police Online (TPO)", ExternallyProvided2Data?.labal)
              } series={
                series(ExternallyProvided2Data)
              } />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default ExternallyProvided2;
