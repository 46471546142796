/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  policeEdit,
  policeFetchByOrgCode,
} from "../../../../ducks/masterDataList";
import WarningDialog from "../../../dialogs/WarningDialog";
import AgencyAddForm from "./AgencyAddForm";

export default function AgencyAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { org_code } = useParams();

  const item = useSelector(
    (state) => state.masterData.police_station[org_code]
  );

  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  const onSubmit = (values) => {
    dispatch(policeEdit(item.ORG_CODE, values))
      .then(() => {
        navigate("/settingagency");
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  useEffect(() => {
    dispatch(policeFetchByOrgCode(org_code)).catch(({ response }) => {
      console.error("error", response);
      setError(response.data.message);
    });
  }, [org_code]);

  return (
    <>
      <WarningDialog error={error} onClose={handleClose} />
      <Grid item xs={12}>
        <Typography variant="h5">
          จัดการข้อมูลหน่วยงาน (ID:{org_code})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            initialValues={item}
            onSubmit={onSubmit}
            component={AgencyAddForm}
            isEdit
          />
        </Paper>
      </Grid>
    </>
  );
}
