import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import api from "../../../apis";
import _ from "lodash";
import { useQuery, useQueryClient } from "react-query";
import Hr03AccountForm, { mapToInit, validate } from "./Hr03AccountForm";
import { useNavigate, useParams } from "react-router-dom";
import { FORM_ERROR } from "final-form";

export default function Hr03AccountEditPage() {
  const params = useParams();
  const itemId = parseInt(params.id);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: item,
  } = useQuery(
    ["hr03", itemId],
    () => api.get(`/api/hr_reports/${itemId}`).then((res) => res.data),
    { staleTime: 60 * 1000 }
  );

  const onSubmit = async (formValues) => {
    let submitData = null;

    submitData = _.omit(formValues, ["pol_signature"]);

    if (submitData.is_foreigner) {
      submitData.eng_name = submitData.name;
      submitData.eng_midname = submitData.midname || undefined;
      submitData.eng_surname = submitData.surname;
    } else {
      submitData.nationality = "TH";
    }

    try {
      await api.put(`/api/hr_reports/${itemId}`, submitData);
      queryClient.invalidateQueries(["hr03", itemId]);
      navigate(`/hr03`);
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  const renderContent = () => {
    if (isLoading)
      return (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      );

    if (isError)
      return (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
        </Alert>
      );

    return (
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={mapToInit(item)}
        component={Hr03AccountForm}
        editMode
      />
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5"> แก้ไขบัญชี HR03 (ID: {itemId})</Typography>
        {/* <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {renderContent()}
        </Paper>
      </Grid>
    </>
  );
}
