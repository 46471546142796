import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import NotificationForm from "./NotificationForm";
import { format, startOfToday } from "date-fns";
import { useDispatch } from "react-redux";
import { notificationUpdate } from "../../../ducks/setting";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import api from "../../../apis";
import toast from "react-hot-toast";

export default function NotificationAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const onSubmit = (formData, form) => {
    return dispatch(notificationUpdate(formData.n))
      .then(() => {
        fetch();
        toast.success(<b>แก้ไขข้อมูลเสร็จสิ้น</b>);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  const fetch = () => {
    api
      .get(`/api/systemsettings`)
      .then(({ data }) => setData(data))
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetch();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">ตั้งค่าการใช้งาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              // validate={validate}
              onSubmit={onSubmit}
              // initialValues={{
              //   date: format(startOfToday(), "yyyy-MM-dd"),
              //   ...initData,
              // }}
              component={NotificationForm}
              initialValues={{ n: data }}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
