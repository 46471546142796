import api from "../apis";
// Actions
const CRQ_FETCH = "caseReqList/FETCH";
const CRQ_CLEAR_RESULT = "caseReqList/CLEAR_RESULT";
const CRQ_MARK_LOADING = "caseReqList/MARK_LOADING";
const CRQ_SET_FILTER = "caseReqList/SET_FILTER";
const CRQ_FORCE_RELOAD = "caseReqList/FORCE_RELOAD";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CRQ_FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
        },
      };
    case CRQ_CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0 },
      };
    case CRQ_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case CRQ_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
        },
      };
    case CRQ_FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const crqFetch =
  (pageSize, pageIndex, filter = {}, fetchId, fetchIdRef, caseId) =>
  async (dispatch) => {
    const response = await api.get(`/api/cases/${caseId}/pol_requests`, {
      params: {
        page_size: pageSize,
        page: pageIndex,
        ...filter,
      },
    });
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      dispatch({
        type: CRQ_FETCH,
        payload: { ...response.data, pageSize, pageIndex },
      });
    }
  };

export const crqMarkLoading = (isLoading = true) => {
  return { type: CRQ_MARK_LOADING, payload: isLoading };
};

export const crqClearResult = () => {
  return { type: CRQ_CLEAR_RESULT };
};

export const crqSetFilter = (filterValue) => {
  return { type: CRQ_SET_FILTER, payload: filterValue };
};

export const forceReload = () => {
  return { type: CRQ_FORCE_RELOAD };
};
