import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../apis";
import { uploadSuspiciousCreate } from "../../../ducks/uploadSuspicious";
import Hr03RequestActiveForm, { validate } from "./UploadSuspiciousActiveForm";
import { useQuery } from "react-query";

export default function UserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { data: bankOptions } = useQuery(
    [
      "bankOption",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) => {
        return res?.data?.map((row) => ({
          ...row,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const onSubmit = (data) => {
    const objData = { ...data };

    let bank_id = bankOptions.find((row) => row.code === objData.bank);

    const formData = new FormData();
    formData.append("bank", bank_id?.id);
    formData.append("number", objData?.number);
    formData.append("date", objData?.date);
    formData.append("attachment", objData.attachment);
    formData.append("notes", objData.notes ?? "-");
    formData.append("type", objData.type === 99 ? 0 : objData.type);
    if (objData.type === 99) {
      formData.append(
        "type_note",
        objData.type_note === "undefined" ? "-" : objData.type_note
      );
    }

    return dispatch(uploadSuspiciousCreate(formData))
      .then(() => {
        navigate("/UploadSuspicious");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Upload เหตุอันควรสงสัย</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              page="add"
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
