import { AutoFixHigh } from "@mui/icons-material";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useForm } from "react-final-form";

const templates = [
  {
    name: "สีพื้นหลังลายเซนต์",
    detail:
      "ภาพพื้นหลังของลายเซนต์ไม่เป็นสีเดียวกับตัวหมายไม่เหมาะสมสำหรับกับการออกเอกสาร\n***แอดไลน์ @pctsupport เพื่อสอบถาม",
  },
  {
    name: "ลายเซ็นเลือนลาง",
    detail:
      "ลายเซ็นเลือนลางไม่ชัดเจนไม่เหมาะสมสำหรับกับการออกเอกสาร\n***แอดไลน์ @pctsupport เพื่อสอบถาม",
  },
  {
    name: "ลายเซ็นเล็ก",
    detail:
      "ลายเซ็นเลิกเกินไป ไม่เหมาะสมสำหรับกับการออกเอกสาร\n***แอดไลน์ @pctsupport เพื่อสอบถาม",
  },
  { name: "ตัวเลขอารบิค", detail: "กรุณาใช้ตัวเลขอารบิคในการกรอกข้อมูล" },
  { name: "ไม่ระบุธนาคาร", detail: "หมายไม่ระบุธนาคาร" },
];

export default function PredefineRejectDesc() {
  const form = useForm();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButton = (text) => {
    form.change("note", text);
    handleClose();
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {templates.map((item, index) => (
          <MenuItem key={index} onClick={() => handleButton(item.detail)}>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        type="button"
        size="small"
        variant="contained"
        color="primary"
        onClick={handleMenu}
      >
        <AutoFixHigh fontSize="inherit" />
      </IconButton>
    </>
  );
}
