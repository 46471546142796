import { Search } from "@mui/icons-material";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormState } from "react-final-form";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../apis";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";

export default function UsageReportSearchForm({
  loading,
  onReset,
  handleSubmit,
  form,
  pristine,
  SetOrgNameLabel,
  SetConditionTypeNameLabel,
  SetOrgName,
  SetConditionTypeName,
  search,
}) {
  const report_static_assign_case = useSelector(
    (state) => state.auth.user?.permissions?.report_static_assign_case ?? []
  );
  const formState = useFormState();
  const didRequest = useRef(false);
  const [fetchInfo, SetFetchInfo] = useState(false);

  const { data: orgCodeOptions } = useQuery(
    ["reportType", `/api/reports/org/1`, { value: "code", label: "fullname" }],
    () =>
      api.get(`/api/reports/org/1`).then((res) => {
        return res?.data?.map((row) => ({
          value: row.ORG_CODE,
          label: row.ORG_ABBR,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    orgCodeOptions &&
      SetOrgNameLabel(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
  }, [formState.values]);

  useEffect(() => {
    if (search) {
      form.change("org_code", search);
      SetOrgName(orgCodeOptions.filter((el) => el.value === search)[0]?.label);
      form.submit();
    }
  }, [search]);

  useEffect(() => {
    if (!didRequest.current && orgCodeOptions) {
      SetOrgName(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
      didRequest.current = true;
    }
  }, [orgCodeOptions]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {report_static_assign_case.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="org_code"
              label="หน่วยงาน"
              options={orgCodeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            {report_static_assign_case.includes("search") && (
              <Stack direction="row" spacing={2} alignItems="center">
                <SubmitBtn
                  variant="contained"
                  startIcon={<Search />}
                  submitting={loading}
                  pristine={pristine}
                >
                  ค้นหา
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={loading || pristine}
                  onClick={(event) => {
                    form.reset();
                    SetOrgName(
                      orgCodeOptions.filter(
                        (el) =>
                          el.value === form.getFieldState("org_code").initial
                      )[0]?.label
                    );
                    if (onReset) onReset(event);
                  }}
                >
                  Reset
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
}
