import _ from "lodash";
import mainAxios from "../apis";

export const APP_MARK_LOADING = "verifyOTP/APP_MARK_LOADING";
export const APP_INIT_SUCCESS = "verifyOTP/APP_INIT_SUCCESS";
export const APP_INIT_FAIL = "verifyOTP/APP_INIT_FAIL";
export const APP_MA = "verifyOTP/APP_MA";

const defaultState = {
  isLoading: true,
  initSuccess: null,
  maintenance: { status: false, message: null },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APP_MARK_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case APP_INIT_SUCCESS:
      return {
        ...state,
        initSuccess: true,
        isLoading: false,
      };
    case APP_INIT_FAIL:
      return {
        ...state,
        initSuccess: false,
        isLoading: false,
      };
    case APP_MA: {
      return {
        ...state,
        maintenance: { status: true, message: action.payload },
      };
    }
    default:
      return state;
  }
}

export const resendOTP = (data) => async (dispatch) => {
  try {
    const email = _.get(data, "email", "");
    const bodyParameters = {
      email,
    };

    const response = await mainAxios.post(
      "/api/resend_otp",
      bodyParameters
      // config
    );
    if (response.status === 200) {
      dispatch({ type: APP_INIT_SUCCESS });
      return response;
    }
  } catch (e) {
    dispatch({ type: APP_INIT_FAIL });
  }
};
