import { format } from "date-fns";
import _ from "lodash";
const validationMsg = (options = {}) => {
  return (errors) => {
    return errors.map((error) => {
      if (error.value === null && error.flags?.presence === "required") {
        error.message = "ต้องการ";
        return error;
      }

      switch (error.code) {
        case "any.required":
          error.message = "ต้องการ";
          break;
        case "number.min":
          error.message = "ต้องไม่น้อยกว่า " + error.local.limit;
          break;
        case "number.max":
          error.message = "ต้องไม่เกินกว่า " + error.local.limit;
          break;
        case "number.base":
          error.message = "ข้อมูลไม่ถูกต้อง";
          break;
        case "string.pattern.base":
          error.message =
            _.get(options[error.local.label], "stringPattern") ||
            "ข้อมูลไม่ถูกต้อง";
          break;
        case "array.includesRequiredUnknowns":
          error.message = "อย่างน้อย 1 รายการ";
          break;
        case "array.length":
          error.message = "ต้องการ " + error.local.limit + " รายการ";
          break;
        case "string.empty":
          error.message = "ต้องการ";
          break;
        case "date.base":
          error.message = "รูปแบบไม่ถูกต้อง";
          break;
        case "date.format":
          error.message = "รูปแบบวันที่ไม่ถูกต้อง";
          break;
        case "date.max":
          const dateMaxFormat =
            _.get(options.dateFormat, error.local.label) || "dd/MM/yyyy";
          error.message = `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
            error.local.limit,
            dateMaxFormat
          )}`;
          break;
        case "date.min":
          const dateMinFormat =
            _.get(options.dateFormat, error.local.label) || "dd/MM/yyyy";
          error.message = `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
            error.local.limit,
            dateMinFormat
          )}`;
          break;
        case "array.min":
          error.message = "ต้องการอย่างน้อย " + error.local.limit + " รายการ";
          break;
        case "string.min":
          error.message = "ต้องการอย่างน้อย " + error.local.limit + " อักษร";
          break;
        case "string.max":
          error.message = "ต้องไม่เกินกว่า " + error.local.limit + " อักษร";
          break;
        case "any.only":
          error.message =
            "ต้องเท่ากับ" +
            (error.local.valids?.length > 1 ? "ค่าใดค่าหนึ่งใน (" : " (") +
            error.local.valids +
            ") เท่านั้น";
          break;
        default:
          error.message = null;
      }
      return error;
    });
  };
};

export default validationMsg;
