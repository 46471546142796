import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo ,useCallback ,useEffect } from "react";
import { format, startOfToday } from "date-fns";
import { useQuery } from "react-query";
import api from "../../../../apis";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";

const ProvidedInternally1 = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const titleChart = "การใช้งาน API ที่ให้บริการกับภายใน";


  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#DDBA30", "#979797"],
      yaxis: { title: { text: "การใช้งาน (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const series = useCallback((data) => {
    return [
      {
        name: "Success",
        data: data?.success,
      },
      {
        name: "Fail",
        data: data?.fail,
      }
    ];
  }, []);


  const {
    data: ProvidedInternally1Data,
    refetch: ProvidedInternally1DataRefetch,
    isLoading: ProvidedInternally1DataIsLoading,
    isError: ProvidedInternally1DataIsError,
  } = useQuery(["ProvidedInternally1Data",date, type], () =>
    api
      .get(`/api/dashboard/apiRequest`,{
        params: {
          date: date,
          report_type: type,
          api_type:"cases"
        }
      })
      .then((res) => res.data)
  );

  const card = useMemo(() => {

    return [
      {
        title: "API สร้างเคสสำเร็จ",
        color: "#DDBA30",
        count: ProvidedInternally1Data?.count_request_all,
      },
      {
        title: "API สร้างเคสไม่สำเร็จ",
        color: "#979797",
        count: ProvidedInternally1Data?.count_response_all,
      }
    ]

  }, [ProvidedInternally1Data]);


  useEffect(() => {
    ProvidedInternally1DataRefetch();
  }, [date, type, ProvidedInternally1DataRefetch]);

  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">{titleChart}</Typography>
        </Grid>

        {ProvidedInternally1DataIsLoading ? (
          <>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={5} sm={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                      minWidth: "200px",
                    }}
                  >
                      <p
                       style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <LineChart options={
                options("API การสร้างรายการ Case", ProvidedInternally1Data?.labal)
              } series={
                series(ProvidedInternally1Data)
              } />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default ProvidedInternally1;
