import { ExpandMore, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import FieldTimePicker from "../../form-fields/FieldTimePicker";
import FieldDateTimePicker from "../../form-fields/FieldDateTimePicker";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  "&.MuiPaper-elevation1": {
    boxShadow: "none",
  },
}));

export default function NotificationForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  setAlertSMS,
  setAlert,
}) {
  const qState = useSelector((state) => state.auth.user.permissions);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      {submitFailed && (error || submitError) && (
        <Grid item md={12}>
          <Alert severity="warning" sx={{ width: "100%" }}>
            {error || submitError}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5">ตั้งค่าการแจ้งเตือน</Typography>
      </Grid>
      <Grid container spacing={2}>
        {values.n.map((el, i) =>
          el.name !== "ตั้งค่าเงื่อนไข" ? (
            <>
              <Grid item xs={12}>
                <AccordionStyled
                  defaultExpanded={
                    el.system_settings.length > 0 ? true : undefined
                  }
                  expanded={el.system_settings.length > 0 ? undefined : false}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore
                        sx={
                          el.system_settings.length > 0
                            ? undefined
                            : { color: "transparent" }
                        }
                      />
                    }
                  >
                    <Typography>
                      <Box sx={{ fontWeight: "medium", m: 1 }}>{el.name}</Box>
                    </Typography>
                    <Typography marginLeft={"auto"} marginRight={1}>
                      <Box sx={{ fontWeight: "medium" }}>
                        <InputLabel>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography>
                              <Box sx={{ fontWeight: "light", m: 1 }}>
                                การแจ้งเตือน
                              </Box>
                            </Typography>
                            <Field name={"n[" + i + "].active"} type="checkbox">
                              {({ input, meta }) => (
                                <>
                                  <AntSwitch
                                    defaultChecked={input.value}
                                    {...input}
                                  />
                                </>
                              )}
                            </Field>
                          </Stack>
                        </InputLabel>
                      </Box>
                    </Typography>
                  </AccordionSummary>
                  {el.system_settings.length > 0 && (
                    <AccordionDetails>
                      <Grid item xs={12} ml={5}>
                        {el.system_settings.map((it, k) => (
                          <FormGroup>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Field
                                name={
                                  "n[" +
                                  i +
                                  "].system_settings[" +
                                  k +
                                  "].active"
                                }
                                type="checkbox"
                              >
                                {({ input, meta }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        defaultChecked={input.value}
                                        {...input}
                                      />
                                    }
                                    label={it.label}
                                  />
                                )}
                              </Field>
                              {(it.type === "text" || it.type === "number") && (
                                <FieldInput
                                  name={
                                    "n[" +
                                    i +
                                    "].system_settings[" +
                                    k +
                                    "].value"
                                  }
                                  label="วัน"
                                  inputProps={{
                                    inputProps: { sx: { textAlign: "center" } },
                                  }}
                                  inputType={it.type}
                                />
                              )}
                              {it.type === "time" && (
                                <FieldTimePicker
                                  name={
                                    "n[" +
                                    i +
                                    "].system_settings[" +
                                    k +
                                    "].value"
                                  }
                                  label="เวลา"
                                  pickerProps={{
                                    inputFormat: "HH:mm",
                                    disableFuture: true,
                                    openTo: "hours",
                                    views: ["hours", "minutes"],
                                    allowSameDateSelection: true,
                                  }}
                                  storeFormat="HH:mm"
                                />
                              )}
                              {it.type === "datetime" && (
                                <FieldDateTimePicker
                                  name={
                                    "n[" +
                                    i +
                                    "].system_settings[" +
                                    k +
                                    "].value"
                                  }
                                  label="วันที่"
                                  pickerProps={{
                                    inputFormat: "dd/MM/yyyy HH:mm",
                                    disableFuture: true,
                                    openTo: "year",
                                    views: [
                                      "year",
                                      "month",
                                      "day",
                                      "hours",
                                      "minutes",
                                    ],
                                    allowSameDateSelection: true,
                                  }}
                                  storeFormat="yyyy-MM-dd HH:mm"
                                />
                              )}
                            </Stack>
                          </FormGroup>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  )}
                </AccordionStyled>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Divider></Divider>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography variant="h5">{el.name}</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    paddingLeft={2}
                    sx={{ width: { xs: "100%", lg: "60%" } }}
                    a
                  >
                    {el.system_settings.map((it, k) =>
                      it.type === "text" || it.type === "number" ? (
                        <InputLabel
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography sx={{ color: "black" }}>
                              <Box sx={{ fontWeight: "medium", m: 1 }}>
                                {it.label} :
                              </Box>
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FieldInput
                              name={
                                "n[" + i + "].system_settings[" + k + "].value"
                              }
                              label="วัน"
                              inputProps={{
                                inputProps: { sx: { textAlign: "center" } },
                              }}
                              inputType={it.type}
                            />
                          </Stack>
                        </InputLabel>
                      ) : it.type === "time" ? (
                        <InputLabel
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography sx={{ color: "black" }}>
                              <Box sx={{ fontWeight: "medium", m: 1 }}>
                                {it.label} :
                              </Box>
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FieldTimePicker
                              name={
                                "n[" + i + "].system_settings[" + k + "].value"
                              }
                              label="เวลา"
                              pickerProps={{
                                inputFormat: "HH:mm",
                                disableFuture: true,
                                openTo: "hours",
                                views: ["hours", "minutes"],
                                allowSameDateSelection: true,
                              }}
                              storeFormat="HH:mm"
                            />
                          </Stack>
                        </InputLabel>
                      ) : it.type === "datetime" ? (
                        <InputLabel
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingTop: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography sx={{ color: "black" }}>
                              <Box sx={{ fontWeight: "medium", m: 1 }}>
                                {it.label} :
                              </Box>
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <FieldDateTimePicker
                              name={
                                "n[" + i + "].system_settings[" + k + "].value"
                              }
                              label="วันที่"
                              pickerProps={{
                                inputFormat: "dd/MM/yyyy HH:mm",
                                disableFuture: true,
                                openTo: "year",
                                views: [
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ],
                                allowSameDateSelection: true,
                              }}
                              storeFormat="yyyy-MM-dd HH:mm"
                            />
                          </Stack>
                        </InputLabel>
                      ) : it.type === "radio" ? (
                        <InputLabel
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography sx={{ color: "black" }}>
                              <Box sx={{ fontWeight: "medium", m: 1 }}>
                                {it.label} :
                              </Box>
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Field
                              name={
                                "n[" + i + "].system_settings[" + k + "].active"
                              }
                              type="checkbox"
                            >
                              {({ input, meta }) => (
                                <>
                                  <AntSwitch
                                    defaultChecked={input.value}
                                    {...input}
                                  />
                                </>
                              )}
                            </Field>
                            <Typography sx={{ color: "black" }}>
                              <Box sx={{ fontWeight: "light", m: 1 }}>
                                เปิดใช้งาน
                              </Box>
                            </Typography>
                          </Stack>
                        </InputLabel>
                      ) : (
                        <></>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Divider></Divider>
              </Grid>
            </>
          )
        )}
      </Grid>

      {!readOnly && (
        <Grid container spacing={2}>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              <SubmitBtn
                variant="contained"
                startIcon={<Save />}
                submitting={submitting}
                pristine={pristine}
              >
                บันทึก
              </SubmitBtn>
              <Button
                type="button"
                variant="outlined"
                disabled={submitting}
                onClick={() => {
                  if (onCancel) {
                    onCancel(form);
                    return;
                  }

                  navigate("/");
                }}
              >
                ยกเลิก
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema().validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = () => ({
//   rank: Joi.string().max(255).required(),
//   cardId: Joi.string()
//     .pattern(/^\d{13}$/)
//     .required(),
//   laserCode: Joi.string().required(),
//   fname: Joi.string().max(255).required(),
//   lname: Joi.string().max(255).required(),
//   pol_tel: Joi.string()
//     .pattern(/^\d{10}$/)
//     .required(),
//   email: Joi.string()
//     .email({ tlds: { allow: false } })
//     .required(),
//   job_title: Joi.string().max(255).required(),
//   // agency_under: Joi.string().pattern(/^\d{5}$/),
//   // agency: Joi.string().pattern(/^\d{5}$/),
//   // agency_supervise: Joi.string().pattern(/^\d{5}$/),
//   agency_under: Joi.string().max(255),
//   agency: Joi.string().max(255),
//   agency_supervise: Joi.string().max(255),
//   password: Joi.string().min(8).required(),
//   confirm_password: Joi.string().min(8).required(),
//   status: Joi.bool(),
// });

// const schema = () => Joi.object(validateRules()).error(validationMsg());

export const mapToInit = (item) => {
  // let initValue = _.pick(item, Object.keys(validateRules()));
  // if (initValue.nationality === "TH") {
  //   initValue = _.omit(initValue, ["nationality"]);
  // } else {
  //   initValue.is_foreigner = true;
  //   initValue = _.omit(initValue, ["eng_name", "eng_midname", "eng_surname"]);
  // }

  return item;
};
