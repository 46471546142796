export function thousands_separators(num) {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

export function isNumeric(str) {
  if (typeof str === "number") return true;
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function nameComposer(title, fname, lname) {
  return (
    (title ? title + " " : "") +
    (fname ? fname + " " : "") +
    (lname ? lname : "")
  );
}
