import styled from "@emotion/styled";
import { Box, Modal, Typography } from "@mui/material";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import DateRangeForm from "./DateRangeForm";

// hide select all checkbox
const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    {
      display: "none",
    },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FieldComponent({
  name,
  readOnly = false,
  input,
  meta,
  rows,
  ...rest
}) {
  // const selectedCount = useRef(input.value.length || 0);
  // const selectedValues = useRef(input.value || []);
  // console.log(selectedCount.current);

  // const bankAcc = useMemo(() => {
  //   return [...rows];
  // }, [rows]);

  const [bankAcc, setBankAcc] = useState([...rows]);

  useEffect(() => {
    setBankAcc([...rows]);
  }, [rows]);

  // add account modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = () => {
    setOpenModal(false);
    input.onBlur();
  };

  const errorShow = meta.error || meta.submitError;

  return (
    <>
      {(meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
        _.isString(errorShow) &&
        meta.touched && (
          <Typography variant="caption" color="error.main">
            {errorShow}
          </Typography>
        )}
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <DataGrid
        {...rest}
        rows={bankAcc}
        checkboxSelection={!readOnly}
        onSelectionModelChange={(newSelectionModel, details) => {
          if (newSelectionModel.length) {
            const accId = _.last(newSelectionModel);
            const foundBa = bankAcc.find((ba) => ba.id === accId);
            handleOpenModal(
              <DateRangeForm
                header={
                  `${foundBa.bank?.short_name} : ${foundBa.number} : ${foundBa.name}` +
                  (foundBa.note ? `(${foundBa.note})` : "")
                }
                handleCloseModal={handleCloseModal}
                onSubmit={(formValues) => {
                  input.onChange([
                    {
                      bank_account_id: accId,
                      from_date: formValues.from_date,
                      to_date: formValues.to_date,
                    },
                  ]);
                  handleCloseModal();
                  setBankAcc(
                    bankAcc.map((ba) =>
                      ba.id === accId
                        ? {
                            ...ba,
                            from_date: formValues.from_date,
                            to_date: formValues.to_date,
                          }
                        : _.omit(ba, ["from_date", "to_date"])
                    )
                  );
                }}
              />
            );
          } else {
            input.onChange([]);
            setBankAcc(
              bankAcc.map((ba) => _.omit(ba, ["from_date", "to_date"]))
            );
            input.onBlur();
          }
        }}
        selectionModel={
          (input.value && input.value.map((sl) => sl.bank_account_id)) || []
        }
        disableSelectionOnClick={readOnly}
      />
    </>
  );
}
