// confirm bank-case-id which bank account owner request to freeze
import { Divider, Grid } from "@mui/material";
import React from "react";
import FieldInput from "../../../form-fields/FieldInput";
import LetterForm from "../../requests/request-forms/sub-forms/LetterForm";
import { getTextMask } from "../../../../helpers/imask";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";

const phoneNumberMask = getTextMask({
  // mask: "0000aaa[aa]00000",
  mask: /^0\d{0,9}$/,
  unmask: true,
  overwrite: true,
});
export default function CdrRequest({ readOnly }) {
  return (
    <>
      <Grid item xs={12}>
        <FieldInput
          name="phone_number"
          label="หมายเลขโทรศัพท์"
          required
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          inputProps={{
            placeholder: "0XXXXXXXXXX",
            inputComponent: phoneNumberMask,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldDatePicker
          name="from_date"
          label="ตั้งแต่วันที่"
          required
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldDatePicker
          name="to_date"
          label="ถึงวันที่"
          required
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center"></Divider>
      </Grid>
      <LetterForm readOnly={readOnly} />
    </>
  );
}
