import { LockOpen, Login } from "@mui/icons-material";
import { Alert, Button, CssBaseline, Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { MdLockOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import api, { setAuthorizationToken } from "../../../apis";
import { setAuthorizationToken as setAuthorizationToken2 } from "../../../apis/apigw";
import { authFetch } from "../../../ducks/auth";
import LocalStorageService from "../../../helpers/LocalStorageService";
import validationMsg from "../../../helpers/validationMsg";
import AlertIconDialog from "../../dialogs/AlertIconDialog";
import InfoDialog from "../../dialogs/InfoDialog";
import WarningDialog from "../../dialogs/WarningDialog";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
}).error(validationMsg());

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  const allowedEmaiTlds = [
    "police.go.th",
    "royalthaipolice.go.th",
    "rtp.go.th",
    "koder3.com",
    "gmail.com",
  ];
  const email = values.email;

  if (email != null) {
    // Extract the TLD from the email
    const emailTldMatch = email.match(/@(.+)$/);
    const emailTld = emailTldMatch ? emailTldMatch[1] : null;

    if (!errors.email && !allowedEmaiTlds.includes(emailTld)) {
      errors.email =
        "รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th เท่านั้น";
    }
  }

  return errors;
};

export default function VerifyEmailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [infoHeader, setInfoHeader] = useState("สำเร็จ");
  const [info, setInfo] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState();
  searchParams.get("state");

  const handleClose = () => {
    dispatch(authFetch());
    navigate("/me");
  };
  const handleChPass = () => {
    setError(false);
    setSuccess(false);
  };
  const handleLogin = () => {
    setInfo(false);
    navigate("/login");
  };

  const onSubmit = (formValues) => {
    return api
      .post(`/api/thaiid/verifyemail`, { state, ...formValues })
      .then((response) => {
        if (response.data.send_email_status) {
          setInfoHeader(response.data.title);
          return setInfo(response.data.message);
        }
        if (response.data.is_authen) {
          LocalStorageService.setToken(response.data);
          LocalStorageService.setUsername(formValues.email);
          setAuthorizationToken(LocalStorageService.getAccessToken());
          setAuthorizationToken2(LocalStorageService.getIaccessToken());
          setSuccess(true);
        }
        setError(response.data.message);
      })
      .catch((response) => {
        if (response.status === 422 || response.status === 404) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  useEffect(() => {
    setState(Object.fromEntries(searchParams).state);
  }, [searchParams]);
  return (
    <div className="grid justify-center items-center h-screen bg-[#F5F5F5]">
      <CssBaseline />
      <WarningDialog error={error} onClose={handleChPass} />
      <InfoDialog infoHeader={infoHeader} info={info} onClose={handleLogin} />
      <AlertIconDialog
        open={success}
        onClose={handleClose}
        title="กำหนดอีเมลเชื่อมกับ ThaiID สำเร็จ"
        maxWidth="sm"
        isHideDialogActions
      >
        <Grid container justifyContent={"center"}>
          <Grid item sx={12}>
            <Button
              onClick={handleClose}
              variant="outlined"
              startIcon={<Login />}
            >
              เข้าสู่ระบบ
            </Button>
          </Grid>
        </Grid>
      </AlertIconDialog>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="bg-white p-12 rounded-[32px] shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] relative mx-4"
      >
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <EnhancedEncryption />
        </Avatar> */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 p-1">
          <MdLockOutline className="h-16 w-16 bg-[#1976D3] rounded-full p-3 text-white" />
        </div>
        <h1 className="text-[28px]">Verify your Email</h1>
        <p className="mt-3">
          โปรดระบุ email (username) ของท่านเพื่อเชื่อมกับ ThaiID
        </p>
        <Form validate={validate} onSubmit={onSubmit}>
          {({ handleSubmit, error, submitError, submitting, submitFailed }) => {
            return (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {submitFailed && (error || submitError) && (
                  <Alert severity="warning" sx={{ width: "100%" }}>
                    {error || submitError}
                  </Alert>
                )}
                <FieldInput
                  name="email"
                  label="Email"
                  required
                  controlProps={{ fullWidth: true, margin: "normal" }}
                />
                <SubmitBtn
                  variant="contained"
                  submitting={submitting}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<LockOpen />}
                  size="large"
                >
                  ยืนยัน
                </SubmitBtn>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2">
                      กลับไปหน้าเข้าสู่ระบบ
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Form>
      </Box>
    </div>
  );
}
