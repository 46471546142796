import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import UserForm, { validate } from "./UserForm";
import { format, startOfToday } from "date-fns";
import { useDispatch } from "react-redux";
import { usersCreate } from "../../../../ducks/setting";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import api from "../../../../apis";

export default function UserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [dataPermission, setDataPermission] = useState([]);

  const [statusUser, setStatusUser] = useState("1");

  const onSubmit = (formData) => {
    const _status = { status: statusUser };
    // const _permission = { permission: dataPermission };
    const objData = { ...formData, roles: dataPermission, status: statusUser };

    return dispatch(usersCreate(objData))
      .then(() => {
        navigate("/settinguser");
      })
      .catch(({ response }) => {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
        // if (response?.status === 422) {
        //   const errors = _.mapValues(response?.data?.errors, (e) => e[0]);

        //   // convert laravel dot notation key to object
        //   const normalizeKeyError = {};
        //   for (const [key, value] of Object?.entries(errors)) {
        //     _.set(normalizeKeyError, key, value);
        //   }

        //   normalizeKeyError[FORM_ERROR] = response?.data?.message;

        //   // console.log(normalizeKeyError);
        //   return normalizeKeyError;
        // } else {
        //   return {
        //     [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        //   };
        // }
      });
  };

  // useEffect(() => {
  //   api
  //     .get(`/api/me`)
  //     .then(({ data }) => {})
  //     .catch(() => {})
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">เพิ่มผู้ใช้งาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              component={UserForm}
              setStatus={setStatusUser}
              setDataPermission={setDataPermission}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
