import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useEffect } from "react";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import { getTextMask } from "../../../helpers/imask";
import countryOptions from "../../../data/selects/countries";
import countries from "../../../data/selects/countries";

const TextMaskCustom = getTextMask({
  mask: "000000000000",
  unmask: true,
  overwrite: true,
});

export default function PersonalHistoryForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  data,
}) {
  useEffect(() => {
    if (data) {
      form.change("typePerson", data?.nationality === 1 ? "คนต่างชาติ" : "คนไทย" );
      form.change("cardId", data?.card_id);
      form.change("user",`${data?.fname}  ${data?.lname}` );
      form.change("country_code", countries?.filter((item) => item.value === data?.country_code)[0]?.label);
      form.change("type", data?.type?.type);
      form.change("status", data?.freeze_status);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="typePerson"
            label="ประเภทบุคคล"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="cardId"
            label="หมายเลขบัตร/เลขหนังสือเดินทาง"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="user"
            label="ชื่อ-สกุล"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="country_code"
            label="สัญชาติ/ประเทศ"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="type"
            label="ประเภท"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="status"
            label="สถานะอายัด"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </form>
  );
}
