import { List } from "@mui/icons-material";
import {
  Alert,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import {
  bankClearResult,
  bankFetch,
  bankForceReload,
  bankSetFilter,
} from "../../../../ducks/masterDataList";
import ChannelSendingSearchForm from "./ChannelSendingSearchForm";

export default function SettingChannelSending() {
  const qState = useSelector((state) => state.auth.user.permissions);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: reqStatus } = useParams();
  const [search, setSearch] = useState({});

  const {
    isLoading,
    data: channelData,
    refetch: channelRefetch,
  } = useQuery(["banks-table", search], () =>
    api
      .get(`/api/banks`, {
        params: {
          status: _.get(search, "status", ""),
          type: _.get(search, "channel", ""),
          search: _.get(search, "search_agency_name", ""),
        },
      })
      .then((res) => res.data)
  );

  useEffect(() => {
    channelRefetch();
  }, [search, channelRefetch]);

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(bankClearResult());
      dispatch(bankSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  // const rows = useSelector((state) => state.masterData.bank.rows);
  // console.log({rows})
  // const total = useSelector((state) => state.masterData.metaBank.total);
  const loading = useSelector((state) => state.masterData.metaBank.loading);
  const forceReload = useSelector(
    (state) => state.masterData.metaBank.forceReload
  );
  const page = useSelector((state) => state.masterData.metaBank.page);
  const pageSize = useSelector((state) => state.masterData.metaBank.pageSize);
  const filter = useSelector((state) => state.masterData.metaBank.filter);

  // const [rowsState, setRowsState] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      bankFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(bankForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const columns = [
    qState?.setting_agency?.includes("view") ||
    qState?.new_agency?.includes("edit")
      ? {
          field: "actions",
          headerName: "จัดการ",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            return (
              <>
                <Tooltip title="แก้ไข">
                  <IconButton
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(
                        `/settingchannel/edit/${_.get(params, "row.id")}`
                      );
                    }}
                  >
                    <List />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        }
      : null,
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 100,
    },
    {
      field: "status",
      headerName: "สถานะใช้งาน",
      sortable: false,
      // minWidth: 180,
      // maxWidth: 180,
      renderCell: (params) => (
        <div className="flex items-center justify-center w-full">
          <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              checked={_.get(params, "row.status", false)}
              type="checkbox"
              className="sr-only peer"
              onClick={() => {
                onSaveData(
                  {
                    status: !params.row.status,
                  },
                  _.get(params, "row.id")
                );
              }}
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      ),
    },
    {
      field: "type",
      headerName: "ประเภทบริการ",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            {
              { bank: "ธนาคาร", telco: "โทรศัพท์" }[
                _.get(params, "row.type", "")
              ]
            }
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "หน่วยงานภายนอก",
      sortable: true,
      width: 400,
      renderCell: (params) => {
        return (
          <span>{`${_.get(params, "row.name", "")} (${_.get(
            params,
            "row.short_name",
            ""
          )})`}</span>
        );
      },
    },
  ].filter(Boolean);

  useEffect(() => {
    channelRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onSaveData = (body, id) => {
    const fetchData = async () => {
      const response = await api.put(`/api/banks/${id}`, body).then((res) => {
        channelRefetch();
        return res;
      });
      return response;
    };
    const callFunction = fetchData();
    toast.promise(callFunction, {
      loading: <b>กำลังโหลด...</b>,
      success: <b>แก้ไขข้อมูลเสร็จสิ้น</b>,
      error: <b>ระบบเกิดความผิดพลาด กรุณาแจ้งเจ้าหน้าที่</b>,
    });
  };

  const onSearchSubmit = (formValues) => {
    setSearch(formValues);
    console.log({ formValues });
    // dispatch(bankSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">หน่วยงานภายนอก</Typography>
        <p>Master Data สำหรับตั้งค่าหน่วยงานภายนอก</p>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={ChannelSendingSearchForm}
            loading={loading}
            onReset={() => {
              dispatch(bankSetFilter({}));
              setSearch({});
            }}
            showStatusField={reqStatus ? false : true}
            page={page}
            pageSize={pageSize}
          />
          <DataGrid
            columns={columns}
            rows={_.get(channelData, "rows", [])}
            loading={isLoading}
          />
        </Paper>
      </Grid>
    </>
  );
}
