import { InfoOutlined, Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import FieldSingleFileDropZone from "../../../form-fields/FieldSingleFileDropZone";
import SubmitBtn from "../../../SubmitBtn";
import thaiIdValidator from "../../../../helpers/ThaiIdValidator";

// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";

export default function UserForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  id,
  idFromHr03,
}) {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [toastInfo, SetToastInfo] = React.useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const navigate = useNavigate();
  const [isErrorDowload, setIsErrorDowLoad] = React.useState(false);
  const [titleError, setTitleError] = React.useState(
    "ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ"
  );
  const [hr03_id, setHr03_id] = useState(undefined);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  const { data: infoGuide } = useQuery(
    ["/api/info_guide"],
    () => api.get(`/api/info_guide`).then((res) => res.data),
    { staleTime: 3 * 60 * 1000 }
  );

  const { isLoading, data: hrDetail } = useQuery(
    ["hr03", hr03_id],
    () =>
      api
        .get(`/api/requests_hr_lists?code_hr03=${hr03_id}`)
        .then((res) => {
          idFromHr03(res?.data?.id);
          return {
            ...res.data,
          };
        })
        .catch((err) => {
          SetToastInfo({ type: "warning", message: "ไม่พบข้อมูล HR03 ในระบบ" });
          setIsErrorDowLoad(true);
          setTitleError("ไม่พบข้อมูล HR03 ในระบบ");
          form.change("card_id_hr", "");
          form.change("nameHr", "");
          form.change("bankcode", "");
          form.change("accountno", "");
          return true;
        }),
    { enabled: !!hr03_id }
  );

  const { isLoading: isLoadingDetail, data: Detail } = useQuery(
    ["hr03Detail", id],
    () =>
      api.get(`/api/requests_open_bank_account/${id}`).then((res) => res.data),
    { staleTime: 60 * 1000, enabled: !!id }
  );

  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) =>
        res?.data?.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    if (Detail) {
      form.change("type", Detail?.type);
      form.change("hr03_id", Detail?.hr03_id);
      form.change("bank_id", Detail?.bank?.code);
      form.change("number", Detail?.number);
      form.change("bank_name", Detail?.bank_name);
      form.change("card_id", Detail?.card_id);
      form.change("name", Detail?.name);
      if (page === "edit") {
        form.change("hr03", Detail?.hr03);
        form.change(
          "hr03.fullName",
          Object.values(
            _.pick(Detail?.hr03, ["title", "name", "surname"])
          ).join(" ")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Detail]);

  useEffect(() => {
    if (hrDetail) {
      let nameHr = `${hrDetail?.title} ${hrDetail?.name ?? ""} ${
        hrDetail?.midname ?? ""
      } ${hrDetail?.surname ?? ""}`;
      form.change("card_id_hr", hrDetail?.card_id);

      if (nameHr !== "undefined   ") {
        form.change("nameHr", nameHr);
      } else {
        form.change("nameHr", "");
      }

      form.change("bankcode", hrDetail?.bankcode);
      form.change("accountno", hrDetail?.accountno);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrDetail]);

  const downloadFile = useCallback(async (fileUrl, fileName) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      // Create a temporary link element
      const link = document.createElement("a");

      link.href = url;
      link.download = `${fileName}.pdf`;

      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setIsErrorDowLoad(true);
    }
  }, []);

  return (
    <>
      {(isLoading || isLoadingDetail) && page === "edit" ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          {toastInfo?.type && (
            <Alert
              onClose={handleToastInfoClose}
              severity={toastInfo?.type || "warning"}
              sx={{ width: "100%" }}
            >
              {toastInfo?.message || ""}
            </Alert>
          )}

          <Dialog
            open={isErrorDowload}
            onClose={() => setIsErrorDowLoad(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" color="warning.main">
              Warning
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {titleError}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsErrorDowLoad(false);
                }}
              >
                ปิด
              </Button>
            </DialogActions>
          </Dialog>
          <form onSubmit={handleSubmit} noValidate>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" color="warning.main">
                Warning
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {error || submitError}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  ปิด
                </Button>
              </DialogActions>
            </Dialog>

            <Grid container spacing={2}>
              <Grid item xs={12} mt={3}>
                <Divider textAlign="center">ข้อมูลดั้งเดิม</Divider>
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldSelect
                  name="type"
                  label="ประเภทคำร้องของเปิดใช้งาน"
                  options={[
                    {
                      value: 1,
                      label: "รายบัญชี",
                    },
                    {
                      value: 2,
                      label: "รายชื่อ",
                    },
                  ]}
                  readOnly={page === "edit"}
                  required
                  controlProp={{ sx: { width: "100%" } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={page === "edit" ? "hr03.ref_code" : "hr03_id"}
                  label="เลข HR03"
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={page === "edit"}
                  inputProps={{
                    placeholder: "กรุณากรอกเลข HR03",
                  }}
                  onBlur={(e) => {
                    if (e === "") {
                      SetToastInfo(false);
                    }
                    setHr03_id(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={page === "edit" ? "hr03.card_id" : "card_id_hr"}
                  label={"เลขบัตรประชาชน"}
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={true}
                  disabled
                  inputProps={{
                    placeholder: "กรุณากรอกเลขบัตรประชาชน",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={page === "edit" ? "hr03.fullName" : "nameHr"}
                  label={"ชื่อ-สกุล"}
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={true}
                  disabled
                  inputProps={{
                    placeholder: "กรุณากรอกเลขบัตรประชาชน",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldAutoComplete
                  name={page === "edit" ? "hr03.bankcode" : "bankcode"}
                  label="ธนาคาร"
                  required
                  controlProp={{ fullWidth: true }}
                  inputProps={{
                    sx: { width: "100%" },
                    loading: isBankOptionsLoading || bankOptionsError,
                    loadingText: bankOptionsError ? "Error" : "Loading…",
                  }}
                  options={bankOptions}
                  readOnly={true}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={page === "edit" ? "hr03.accountno" : "accountno"}
                  label={"หมายเลขบัญชี"}
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={true}
                  disabled
                  inputProps={{
                    placeholder: "กรุณากรอกเลขบัตรประชาชน",
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={3}>
                <Divider textAlign="center">ข้อมูลที่จะถอน</Divider>
              </Grid>
              {(values?.type === 1 || !values?.type) && (
                <Grid item xs={12} md={6}>
                  <FieldAutoComplete
                    name="bank_id"
                    label="ธนาคาร"
                    required
                    controlProp={{ fullWidth: true }}
                    inputProps={{
                      sx: { width: "100%" },
                      loading: isBankOptionsLoading || bankOptionsError,
                      loadingText: bankOptionsError ? "Error" : "Loading…",
                    }}
                    options={bankOptions}
                    readOnly={readOnly}
                  />
                </Grid>
              )}
              {(values?.type === 1 || !values?.type) && (
                <Grid item xs={12} md={6}>
                  <FieldInput
                    name="number"
                    label={"หมายเลขบัญชี"}
                    required
                    readOnly={page === "edit"}
                    controlProps={{ fullWidth: true }}
                    inputProps={{
                      placeholder: "กรุณากรอกหมายเลขบัญชี",
                    }}
                  />
                </Grid>
              )}
              {(values?.type === 1 || !values?.type) && (
                <Grid item xs={12} md={12}>
                  <FieldInput
                    name="bank_name"
                    label={"ชื่อบัญชี"}
                    required
                    controlProps={{ fullWidth: true }}
                    readOnly={page === "edit"}
                    inputProps={{
                      placeholder: "กรุณากรอกชื่อบัญชี",
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <FieldInput
                  name="card_id"
                  label={"เลขบัตรประชาชน/เลขพาสปอร์ต"}
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={page === "edit"}
                  inputProps={{
                    placeholder: "กรุณากรอกเลขบัตรประชาชน/เลขพาสปอร์ต",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name="name"
                  label={"ชื่อ-สกุล"}
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={page === "edit"}
                  inputProps={{
                    placeholder: "กรุณากรอกชื่อ-สกุล",
                  }}
                />
              </Grid>
              <Grid item xs={12} mt={3}>
                <Divider textAlign="center">File Upload</Divider>
              </Grid>

              {page === "add" && request_account_HR03.includes("upload") ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      บันทึกสอบปากคำ *
                      {infoGuide && infoGuide[0] && (
                        <Tooltip
                          title={
                            <>
                              <p>{infoGuide && infoGuide[0].text}</p>
                              <img
                                src={
                                  infoGuide && infoGuide[0].base64
                                    ? infoGuide[0].base64
                                    : infoGuide && infoGuide[0].path
                                    ? infoGuide[0].path
                                    : ""
                                }
                                alt={infoGuide && infoGuide[0].text}
                              />
                              <Button
                                component={Link}
                                target="_blank"
                                href={infoGuide[0].file}
                                variant="contained"
                                color="primary"
                              >
                                Download
                              </Button>
                            </>
                          }
                        >
                          <InfoOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                    <FieldSingleFileDropZone
                      name="note_interrogation"
                      accept="application/pdf"
                      type="file"
                      required
                      disabled={page === "edit"}
                      placeholder={
                        <>
                          Drag 'n' drop some files here, or click to select
                          files <br />
                          (*.pdf) only (10 MB)
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      หนังสือบันทึกข้อความ *
                      {infoGuide && infoGuide[3] && (
                        <Tooltip
                          title={
                            <>
                              <p>{infoGuide && infoGuide[3].text}</p>
                              <img
                                src={
                                  infoGuide && infoGuide[3].base64
                                    ? infoGuide[3].base64
                                    : infoGuide && infoGuide[3].path
                                    ? infoGuide[3].path
                                    : ""
                                }
                                alt={infoGuide && infoGuide[3].text}
                              />
                              <Button
                                component={Link}
                                target="_blank"
                                href={infoGuide[3].file}
                                variant="contained"
                                color="primary"
                              >
                                Download
                              </Button>
                            </>
                          }
                        >
                          <InfoOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                    <FieldSingleFileDropZone
                      name="book_message"
                      accept="application/pdf"
                      type="file"
                      required
                      disabled={page === "edit"}
                      placeholder={
                        <>
                          Drag 'n' drop some files here, or click to select
                          files <br />
                          (*.pdf) only (10 MB)
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      สแกนบัตรประชาชน *
                      {infoGuide && infoGuide[1] && (
                        <Tooltip
                          title={
                            <>
                              <p>{infoGuide && infoGuide[1].text}</p>
                              <img
                                src={
                                  infoGuide && infoGuide[1].base64
                                    ? infoGuide[1].base64
                                    : infoGuide && infoGuide[1].path
                                    ? infoGuide[1].path
                                    : ""
                                }
                                alt={infoGuide && infoGuide[1].text}
                              />
                              <Button
                                component={Link}
                                target="_blank"
                                href={infoGuide[1].file}
                                variant="contained"
                                color="primary"
                              >
                                Download
                              </Button>
                            </>
                          }
                        >
                          <InfoOutlined color="primary" fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                    <FieldSingleFileDropZone
                      name="card_scan"
                      accept="application/pdf"
                      type="file"
                      required
                      disabled={page === "edit"}
                      placeholder={
                        <>
                          Drag 'n' drop some files here, or click to select
                          files <br />
                          (*.pdf) only (10 MB)
                        </>
                      }
                    />
                  </Grid>
                  {values?.type === 1 || !values?.type ? (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        {" "}
                        หน้า Book Bank *
                        {infoGuide && infoGuide[4] && (
                          <Tooltip
                            title={
                              <>
                                <p>{infoGuide && infoGuide[4].text}</p>
                                <img
                                  src={
                                    infoGuide && infoGuide[4].base64
                                      ? infoGuide[4].base64
                                      : infoGuide && infoGuide[4].path
                                      ? infoGuide[4].path
                                      : ""
                                  }
                                  alt={infoGuide && infoGuide[4].text}
                                />
                                <Button
                                  component={Link}
                                  target="_blank"
                                  href={infoGuide[4].file}
                                  variant="contained"
                                  color="primary"
                                >
                                  Download
                                </Button>
                              </>
                            }
                          >
                            <InfoOutlined color="primary" fontSize="small" />
                          </Tooltip>
                        )}
                      </Typography>
                      <FieldSingleFileDropZone
                        name="book_bank"
                        accept="application/pdf"
                        type="file"
                        required
                        disabled={page === "edit"}
                        placeholder={
                          <>
                            Drag 'n' drop some files here, or click to select
                            files <br />
                            (*.pdf) only (10 MB)
                          </>
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        {" "}
                        หน้าระบบ Banking *
                        {infoGuide && infoGuide[4] && (
                          <Tooltip
                            title={
                              <>
                                <p>{infoGuide && infoGuide[4].text}</p>
                                <img
                                  src={
                                    infoGuide && infoGuide[4].base64
                                      ? infoGuide[4].base64
                                      : infoGuide && infoGuide[4].path
                                      ? infoGuide[4].path
                                      : ""
                                  }
                                  alt={infoGuide && infoGuide[4].text}
                                />
                                <Button
                                  component={Link}
                                  target="_blank"
                                  href={infoGuide[4].file}
                                  variant="contained"
                                  color="primary"
                                >
                                  Download
                                </Button>
                              </>
                            }
                          >
                            <InfoOutlined color="primary" fontSize="small" />
                          </Tooltip>
                        )}
                      </Typography>
                      <FieldSingleFileDropZone
                        name="banking"
                        accept="application/pdf"
                        type="file"
                        required
                        disabled={page === "edit"}
                        placeholder={
                          <>
                            Drag 'n' drop some files here, or click to select
                            files <br />
                            (*.pdf) only (10 MB)
                          </>
                        }
                      />
                    </Grid>
                  )}
                  {values?.type === 1 || !values?.type ? (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        เอกสารประกอบเหตุผล *
                        {infoGuide && infoGuide[2] && (
                          <Tooltip
                            title={
                              <>
                                <p>{infoGuide && infoGuide[2].text}</p>
                                <img
                                  src={
                                    infoGuide && infoGuide[2].base64
                                      ? infoGuide[2].base64
                                      : infoGuide && infoGuide[2].path
                                      ? infoGuide[2].path
                                      : ""
                                  }
                                  alt={infoGuide && infoGuide[2].text}
                                />
                                <Button
                                  component={Link}
                                  target="_blank"
                                  href={infoGuide[2].file}
                                  variant="contained"
                                  color="primary"
                                >
                                  Download
                                </Button>
                              </>
                            }
                          >
                            <InfoOutlined color="primary" fontSize="small" />
                          </Tooltip>
                        )}
                      </Typography>
                      <FieldSingleFileDropZone
                        name="infomation_reason"
                        accept="application/pdf"
                        type="file"
                        required
                        disabled={page === "edit"}
                        placeholder={
                          <>
                            Drag 'n' drop some files here, or click to select
                            files <br />
                            (*.pdf) only (10 MB)
                          </>
                        }
                      />
                    </Grid>
                  ) : null}
                </>
              ) : request_account_HR03.includes("download") ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      บันทึกสอบปากคำ *
                    </Typography>
                    <Typography
                      style={{
                        textDecoration: "underline",

                        color: "blue",
                        cursor: "pointer",
                      }} // Optional: Custom styles
                      onClick={() =>
                        downloadFile(
                          Detail?.note_interrogation.slice(1),
                          "บันทึกสอบปากคำ"
                        )
                      }
                    >
                      ดาวน์โหลดไฟล์
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      หนังสือบันทึกข้อความ *
                    </Typography>

                    <Typography
                      style={{
                        textDecoration: "underline",

                        color: "blue",
                        cursor: "pointer",
                      }} // Optional: Custom styles
                      onClick={() =>
                        downloadFile(
                          Detail?.book_message.slice(1),
                          "หนังสือบันทึกข้อความ"
                        )
                      }
                    >
                      ดาวน์โหลดไฟล์
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">
                      สแกนบัตรประชาชน *
                    </Typography>
                    <Typography
                      style={{
                        textDecoration: "underline",

                        color: "blue",
                        cursor: "pointer",
                      }} // Optional: Custom styles
                      onClick={() =>
                        downloadFile(
                          Detail?.card_scan.slice(1),
                          "สแกนบัตรประชาชน"
                        )
                      }
                    >
                      ดาวน์โหลดไฟล์
                    </Typography>
                  </Grid>
                  {values?.type === 1 || !values?.type ? (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        {" "}
                        หน้า Book Bank *{" "}
                      </Typography>
                      <Typography
                        style={{
                          textDecoration: "underline",

                          color: "blue",
                          cursor: "pointer",
                        }} // Optional: Custom styles
                        onClick={() =>
                          downloadFile(
                            Detail?.book_bank.slice(1),
                            "หน้า Book Bank"
                          )
                        }
                      >
                        ดาวน์โหลดไฟล์
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        {" "}
                        หน้าระบบ Banking *{" "}
                      </Typography>
                      <Typography
                        style={{
                          textDecoration: "underline",

                          color: "blue",
                          cursor: "pointer",
                        }} // Optional: Custom styles
                        onClick={() =>
                          downloadFile(
                            Detail?.banking.slice(1),
                            "หน้าระบบ Banking"
                          )
                        }
                      >
                        ดาวน์โหลดไฟล์
                      </Typography>
                    </Grid>
                  )}

                  {values?.type === 1 || !values?.type ? (
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle1">
                        เอกสารประกอบเหตุผล *
                      </Typography>
                      <Typography
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }} // Optional: Custom styles
                        onClick={() =>
                          downloadFile(
                            Detail?.infomation_reason?.slice(1),
                            "เอกสารประกอบเหตุผล"
                          )
                        }
                      >
                        ดาวน์โหลดไฟล์
                      </Typography>
                    </Grid>
                  ) : null}
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <SubmitBtn
                    variant="contained"
                    startIcon={<Save />}
                    submitting={submitting}
                    {...(page === "edit" ? { disabled: true } : {})}
                    // disabled={page === "edit"}
                    pristine={pristine}
                  >
                    บันทึก
                  </SubmitBtn>
                  <Button
                    variant="outlined"
                    disabled={submitting}
                    onClick={() => navigate("/hr03/request-active")}
                  >
                    ยกเลิก
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = () => ({
  type: Joi.alternatives().try(Joi.number(), Joi.string()).required().messages({
    "any.required": "กรุณาระบุประเภทคำร้องขอเปิดใช้งาน",
  }),
  hr03_id: Joi.string()
    .messages({
      "string.empty": "กรุณากรอกเลข HR03",
      "any.required": "กรุณากรอกเลข HR03",
      "string.pattern.base": "กรุณากรอกเลข HR03 ให้ถูกต้อง ex. HR0001, AO0001",
    })
    .pattern(new RegExp("^HR[0-9]{1,}|^AO[0-9]{1,}$"))
    .required(),
  bank_id: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(2), Joi.string().valid("2")),
    then: Joi.optional(),
    otherwise: Joi.required().messages({
      "any.required": "กรุณาระบุธนาคาร",
    }),
  }),

  number: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(2), Joi.string().valid("2")),
    then: Joi.optional(),
    otherwise: Joi.string().min(10).max(14).required().messages({
      "any.required": "กรุณากรอกหมายเลขบัญชี",
      "string.min": "หมายเลขบัญชีต้องมีความยาวอย่างน้อย 10 หลัก",
      "string.max": "หมายเลขบัญชีต้องมีความยาวไม่เกิน 14 หลัก",
    }),
  }),

  bank_name: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(2), Joi.string().valid("2")),
    then: Joi.optional(),
    otherwise: Joi.required().messages({
      "any.required": "กรุณากรอกชื่อบัญชี",
    }),
  }),

  card_id: Joi.string()
    .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
    .required()
    .messages({
      "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
    }),
  name: Joi.string()
    .messages({
      "string.empty": "กรุณากรอกชื่อ-สกุล",
      "any.required": "กรุณากรอกชื่อ-สกุล",
    })
    .required(),
  note_interrogation: Joi.any()
    .messages({
      "string.empty": "กรุณาอัพโหลดเอกสารบันทึกสอบปากคำ",
      "any.required": "กรุณาอัพโหลดเอกสารบันทึกสอบปากคำ",
    })
    .required(),
  book_message: Joi.any()
    .messages({
      "string.empty": "กรุณาอัพโหลดเอกสารหนังสือบันทึกข้อความ",
      "any.required": "กรุณาอัพโหลดเอกสารหนังสือบันทึกข้อความ",
    })
    .required(),
  card_scan: Joi.any()
    .messages({
      "string.empty": "กรุณาอัพโหลดเอกสารสแกนบัตรประชาชน",
      "any.required": "กรุณาอัพโหลดเอกสารสแกนบัตรประชาชน",
    })
    .required(),
  book_bank: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(2), Joi.string().valid("2")),
    then: Joi.optional(),
    otherwise: Joi.required().messages({
      "any.required": "กรุณาอัพโหลดเอกสารหน้า Book Bank",
    }),
  }),
  banking: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(1), Joi.string().valid("1")),
    then: Joi.optional(),
    otherwise: Joi.required().messages({
      "any.required": "กรุณาอัพโหลดหน้าระบบ Banking",
    }),
  }),

  // book_bank: Joi.any()
  //   .messages({
  //     "string.empty": "กรุณาอัพโหลดเอกสารหน้า Book Bank",
  //     "any.required": "กรุณาอัพโหลดเอกสารหน้า Book Bank",
  //   })
  //   .required(),
  infomation_reason: Joi.any().when(Joi.ref("type"), {
    is: Joi.alternatives().try(Joi.number().valid(2), Joi.string().valid("2")),
    then: Joi.optional(),
    otherwise: Joi.required().messages({
      "any.required": "กรุณาอัพโหลดเอกสารเอกสารประกอบเหตุผล",
    }),
  }),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = () => Joi.object(validateRules());

export const mapToInit = (item) => {
  return item;
};
