import { UPDATE_FORM_STATE } from "./ducks/finalForm";

export const actionSanitizer = (action) =>
  action.type === UPDATE_FORM_STATE && action.payload?.form
    ? {
        ...action,
        payload: {
          ...action.payload,
          form: "<<FORM_OBJ>>",
          state: "<<STATE_OBJ>>",
        },
      }
    : action;
export const stateSanitizer = (state) => {
  const finalForm = state.finalForm;
  const transactionForm = state.finalForm?.transaction;

  return state.finalForm?.transaction?.form
    ? {
        ...state,
        finalForm: {
          ...finalForm,
          transaction: {
            ...transactionForm,
            form: "<<FORM_OBJ>>",
            state: "<<STATE_OBJ>>",
          },
        },
      }
    : state;
};
