import React from "react";
import { Field } from "react-final-form";
import FieldComponent from "./FieldComponent";

const FormField = (props) => {
  return (
    <Field
      {...props}
      component={FieldComponent}
      parse={(value) => value?.value}
    />
  );
};

export default FormField;
