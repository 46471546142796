import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import LocalStorageService from "./helpers/LocalStorageService";
import { setAuthorizationToken, initApi } from "./apis";
import { authFetch, authLogout, authMarkFetching } from "./ducks/auth";
import { actionSanitizer, stateSanitizer } from "./reduxDevTool";
import { pdfjs } from "react-pdf";
import { QueryClient, QueryClientProvider } from "react-query";
import { g9initApi as initApi2 } from "./apis/apigw";
import { Toaster } from "react-hot-toast";
// import ReactGA from 'react-ga';

// ReactGA.initialize('Your Unique ID');
// ReactGA.pageview(window.location.pathname + window.location.search);

// react-pdf config (pdf.worker.js need to be copied from react-pdf build in node module (see pagkage.json scripts))
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker${
  process.env.NODE_ENV === "development" ? "" : ".min"
}.js`;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
      stateSanitizer,
    })
  : compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

initApi(store);
initApi2(store);

if (LocalStorageService.getAccessToken()) {
  setAuthorizationToken(LocalStorageService.getAccessToken());
  store.dispatch(authMarkFetching(true));
  store
    .dispatch(authFetch())
    .catch((e) => {
      console.error(e);
      store.dispatch(authLogout());
    })
    .finally(() => store.dispatch(authMarkFetching(false)));
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" />
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
