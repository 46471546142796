import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo } from "react";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";
import { useQuery } from "react-query";
import api from "../../../../apis";
import { useEffect, useCallback } from "react";
import { format, startOfToday } from "date-fns";

const RequestInformation = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const titleChart = "ข้อมูลการออกหมายคำขอ";

  const {
    data: RequestInformationData,
    refetch: RequestInformationDataRefetch,
    isLoading: RequestInformationDataIsLoading,
    isError: RequestInformationDataIsError,
  } = useQuery(
    ["RequestInformationData", date, type], // Added dependencies here
    () =>
      api
        .get(
          `/api/dashboard/RequestAll?date=${date}&report_type=${type}`
        )
        .then((res) => res.data),
    { staleTime: 3 * 60 * 1000 }
  );

  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#FF4560", "#506A85"],
      yaxis: { title: { text: "การออกหมาย (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const series = useCallback((data) => {
    return [
      {
        name: "หมายคำขอ",
        data: data?.count_request,
      },
      {
        name: "ตอบกลับ",
        data: data?.count_response,
      },
    ];
  }, []);

  const card = useMemo(() => {
    return [
      {
        title: "หมายทั้งหมด",
        color: "#EF5350",
        count: RequestInformationData?.count_request_all,
      },
      {
        title: "ตอบกลับหมาย",
        color: "#506A85",
        count: RequestInformationData?.count_response_all,
      },
    ];
  }, [RequestInformationData]);

  // UseEffect to refetch when date or report_type changes
  useEffect(() => {
    RequestInformationDataRefetch();
  }, [date, type, RequestInformationDataRefetch]);



  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6"> {titleChart}</Typography>
        </Grid>

        {RequestInformationDataIsLoading ? (
          <>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={5} sm={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                      minWidth: "200px",
                    }}
                  >
                      <p
                       style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <LineChart
                options={options(titleChart, RequestInformationData?.labal)}
                series={series(RequestInformationData)}
              />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default RequestInformation;
