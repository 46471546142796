import { Search } from "@mui/icons-material";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormState } from "react-final-form";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../apis";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";

export default function DataExternalReportSearchForm({
  loading,
  onReset,
  handleSubmit,
  form,
  pristine,
  SetRequestNameLabel,
  SetBankNameLabel,
  SetRequestName,
  SetBankName,
}) {
  const report_static_org_external = useSelector(
    (state) => state.auth.user?.permissions?.report_static_org_external ?? []
  );
  const formState = useFormState();
  const didRequest = useRef(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const [reportType, setReportType] = useState(
    formState.values["request_type_id"]
  );

  const { data: options, refetch: orgCodeRefetch } = useQuery(
    ["/api/reports_external_dropdown", { value: "id", label: "name" }],
    () =>
      reportType &&
      api
        .get(`/api/reports_external_dropdown`, {
          params: { request_type_id: reportType },
        })
        .then((res) => {
          let tmp = res.data.data;
          return {
            request_types: tmp?.request_types?.map((row) => ({
              value: row.id,
              label: row.name,
            })),
            bank: tmp?.bank?.map((row) => ({
              value: row.id,
              label: row.name,
            })),
          };
        }),
    { staleTime: 3 * 60 * 1000 }
  );

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    setReportType(formState.values["request_type_id"]);
    options?.request_types &&
      SetRequestNameLabel(
        options?.request_types.filter(
          (el) => el.value === formState.values["request_type_id"]
        )[0]?.label
      );
    options?.bank &&
      SetBankNameLabel(
        options?.bank.filter(
          (el) => el.value === formState.values["bank_id"]
        )[0]?.label
      );
  }, [formState.values]);

  useEffect(() => {
    if (reportType) {
      orgCodeRefetch();
      form.change("bank_id", -1);
    }
  }, [reportType, orgCodeRefetch]);

  useEffect(() => {
    if (!didRequest.current && options) {
      SetRequestName(
        options?.request_types.filter(
          (el) => el.value === formState.values["request_type_id"]
        )[0]?.label
      );
      SetBankName(
        options?.bank.filter(
          (el) => el.value === formState.values["bank_id"]
        )[0]?.label
      );
      didRequest.current = true;
    }
  }, [options]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {report_static_org_external.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="request_type_id"
              label="ประเภทหมายคำร้อง"
              options={options?.request_types}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="bank_id"
              label="หน่วยงาน"
              options={options?.bank}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              disabled={!reportType}
              readOnly={!reportType}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} xl={6} />
          <Grid item xs={12} lg={6}>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={2}
            >
              <FieldDatePicker
                name="start_date"
                label="ตั้งแต่วันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
              <FieldDatePicker
                name="end_date"
                label="ถึงวันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={12} lg={4}>
            {report_static_org_external.includes("search") && (
              <Stack direction="row" spacing={2} alignItems="center">
                <SubmitBtn
                  variant="contained"
                  startIcon={<Search />}
                  submitting={loading}
                  pristine={pristine}
                >
                  ค้นหา
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={loading || pristine}
                  onClick={(event) => {
                    form.reset();
                    SetRequestName(
                      options?.request_types.filter(
                        (el) =>
                          el.value ===
                          form.getFieldState("request_type_id").initial
                      )[0]?.label
                    );
                    SetBankName(
                      options?.bank.filter(
                        (el) =>
                          el.value === form.getFieldState("bank_id").initial
                      )[0]?.label
                    );
                    if (onReset) onReset(event);
                  }}
                >
                  Reset
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
