import { Restore, Search } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

export default function Hr03MeetingSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  setBtnConfirm,
}) {
  const status = [{ value: "waiting", label: "เตรียมเข้าประชุม" }];
  const qState = useSelector((state) => state.auth.user.permissions);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={3}>
          <FieldAutoComplete
            name="status"
            label="สถานะ"
            options={status}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/policestations"
            requestParams={{ page_size: undefined, page: undefined }}
            itemFormat={(row) => {
              return {
                value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                label: row.ORG_ABBR,
              };
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            // filter={{ search: "xxxx"}}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
        {qState?.HR03_filter?.includes("confirm") ? (
          <Grid item md={12} lg={8}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                startIcon={<Restore />}
                onClick={() => {
                  setBtnConfirm(true);
                }}
              >
                เคลียร์รายการคงค้าง
              </Button>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
}
