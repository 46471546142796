import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo } from "react";
import { format, startOfToday } from "date-fns";
import { useQuery } from "react-query";
import api from "../../../../apis";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";
import { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import { useEffect, useCallback } from "react";

const SystemloginStatistics = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const [showType, setShowType] = useState("all");

  const titleChart = "สถิติข้อมูลการเข้าใช้งานระบบ (Login)";
 
  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#50B238", "#8A81FF", "#E480C3"],
      yaxis: { title: { text: "Login (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const {
    data: SystemloginStatisticsData,
    refetch: SystemloginStatisticsDataRefetch,
    isLoading: SystemloginStatisticsDataIsLoading,
    isError: SystemloginStatisticsDataIsError,
  } = useQuery(["SystemloginStatisticsData",date, type,showType], () =>
    api
      .get(`/api/chart_login`, {
        params: {
          show_type: showType,
          date: date,
          type: type,
        },
      })
      .then((res) => res.data)
  );

  const series = useCallback((data) => {
    return [
      {
        name: "Success",
        data: data?.success,
      },
      {
        name: "Fail",
        data: data?.failed
        ,
      },
      {
        name: "Reset Password",
        data: data?.change_password,
      },
    ];
  }, []);

  const card = useMemo(() => {
    return [
      {
        title: "เข้าระบบสำเร็จ",
        color: "#50B238",
        count: SystemloginStatisticsData?.total_success,
      },
      {
        title: "เข้าระบบไม่สำเร็จ",
        color: "#8A81FF",
        count: SystemloginStatisticsData?.total_failed,
      },
      {
        title: "เปลี่ยนรหัสผ่าน",
        color: "#E480C3",
        count: SystemloginStatisticsData?.total_change_password,
      },
    ];
  }, [SystemloginStatisticsData]);


  useEffect(() => {
    SystemloginStatisticsDataRefetch();
  }, [date, type,showType, SystemloginStatisticsDataRefetch]);

  useEffect(() => {
    console.log("SystemloginStatisticsData", SystemloginStatisticsData);
  }, [SystemloginStatisticsData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">{titleChart}</Typography>
        </Grid>

        {SystemloginStatisticsDataIsLoading ? (
          <>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                    }}
                  >
                       <p
                       style={{
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",

                  width: "100%",
                  paddingRight: 2,
                  paddingTop: 1,
                  marginBottom: "-37px",
                }}
              >
                <Select
                  labelId="select-label"
                  value={showType}
                  onChange={(event) => {
                    setShowType(event.target.value);
                  }}
                  label="Select an option"
                  defaultValue="user"
                  sx={{
                    height: "25px",
                    fontSize: "12px",
                    marginBottom: "5px",
                    zIndex: 1000,
                  }}
                >
                  <MenuItem value="user">จำนวนผู้ใช้ </MenuItem>
                  <MenuItem value="all">จำนวนครั้ง</MenuItem>
                </Select>
              </Box>

              <LineChart options={
                options(
                  titleChart,
                  SystemloginStatisticsData?.x_labels
                )
              } series={
                series(SystemloginStatisticsData)
              }
               />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default SystemloginStatistics;
