import { Grid, Box, Typography, Paper } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";
import LineChart from "../chart";
import { useMemo,useCallback ,useEffect } from "react";
import { format, startOfToday } from "date-fns";
import { useQuery } from "react-query";
import api from "../../../../apis";
import CardSkeleton from "../skeleton/card";
import ChartSkeleton from "../skeleton/chart";

const ExternallyProvided1 = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const titleChart = "การใช้งาน API ที่ให้บริการกับภายนอก";
 
  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#52BBC9", "#FF926A"],
      yaxis: { title: { text: "การส่งเมล (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const series = useCallback((data) => {
    return [
      {
        name: "Success",
        data: data?.success,
      },
      {
        name: "Fail",
        data: data?.failed,
      }
    ];
  }, []);


  const {
    data: ExternallyProvided1Data,
    refetch: ExternallyProvided1DataRefetch,
    isLoading: ExternallyProvided1DataIsLoading,
    isError: ExternallyProvided1DataIsError,
  } = useQuery(["ExternallyProvided1Data",date, type], () =>
    api
      .get(`/api/chart_api_external`,{
        params: { 
          date,
          type
         }
      })
      .then((res) => res.data)
  );

  const card = useMemo(() => {

    return [
      {
        title: "API ส่งเมลสำเร็จ",
        color: "#52BBC9",
        count: ExternallyProvided1Data?.total_success,
      },
      {
        title: "API ส่งเมลไม่สำเร็จ",
        color: "#FF926A",
        count: ExternallyProvided1Data?.total_failed,
      }
    ]

  }, [ExternallyProvided1Data]);

  useEffect(() => {
    ExternallyProvided1DataRefetch();
  }, [date, type, ExternallyProvided1DataRefetch]);



  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6">{titleChart}</Typography>
        </Grid>

        {ExternallyProvided1DataIsLoading ? (
          <>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <CardSkeleton />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ChartSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {card?.map((item, index) => {
              return (
                <Grid item md={5} sm={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.color,
                      padding: 2,
                      height: "90px", // Adjust height
                      borderRadius: "8px",
                      width: "100%",
                      minWidth: "200px",
                    }}
                  >
                     <p
                       style={{
                          color: "white",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "center",
                          fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                          lineHeight: "1.5",
                       }}>
                          {item?.title}

                     </p>

                     <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
                        lineHeight: "71.76px",
                     }}
                     >

                     </p>
                  
                    <Typography variant="h4" color="white" fontWeight="bold">
                      <CountUp
                        separator=","
                        start={0}
                        end={_.isNil(item?.count) ? 0 : item?.count}
                      />
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                marginLeft: 2,
                marginTop: 2,
              }}
            >
              <LineChart options={
                options("API การส่งเมลไปยังหน่วยงานภายนอก", ExternallyProvided1Data?.x_labels)
              } series={
                series(ExternallyProvided1Data)
              } />
            </Paper>
          </>
        )}
      </Grid>
    </>
  );
};

export default ExternallyProvided1;
