import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import Hr03AccountForm, { validate } from "../hr03/Hr03AccountForm";
import api from "../../../apis";
import { useQuery } from "react-query";
import Resizer from "react-image-file-resizer";
import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const mapToInit = (data) => {
  if (!data) {
    return undefined;
  }

  return {
    rank: data.rank,
    pol_name: data.fname,
    pol_surname: data.lname,
    pol_job_title: data.job_title,
    pol_org_code: data.policestation_org_code,
    pol_tel: data.pol_tel,
  };
};

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

export default function Hr03AccountAddPage() {
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(
    "me",
    () => api.get(`/api/me`).then((res) => res.data),
    { staleTime: 10 * 1000 }
  );

  const onSubmit = async (formData) => {
    let submitData = null;
    if (formData.pol_signature) {
      // resize before submit
      try {
        const signatureBase64 = await signatureResize(formData.pol_signature);
        submitData = { ...formData, pol_signature: signatureBase64 };
      } catch (e) {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          signature: "เกิดข้อผิดพลาด",
        };
      }
    } else {
      submitData = formData;
    }

    if (submitData.is_foreigner) {
      submitData.eng_name = submitData.name;
      submitData.eng_midname = submitData.midname || undefined;
      submitData.eng_surname = submitData.surname;
    } else {
      submitData.nationality = "TH";
    }

    try {
      await api.post(`/api/hr_reports`, submitData);
      navigate(`/hr03`);
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">เพิ่มบัญชี</Typography>
        <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              initialValues={mapToInit(data)}
              component={Hr03AccountForm}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
