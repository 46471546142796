import { isValid, format as formatDate, parse as parseDate } from "date-fns";
import React from "react";
import { Field } from "react-final-form";
import { isInvalidDateObject } from "../../../helpers/dateHelper.js";
import FieldComponent from "./FieldComponent.js";
import { he } from "date-fns/locale";
// import { useDefaultDates } from "@mui/lab/internal/pickers/hooks/useUtils.js";

const FormField = ({ storeFormat = "yyyy-MM-dd",defaultValue, ...props }) => {
  // const defaultDates = useDefaultDates(); // picker also use this default date to provide min and max date if not specify.

  const parse = (value) => {
    if (isValid(value)) {
      return formatDate(value, storeFormat);
    } else if (isInvalidDateObject(value)) {
      // store Invalid Date object
      return value;
    }

    // set undefined to remove from form values
    return undefined;
  };
  const format = (value) => {
    if (!value) {
      return undefined;
    }

    if (isInvalidDateObject(value)) {
      return value;
    }

    return parseDate(value, storeFormat, new Date());
  };

  return (
    <Field
      {...props}
      component={FieldComponent}
      
      parse={parse}
      format={format}
      defaultValue={defaultValue && formatDate(defaultValue, storeFormat)}

    />
  );
};

export default FormField;
