import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";

import { Add, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import SubmitBtn from "../../../SubmitBtn";

export default function UserSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  usersData,
}) {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {request_account_HR03.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <FieldAsyncSelect
              name="bank_id"
              label="ธนาคาร"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/banks`}
              // filter={{ type: "bank" }}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: `${row.name} (${row.short_name})`,
                };
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <FieldInput
              name="number"
              label="เลขบัญชี"
              controlProps={{ fullWidth: true }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4} xl={4}>
            <FieldSelect
              name="type"
              label="ประเภทคำร้อง"
              options={[
                {
                  value: "1",
                  label: "รายบัญชี",
                },
                {
                  value: "2",
                  label: "รายชื่อ",
                },
              ]}
              controlProp={{ sx: { width: "100%" } }}
            />

            {/* <FieldAsyncAutoComplete
            name="external_org_type"
            label="ประเภทหน่วยงาน"
            required
            
            controlProp={{ fullWidth: true }}
            basePath="/api/externaltype"
            itemFormat={(row) => {
              return {
                value: row,
                label: row.name,
              };
            }}
          /> */}
          </Grid>

          <Grid item xs={12} lg={8} xl={3}>
            <FieldInput
              name="search"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4} xl={4}>
          <FieldAutoComplete
            name="role"
            label="ตำเเหน่ง"
            options={roles || []}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            //   inputProps={{ sx: { fullWidth: true } }}
          />
        </Grid> */}
        </Grid>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          {request_account_HR03.includes("search") && (
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item md={12} lg={8}>
          {request_account_HR03.includes("create") && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                component={Link}
                to="/hr03/request-active/add"
              >
                เพิ่ม
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
