import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, startOfToday } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../../../../apis";
import LineChart from "../chart";
import ChartSkeleton from "../skeleton/chart";
const TypeOfRequest = ({
  date = format(startOfToday(), "yyyy-MM-dd"),
  type = "h",
}) => {
  const [accordion, setAccordion] = useState(false);

  const titleChart = "หมายคำขอตามแต่ละประเภทหมาย";

  const options = useCallback((title, categories) => {
    return {
      chart: {
        id: "basic-line",
        toolbar: { show: false },
        fontFamily: 'Kanit, sans-serif', // Apply Kanit globally
        
      },
      stroke: { curve: "smooth", width: 2 },
      xaxis: { categories: categories ?? [] },
      title: {
        text: title,
        align: "center",
        style: { fontSize: "16px", fontWeight: "medium" },
      },
      colors: ["#FF5666", "#506A85"],
      yaxis: { title: { text: "การออกหมาย (จำนวน)" } },
      legend: { position: "top" },
    };
  }, []);

  const series = useCallback((data) => {
    return [
      {
        name: "หมายคำขอ",
        data: data?.count_request,
      },
      {
        name: "ตอบกลับ",
        data: data?.count_response,
      },
    ];
  }, []);

  const {
    data: TypeOfRequestTableData,
    refetch: TypeOfRequestTableDataRefetch,
    isLoading: TypeOfRequestTableDataIsLoading,
    isError: TypeOfRequestTableDataIsError,
  } = useQuery(["TypeOfRequestTableData",date, type], () =>
    api
      .get(`/api/dashboard/RequestByTypeGroup`, {
        params: {
          date: date,
          report_type: type,
        },
      })
      .then((res) => res.data)
  );

  const {
    data: TypeOfRequestChartData,
    refetch: TypeOfRequestCharDataRefetch,
    isLoading: TypeOfRequestCharDataIsLoading,
    isError: TypeOfRequestCharDataIsError,
  } = useQuery(["TypeOfRequestChartData",date, type], () =>
    api
      .get(`/api/dashboard/RequestByType`, {
        params: {
          date: date,
          report_type: type,
        },
      })
      .then((res) => res.data)
  );

  useEffect(() => {
    console.log("TypeOfRequestChartData", TypeOfRequestChartData);
  }, [TypeOfRequestChartData]);

  const columns = [
    {
      field: "request_type",
      headerName: "ประเภทหมายคำขอ",
      sortable: false,
      flex: 1,
    },
    {
      field: "count_request",
      headerName: "คำร้องขอ",
      sortable: false,
      maxWidth: 75,
      align: "center",
    },
    {
      field: "count_response",
      headerName: "ตอบกลับ",
      sortable: false,
      maxWidth: 75,
      align: "center",
    },
  ];

  useEffect(() => {
    TypeOfRequestCharDataRefetch();
  }, [date, type, TypeOfRequestCharDataRefetch]);

 
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid item md={12}>
          <Box display="flex" alignItems="center">
            {accordion ? (
              <KeyboardArrowDownIcon
                style={{
                  color: "white",
                  fontSize: "20px",
                  backgroundColor: "#4D4D4D",
                  borderRadius: "10%",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAccordion(!accordion);
                }}
              />
            ) : (
              <KeyboardArrowRightIcon
                style={{
                  color: "white",
                  fontSize: "20px",
                  backgroundColor: "#4D4D4D",
                  borderRadius: "10%",
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAccordion(!accordion);
                }}
              />
            )}
            <Typography variant="h6">{titleChart}</Typography>
          </Box>
        </Grid>
      </Grid>
      {accordion && (
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            {TypeOfRequestTableDataIsLoading ? (
              <ChartSkeleton />
            ) : (
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "430px",
                  width: "100%",

                  padding: 2,
                }}
              >
                <DataGrid
                  disableColumnMenu
                  columns={columns}
                  rows={
                    TypeOfRequestTableData?.map((item, index) => {
                      return {
                        id: index,
                        request_type: item.request_type,
                        count_request: item.count_request,
                        count_response: item.count_response,
                      };
                    }) ?? []
                  }
                  rowCount={TypeOfRequestTableData?.length ?? 0}
                  loading={TypeOfRequestTableDataIsLoading}
                  // pagination={false}
                  pageSize={100}
                  width="100%"
                  autoHeight
                  hideFooter
                  rowHeight={35}
                  sx={{
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#efefef",
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                      justifyContent: "center",
                      fontWeight: 700,
                    },
                  }}
                />
              </Paper>
            )}
          </Grid>
          {TypeOfRequestCharDataIsLoading ? (
            <ChartSkeleton />
          ) : (
            TypeOfRequestChartData?.map((item, index) => {
              return (
                <Grid item md={6} sm={12} key={index}>
                  <Paper
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "430px",
                      width: "100%",
                    }}
                  >
                    <LineChart
                      options={options(item?.label, item?.data?.labal)}
                      series={series(item?.data)}
                      height={380}
                    />
                  </Paper>
                </Grid>
              );
            })
          )}
        </Grid>
      )}
    </>
  );
};

export default TypeOfRequest;
