import {
  Alert,
  Grid,
  Paper,
  Snackbar,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  hr03ReportClearResult,
  hr03ReportLoading,
  fetchHr03Report,
  hr03ReportFilter,
} from "../../../ducks/report";
import _ from "lodash";
import Hr03ReportSearchForm from "./Hr03ReportSearchForm";
import { Form } from "react-final-form";
import { getChip } from "../../../data/hr03Status";
import { format, isValid, parseISO } from "date-fns";
import AnalyticsCase from "./cards/statistics/AnalyticsCase";

export default function RequestReport() {
  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);
  const forceReloadRef = React.useRef(0);

  useEffect(() => {
    return () => {
      dispatch(hr03ReportClearResult());
      dispatch(hr03ReportFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state?.report?.hr03_report?.rows);
  // const total = useSelector((state) => state?.report?.metaHr03Report?.total);
  // const loading = useSelector((state) => state.report.metaHr03Report.loading);
  // const filter = useSelector((state) => state.report.metaHr03Report.filter);

  // const statusBH = rows?.filter((v) => v.status === "sent_to_bh")?.length || 0;
  // const statusBHReject = rows?.filter((v) => v.status === "bh_reject")?.length || 0;
  // const statusBHApprove = rows?.filter((v) => v.status === "bh_approve")?.length || 0;
  // const statusAML = rows?.filter((v) => v.status === "sent_to_aml")?.length || 0;
  // const statusHR03 = rows?.filter((v) => v.status === "hr03")?.length || 0;

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    dispatch(hr03ReportLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(
      fetchHr03Report(
        rowsState.pageSize,
        rowsState.page,
        // filter,
        fetchId,
        fetchIdRef
      )
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(hr03ReportClearResult());
      dispatch(hr03ReportLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // filter,
    rowsState.page,
    rowsState.pageSize,
    forceReloadRef.current,
  ]);

  const onSearchFormSubmit = (data) => {
    dispatch(hr03ReportFilter(data));
  };

  const columns = [
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
            {params.row.status === "new" && (
              <Chip
                label="กรุณายืนยัน"
                color="warning"
                size="small"
                กรุณายืนยัน
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "hr_type",
      headerName: "ประเภท",
      sortable: true,
      width: 80,
      valueFormatter: ({ value }) => {
        return value === 1 ? "HR03-1" : "HR03-2";
      },
    },
    {
      field: "tpo_case_id",
      headerName: "caseId",
      sortable: true,
      width: 100,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-สกุล",
      sortable: true,
      minWidth: 120,
      maxWidth: 250,
      valueGetter: ({ row }) => {
        return `${row.name} ${row.surname}`;
        // return params.row.bank?.short_name;
      },
    },
    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: true,
      width: 80,
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: true,
      minWidth: 150,
      maxWidth: 180,
    },
    {
      field: "pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
    },
    {
      field: "pol_policestation",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        return value.ORG_ABBR;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">รายงานข้อมูล HR03</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            onSubmit={onSearchFormSubmit}
            component={Hr03ReportSearchForm}
            // loading={loading}
            onReset={() => dispatch(hr03ReportFilter({}))}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ทั้งหมด"
          // count={total}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="บก.พิจารณา"
          // count={statusBH}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="บก.ปฏิเสธ"
          // count={statusBHReject}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ตร.พิจารณา"
          // count={statusBHApprove}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ปปง.พิจารณา"
          // count={statusAML}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="HR03"
          // count={statusHR03}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 700,
            width: "100%",
          }}
        >
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={rows}
            // rowCount={total}
            // loading={loading}
            // rowsPerPageOptions={[10, 20]}
            pagination
            // {...rowsState}
            paginationMode="server"
            // onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            // onPageSizeChange={(pageSize) =>
            //   setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
            // }
          />
        </Paper>
      </Grid>
    </>
  );
}
