import * as React from "react";
import { Form } from "react-final-form";
import api from "../../../../apis";
import Step10List from "./Step10List";
import Step1List from "./Step1List";
import Step2List from "./Step2List";
import Step3List from "./Step3List";
import Step4List from "./Step4List";
import Step5List from "./Step5List";
import Step6List from "./Step6List";
import Step7List from "./Step7List";
import Step8List from "./Step8List";
import Step9List from "./Step9List";

import { TabContext, TabList } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Modal,
  Paper,
  Snackbar,
  Tab,
  Typography
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { getUnixTime } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../../ducks/hr03List";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import Hr03MeetingModal from "./Hr03MeetingModal";
import Hr03MeetingSearchForm from "./Hr03MeetingSearchForm";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      // "-apple-system",
      // "BlinkMacSystemFont",
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#28B463",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(15),
      backgroundColor: "#28B463",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MeetingForm() {
  const dispatch = useDispatch();
  const onSearchSubmit = (data) => {
    dispatch(setFilter(data));
  };
  const qState = useSelector((state) => state.auth.user.permissions);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [btnConfirm, setBtnConfirm] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [reload, setReload] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const [toastInfo, SetToastInfo] = React.useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);

  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">คัดกรองรายการ HR03</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >

          {qState?.HR03_filter?.includes("search") && (
             <Form
             onSubmit={onSearchSubmit}
             component={Hr03MeetingSearchForm}
             setBtnConfirm={setBtnConfirm}
           />
           )}
         

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                sx={{ borderRight: 1, borderColor: "divider", mt: 2, ml: 2 }}
              >
                <AntTab
                  label="STEP 1"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                  }}
                  {...a11yProps(0)}
                />
                <AntTab
                  label="STEP 2"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(1)}
                />
                <AntTab
                  label="STEP 3"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(2)}
                />
                <AntTab
                  label="STEP 4"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(3)}
                />
                <AntTab
                  label="STEP 5"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(4)}
                />
                <AntTab
                  label="STEP 6"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(5)}
                />
                <AntTab
                  label="STEP 7"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(6)}
                />
                <AntTab
                  label="STEP 8"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(7)}
                />
                <AntTab
                  label="STEP 9"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(8)}
                />
                <AntTab
                  label="STEP 10"
                  sx={{
                    borderRadius: 1.5,
                    border: 1,
                    mb: 1,
                    borderColor: "#B6B6B6",
                  }}
                  {...a11yProps(9)}
                />
              </TabList>
            </Box>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Step1List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Step2List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Step3List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <Step4List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <Step5List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              <Step6List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={6} dir={theme.direction}>
              <Step7List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={7} dir={theme.direction}>
              <Step8List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={8} dir={theme.direction}>
              <Step9List reload={reload} />
            </TabPanel>
            <TabPanel value={value} index={9} dir={theme.direction}>
              <Step10List reload={reload} />
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>

      <ConfirmDialog
        open={btnConfirm}
        onClose={() => setBtnConfirm(false)}
        cfBtnProps={{ label: "ยืนยัน", color: "primary" }}
        onConfirm={async () => {
          await api
            .post(`/api/mgt/hr_reports/rtp_process/step7`)
            .then((e) => {
              setReload(getUnixTime(new Date()));
              handleOpenModal(
                <Hr03MeetingModal
                  open={true}
                  handleClose={handleCloseModal}
                  item={e?.data}
                  title="ผลลัพธ์ของการเคลียร์รายการคงค้าง"
                  step={"clear"}
                />
              );
            })
            .catch(() => {
              setIsError(true);
            });
        }}
        title="ยืนยันการทำรายการ"
      >
        ยืนยันการเคลียร์ข้อมูลรายการคงค้าง
      </ConfirmDialog>

      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>

      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            พบข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
