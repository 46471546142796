import api from "../apis";
// Actions
const PERSONAL_FETCH = "personalList/PERSONAL_FETCH";
const PERSONAL_CLEAR_RESULT = "personalList/PERSONAL_CLEAR_RESULT";
const PERSONAL_MARK_LOADING = "personalList/PERSONAL_MARK_LOADING";
const PERSONAL_SET_FILTER = "personalList/PERSONAL_SET_FILTER";
const PERSONAL_FORCE_RELOAD = "personalList/PERSONAL_FORCE_RELOAD";

const TELEPHONE_FETCH = "personalList/TELEPHONE_FETCH";
const TELEPHONE_CLEAR_RESULT = "personalList/TELEPHONE_CLEAR_RESULT";
const TELEPHONE_MARK_LOADING = "personalList/TELEPHONE_MARK_LOADING";
const TELEPHONE_SET_FILTER = "personalList/TELEPHONE_SET_FILTER";
const TELEPHONE_FORCE_RELOAD = "personalList/TELEPHONE_FORCE_RELOAD";

const defaultState = {
  personal: {},
  metaPersonal: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
  telephone: {},
  metaTelephone: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case PERSONAL_FETCH:
      return {
        ...state,
        personal: action.payload.rows,
        metaPersonal: {
          ...state.metaPersonal,
          loading: false,
          total: action.payload.total,
          requestColumns: action.payload.request_column,
        },
      };
    case PERSONAL_CLEAR_RESULT:
      return {
        ...state,
        personal: {},
        metaPersonal: {
          ...state.metaPersonal,
          loading: false,
          total: 0,
          requestColumns: [],
        },
      };
    case PERSONAL_MARK_LOADING:
      return {
        ...state,
        metaPersonal: { ...state.metaPersonal, loading: action.payload },
      };
    case PERSONAL_SET_FILTER:
      return {
        ...state,
        metaPersonal: {
          ...state.metaPersonal,
          filter: action.payload,
        },
      };
    case PERSONAL_FORCE_RELOAD:
      return {
        ...state,
        metaPersonal: {
          ...state.metaPersonal,
          forceReload: ++state.metaPersonal.forceReload,
        },
      };

    case TELEPHONE_FETCH:
      return {
        ...state,
        telephone: action.payload.rows,
        metaTelephone: {
          ...state.metaTelephone,
          loading: false,
          total: action.payload.total,
          requestColumns: action.payload.request_column,
        },
      };
    case TELEPHONE_CLEAR_RESULT:
      return {
        ...state,
        telephone: {},
        metaTelephone: { ...state.metaTelephone, loading: false, total: 0 },
      };
    case TELEPHONE_MARK_LOADING:
      return {
        ...state,
        metaTelephone: { ...state.metaTelephone, loading: action.payload },
      };
    case TELEPHONE_SET_FILTER:
      return {
        ...state,
        metaTelephone: {
          ...state.metaTelephone,
          filter: action.payload,
        },
      };
    case TELEPHONE_FORCE_RELOAD:
      return {
        ...state,
        metaTelephone: {
          ...state.metaTelephone,
          forceReload: ++state.metaTelephone.forceReload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const personalFetch =
  (pageSize, pageIndex, filter = {}, fetchId, fetchIdRef, caseId) =>
  async (dispatch) => {
    const response = await api.get(`/api/cases/${caseId}/persons`, {
      params: {
        page_size: pageSize,
        page: pageIndex,
        ...filter,
      },
    });

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      dispatch({
        type: PERSONAL_FETCH,
        payload: { ...response.data, pageSize, pageIndex },
      });
    }
  };

export const personalMarkLoading = (isLoading = true) => {
  return { type: PERSONAL_MARK_LOADING, payload: isLoading };
};

export const personalClearResult = () => {
  return { type: PERSONAL_CLEAR_RESULT };
};

export const personalSetFilter = (filterValue) => {
  return { type: PERSONAL_SET_FILTER, payload: filterValue };
};

export const personalForceReload = () => {
  return { type: PERSONAL_FORCE_RELOAD };
};

export const telephoneFetch =
  (pageSize, pageIndex, filter = {}, fetchId, fetchIdRef, caseId) =>
  async (dispatch) => {
    pageSize = pageSize > 5 ? pageSize : 5;
    pageIndex = pageIndex > 0 ? pageIndex : 1;
    const response = await api.get(`/api/cases/${caseId}/telephones`, {
      params: {
        page_size: pageSize,
        page: pageIndex,
        ...filter,
      },
    });
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      dispatch({
        type: TELEPHONE_FETCH,
        payload: { ...response.data, pageSize, pageIndex },
      });
    }
  };

export const telephoneMarkLoading = (isLoading = true) => {
  return { type: TELEPHONE_MARK_LOADING, payload: isLoading };
};

export const telephoneClearResult = () => {
  return { type: TELEPHONE_CLEAR_RESULT };
};

export const telephoneSetFilter = (filterValue) => {
  return { type: TELEPHONE_SET_FILTER, payload: filterValue };
};

export const telephoneForceReload = () => {
  return { type: TELEPHONE_FORCE_RELOAD };
};
