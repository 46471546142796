import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import { FileOpen } from "@mui/icons-material";

const FieldComponent = ({
  name,
  required = false,
  readOnly = false,
  resizeImage,
  shouldResizeImage = () => true,
  dropZoneProps = {},
  showFileSize = true,
  placeholder,
  accept = "image/jpeg, image/png,application/pdf,application/zip",
  input,
  meta,
  disabled = false,
}) => {
  // const [loading, setLoading] = useState(false);

  const onDrop = (dropFiles) => {
    // console.log(dropFiles);
    input.onBlur();
    if (!dropFiles[0]) {
      return;
    }

    input.onChange(dropFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept,
    multiple: true,
    ...dropZoneProps,
    disabled: disabled,
  });

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography sx={{ mb: 2 }} variant="h6" component="div">
            Template file *
          </Typography>

          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div
              className="text-wrap"
              style={{ width: "100%", textAlign: "center", height: 150 }}
            >
              {placeholder || (
                <>
                  Drag 'n' drop some files here, or click to select files <br />
                  (*.jpg,*.gif,*.png,*pdf,*zip) only
                </>
              )}
            </div>
          </div>
          {meta.invalid && meta.touched && (
            <Typography variant="caption" color="error.main">
              {meta.error || meta.submitError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography sx={{ mb: 2 }} variant="h6" component="div">
            Files ({input.value?.length})
          </Typography>
          <List
            dense={true}
            sx={{
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {input.value &&
              input.value?.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FileOpen />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      item?.size
                        ? `${(item?.size / 1048576).toFixed(2)} MB`
                        : null
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default FieldComponent;
