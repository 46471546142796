import { Save } from "@mui/icons-material";
import { Box, Button, Divider, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditRequestModal = ({ no, databyNo, setDatabyNo, setNo, onSaveData }) => {
  const qState = useSelector((state) => state.auth.user.permissions);

  const { register, setValue, handleSubmit } = useForm();
  const onSubmit = (data) => {
    const update = {};
    Object.keys(databyNo.object_type).forEach(
      (k) => (update[k] = { ...databyNo.object_type[k], value: data[k] })
    );
    const body = {
      object_type: update,
      status: _.get(data, "status"),
    };
    onSaveData(body, no);
  };

  const { bank, person, telco, BankcaseID, ipAddress } = databyNo.object_type;

  useEffect(() => {
    if (databyNo) {
      Object.entries(databyNo.object_type).map(
        (el) => el[1].value !== undefined && setValue(el[0], el[1].value)
      );
      setValue("status", databyNo.status);
    }
  }, [databyNo, setValue]);

  return (
    <Box sx={style}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <h2 className="font-medium">แก้ไขข้อมูลหมายคำขอ (ID : {no})</h2>
            <p className="text-sm">
              Master Data สำหรับกำหนดข้อมูลหมายคำขอ แต่ละหมาย
            </p>
          </Grid>
          <div className="mt-5">
            <TextField
              defaultValue={_.get(databyNo, "label", "")}
              disabled
              sx={{ width: "100%" }}
              label="หมายคำขอ*"
              variant="outlined"
              type="text"
            />
          </div>

          <Divider sx={{ mt: 2 }} />

          <p className="text-sm mt-2">เลือก Object (อย่างน้อย 1 ตัวเลือก) </p>
          <div className="mt-5 flex justify-start items-center gap-3">
            {bank?.value !== undefined && (
              <div className="flex items-center">
                <input
                  {...register("bank")}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-sm">บัญชี</label>
              </div>
            )}
            {person?.value !== undefined && (
              <div className="flex items-center">
                <input
                  {...register("person")}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-sm">บุคคล</label>
              </div>
            )}
            {telco?.value !== undefined && (
              <div className="flex items-center">
                <input
                  {...register("telco")}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-sm">โทรศัพท์</label>
              </div>
            )}
            {BankcaseID?.value !== undefined && (
              <div className="flex items-center">
                <input
                  {...register("BankcaseID")}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-sm">BankcaseID</label>
              </div>
            )}
            {ipAddress?.value !== undefined && (
              <div className="flex items-center">
                <input
                  {...register("ipAddress")}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label className="ms-2 text-sm">โทรศัพท์</label>
              </div>
            )}
          </div>
          <div className="flex justify-start items-center gap-3 mt-5">
            <label className="text-sm">สถานะใช้งาน</label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                {...register("status")}
                type="checkbox"
                value=""
                className="sr-only peer"
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>

          <Divider sx={{ mt: 5 }} />
          {!qState?.setting_request?.includes("edit") && (
            <p className="text-red-400 my-2 font-normal">คุณไม่มีสิทธิ์แก้ไข</p>
          )}

          <div className="mt-5 flex justify-start items-center gap-5">
            <Button
              type="submit"
              variant="contained"
              disabled={!qState?.setting_request?.includes("edit")}
            >
              <Save fontSize="18px" />
              <span className="ml-1">ตกลง</span>
            </Button>
            <Button
              onClick={() => {
                setNo(null);
                setDatabyNo(null);
              }}
              type="button"
              variant="outlined"
            >
              ยกเลิก
            </Button>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default EditRequestModal;
