import { Construction } from "@mui/icons-material";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function MaPage() {
  const msg = useSelector((state) => state.app.maintenance.message);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Construction sx={{ m: 1, fontSize: 60 }} />
        <Typography component="h1" variant="h4" gutterBottom>
          We'll be back
        </Typography>
        <Typography component="h1" variant="h6" gutterBottom>
          ปิดปรับปรุงระบบชั่วคราว
        </Typography>
        {msg && (
          <Typography component="h1" variant="subtitle1" gutterBottom>
            {msg}
          </Typography>
        )}
      </Box>
    </Container>
  );
}
