import React from "react";
import { Grid, Typography, Stack, Paper, Box } from "@mui/material";
import FieldDatePicker from "../../../components/form-fields/FieldDatePicker";
import { Form } from "react-final-form";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import SubmitBtn from "../../SubmitBtn";
import { format, startOfToday } from "date-fns";
import RequestInformation from "./cards/RequestInformation";
import SystemloginStatistics from "./cards/SystemloginStatistics";
import ExternallyProvided1 from "./cards/ExternallyProvided1";
import ExternallyProvided2 from "./cards/ExternallyProvided2";
import ProvidedInternally1 from "./cards/ProvidedInternally1";
import ProvidedInternally2 from "./cards/ProvidedInternally2";
import TypeOfRequest from "./cards/TypeOfRequest";
import { he } from "date-fns/locale";

export default function Dashboard() {
  const [date, setDate] = React.useState(format(startOfToday(), "yyyy-MM-dd"));
  const [type, setType] = React.useState("h");
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Dashboard (แดชบอร์ด)</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Form
            onSubmit={(values) => {
              console.log(values);
              setDate(values.date);
              setType(values.type);
            }}
            initialValues={{
              date: format(startOfToday(), "yyyy-MM-dd"), // Set initial date here
              type: "h",
            }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <FieldDatePicker
                    name="date"
                    label=""
                    pickerProps={{
                      inputFormat: "dd/MM/yyyy",
                      disableFuture: true,
                      openTo: "year",
                      views: ["year", "month", "day"],
                      allowSameDateSelection: true,
                    }}
                    sx={{
                      height: 45,
                      width: "60%",
                      margin: "10px 0",
                      "& .MuiOutlinedInput-root": {
                        height: "100%",
                        color: "#696969",
                        // background: "white",
                      },
                      "& .MuiFormLabel-root": {
                        marginTop: "-10px",
                        color: "#696969",
                      },
                      "& .MuiInputBase-input": {
                        marginTop: "5px",
                        color: "#696969",
                        // background: "white",
                      },
                    }}
                  />
                  <FieldAutoComplete
                    name="type"
                    label=""
                    options={[
                      { value: "h", label: "นับข้อมูลเเบบรายชั่วโมง" },
                      { value: "d", label: "นับข้อมูลเเบบรายวัน" },
                      { value: "w", label: "นับข้อมูลเเบบรายสัปดาห์" },
                      { value: "m", label: "นับข้อมูลเเบบรายเดือน" },
                    ]}
                    controlProp={{
                      sx: {
                        width: "70%",

                        "& .MuiOutlinedInput-root": {
                          height: 45,
                          marginTop: "10px",
                          // background: "white",
                          padding: "6px 4px 7.5px 6px",
                          color:"#696969"
                        },
                        "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                          {
                            padding: "6px 4px 7.5px 6px",
                            color:"#696969"
                          },
                      },
                    }}
                  />
                  <SubmitBtn
                    variant="contained"
                    color="success"
                    submitting={submitting}
                    sx={{ height: 45 }}
                    style={{ marginTop: "10px" }}
                  >
                    Search
                  </SubmitBtn>
                </Stack>
              </form>
            )}
          />
        </Grid>
      </Grid>
      <Box
      sx={{
        backgroundColor: "white",
        padding: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
      >
        <Grid container spacing={2}>
          <Grid item md={6}>
            <RequestInformation date={date} type={type} />
          </Grid>
          <Grid item md={6}>
            <SystemloginStatistics date={date} type={type} />
          </Grid>
          <Grid item md={12}>
            <TypeOfRequest date={date} type={type} />
          </Grid>
          <Grid item md={6}>
            <ExternallyProvided1 date={date} type={type} />
          </Grid>
          <Grid item md={6}>
            <ExternallyProvided2 date={date} type={type} />
          </Grid>
          <Grid item md={6}>
            <ProvidedInternally1 date={date} type={type} />
          </Grid>
          <Grid item md={6}>
            <ProvidedInternally2 date={date} type={type} />
          </Grid>
        </Grid>
      </Box>

  
    </>
  );
}
