import { Divider, Grid, InputAdornment } from "@mui/material";
import React from "react";
import FieldInput from "../../../../form-fields/FieldInput";
import FieldDatePicker from "../../../../form-fields/FieldDatePicker";
import FieldAsyncAutoComplete from "../../../../form-fields/FieldAsyncAutoComplete";
import ReuseSignatureB from "./ReuseSignatureB";

export default function LetterFormB({ readOnly, hiddenUserForm = false }) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <FieldInput
          name="noB"
          label="เลขหนังสือ"
          required
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">ตช.</InputAdornment>
            ),
            placeholder: "ตัวอย่างเช่น 0039.12/123",
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldDatePicker
          name="dateB"
          label="ลงวันที่"
          required
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
          readOnly={readOnly}
        />
      </Grid>

      {!hiddenUserForm && (
        <>
          <Grid item xs={12}>
            <Divider textAlign="center">พงส.</Divider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FieldInput
              name="rank"
              label="ยศ"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              inputProps={{ placeholder: "คำเต็ม เช่น พันตำรวจโท" }}
              required
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <FieldInput
              name="fname"
              label="ชื่อ"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              required
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <FieldInput
              name="lname"
              label="สกุล"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FieldInput
              name="job_title"
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FieldAsyncAutoComplete
              name="policestation_org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={readOnly}
              basePath="/api/policestations"
              // itemFilter={(row) =>
              //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
              // }
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FieldInput
              name="email"
              label="email"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FieldInput
              name="tel"
              label="โทรศัพท์"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldInput
              name="address"
              label="ที่อยู่"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ReuseSignatureB name="signature" readOnly={readOnly} />
          </Grid>
        </>
      )}
    </>
  );
}
