import { IMaskInput } from "react-imask";
import React from "react";

export const getTextMask = (config) =>
  React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        {...config}
        // mask="(#00) 000-0000"
        // definitions={{
        //   "#": /[1-9]/,
        // }}
        // unmask
        // overwrite
      />
    );
  });
