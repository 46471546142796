import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Divider,
  Grid,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import FieldSelect from "../../../form-fields/FieldSelect";
import letterTypes from "../../../../data/selects/letterTypes";
import UrgentRequest from "./UrgentRequest";
import FieldInput from "../../../form-fields/FieldInput";
import { ContentCopy } from "@mui/icons-material";
import AccountRequest from "./AccountRequest";
import StatementRequest from "./StatementRequest";
import AmountRequest from "./AmountRequest";
import { thousands_separators } from "../../../../helpers/stringFormater";
import AtmRequest from "./AtmRequest";
import CctvRequest from "./CctvRequest";
import ConfirmCfrRequest from "./ConfirmCfrRequest";
import RegistrationRequest from "../../requests-telco/request-forms/RegistrationRequest";
import { validateLetterRules } from "./sub-forms/LetterForm";
import CdrRequest from "../../requests-telco/request-forms/CdrRequest";

export default function RequestFormReadOnly({
  readOnly = true, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [copied, SetCopied] = useState(false);

  const copyData = (copyValue) => {
    SetCopied(true);
    navigator.clipboard.writeText(copyValue);
  };

  useEffect(() => {
    let active = true;

    if (!copied) {
      return undefined;
    }

    setTimeout(() => {
      active && SetCopied(false);
    }, 5000);

    return () => {
      active = false;
    };
  }, [copied]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <FieldSelect
              name="request_type_id"
              label="ประเภทคำขอ"
              options={letterTypes}
              readOnly={readOnly}
              required
              controlProp={{ sx: { width: "50%" } }}
            />
            {![8].includes(values.request_type_id) && (
              <FieldInput
                name="bank.name"
                label="ธนาคาร"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center"></Divider>
        </Grid>
        {values.request_type_id &&
          renderRequest(
            readOnly,
            values.request_type_id,
            values.show_bank_accounts,
            values
          )}

        {values.description && (
          <>
            <Grid item xs={12}>
              <Divider textAlign="center">รายละเอียด</Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                style={{ whiteSpace: "pre-line" }}
              >
                {values.description}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item md={12}>
          <Stack direction="row" spacing={2}>
            {values.description && (
              <Button
                variant="contained"
                color="success"
                size="large"
                startIcon={<ContentCopy />}
                onClick={() => copyData(values.description)}
                disabled={copied}
              >
                {copied ? "Copied" : "Copy"}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

const renderRequest = (readOnly, request_type_id, bankAccounts, values) => {
  switch (request_type_id) {
    case 1:
      return <UrgentRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 2:
      return <AccountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 3:
      return (
        <StatementRequest readOnly={readOnly} bankAccounts={bankAccounts} />
      );
    case 4:
      return (
        <AccountRequest
          readOnly={readOnly}
          values={values}
          bankAccounts={bankAccounts}
        />
      );
    case 5:
      return <AmountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 6:
      return <AtmRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 7:
      return <CctvRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 8:
      return <ConfirmCfrRequest readOnly={readOnly} />;
    case 9:
      return <RegistrationRequest readOnly={readOnly} />;
    case 10:
      return <CdrRequest readOnly={readOnly} />;

    default:
      return (
        <Grid item xs={12}>
          not support yet
        </Grid>
      );
  }
};

export const mapToInit = (item) => {
  let initValues = {};
  initValues.request_type_id = item.request_type_id;
  initValues.description = item.description;
  initValues.bank = {
    name: `${item.bank?.name} (${item.bank?.short_name})`,
    short_name: item.bank.short_name,
  };
  switch (item.request_type_id) {
    case 1:
      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank_account.bank = { short_name: item.bank?.short_name };
          return acc.bank_account;
        }
      );
      break;
    case 2:
      initValues.noA = item.detail.no;
      initValues.dateA = item.detail.date;

      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank_account.bank = { short_name: item.bank?.short_name };
          return acc.bank_account;
        }
      );
      initValues = {
        ...initValues,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 3:
      initValues.noB = item.detail.no;
      initValues.dateB = item.detail.date;
      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank_account.bank = { short_name: item.bank?.short_name };
          acc.bank_account.from_date = acc.from_date;
          acc.bank_account.to_date = acc.to_date;
          return acc.bank_account;
        }
      );
      initValues = {
        ...initValues,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 4:
      initValues.noA = item.detail.no;
      initValues.dateA = item.detail.date;

      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank_account.bank = { short_name: item.bank?.short_name };
          return acc.bank_account;
        }
      );
      initValues = {
        ...initValues,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 5:
      initValues.noQ = item.detail.no;
      initValues.dateQ = item.detail.date;
      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank_account.bank = { short_name: item.bank?.short_name };
          acc.bank_account.amount = acc.amount;
          return acc.bank_account;
        }
      );
      initValues = {
        ...initValues,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 6:
      initValues.noD = item.detail.no;
      initValues.dateD = item.detail.date;
      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank = item.bank;
          acc.account = acc.bank_account;
          return acc;
        }
      );
      initValues = {
        ...initValues,
        bank_id: item.bank_id,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 7:
      initValues.noE = item.detail.no;
      initValues.dateE = item.detail.date;
      initValues.show_bank_accounts = item.detail?.detail_bank_accounts?.map(
        (acc) => {
          acc.bank = item.bank;
          acc.account = acc.bank_account;
          return acc;
        }
      );
      initValues = {
        ...initValues,
        bank_id: item.bank_id,
        ..._.pick(item.detail, _.keys(validateLetterRules())),
      };
      break;
    case 8:
      initValues.noH = item.detail.no;
      initValues.dateH = item.detail.date;
      initValues = {
        ...initValues,
        bank_id: item.bank_id,
        ..._.pick(item.detail, [
          ..._.keys(validateLetterRules()),
          "bank_case_id",
          "victim_card_id",
          "nationality",
        ]),
      };
      initValues.is_foreigner = initValues.nationality !== "TH";
      break;
    case 9:
      initValues = {
        ...initValues,
        bank_id: item.bank_id,
        ..._.pick(item.detail, [
          ..._.keys(validateLetterRules()),
          "phone_number",
        ]),
      };
      break;
    case 10:
      initValues = {
        ...initValues,
        bank_id: item.bank_id,
        ..._.pick(item.detail, [
          ..._.keys(validateLetterRules()),
          "phone_number",
          "from_date",
          "to_date",
        ]),
      };
      break;

    default:
      return item;
  }

  // console.log('initValues', initValues)
  return initValues;
};

const getBankJobTitle = (bank_short_name) => {
  switch (bank_short_name) {
    case "LHFG":
      return "ประธานเจ้าหน้าที่บริหาร และกรรมการผู้จัดการ";
    case "TISCO":
      return "กรรมการผู้จัดการใหญ่";
    case "GSB":
      return "ผู้อำนวยการ";
    case "GHB":
      return "กรรมการผู้จัดการ";
    case "BAAC":
      return "ผู้จัดการธนาคาร";
    case "EXIM":
      return "กรรมการผู้จัดการ";
    case "TCD":
      return "กรรมการผู้จัดการ";
    default:
      return "กรรมการผู้จัดการ";
  }
};

export const getEmailBody = (item) => {
  const { detail } = item;
  let message = `เรียน ${getBankJobTitle(item.bank?.short_name)}${
    item.bank?.name
  }\n
ด้วย  ${detail?.rank} ${detail?.fname} ${detail?.lname} ตำแหน่ง ${
    detail?.job_title
  } ${detail?.policestation?.ORG} 
อีเมล์ ${detail?.email}
เบอร์โทรศัพท์ ${detail?.tel}
ได้ส่งหนังสือเลข ตช.${detail?.no}
ลงวันที่ ${detail?.date}\n\n`;

  let msg = "";
  switch (item.request_type_id) {
    case 2:
      msg += `ประเภทหมาย หมายขอข้อมูลที่ใช้เปิดบัญชี A\nหมายเลขบัญชีที่ขอ\n\n${detail?.detail_bank_accounts
        ?.map(
          (bank) =>
            `เลขบัญชี ${bank.bank_account.number}${
              bank.bank_account.name ? " ชื่อ " + bank.bank_account.name : ""
            }`
        )
        .join("\n")}\n\n`;
      msg += `ขอข้อมูลหนังสือรับรองการเปิดบัญชี (ไฟล์ PDF ขนาด A4) ประกอบไปด้วย ข้อมูลที่ใช้ประกอบการเปิดบัญชี , หมายเลขโทรศัพท์ที่สมัครใช้บริการทำธุรกรรมผ่านช่องทางอิเล็กทรอนิกส์ พร้อมรับรองเอกสาร และข้อมูลตาม template01`;
      message += msg;
      break;
    case 3:
      msg += `หมายขอข้อมูลรายการเดินบัญชี B\nหมายเลขบัญชีที่ขอ\n\n${detail?.detail_bank_accounts
        ?.map(
          (bank) =>
            `เลขบัญชี ${bank.bank_account.number}${
              bank.bank_account.name ? " ชื่อ " + bank.bank_account.name : ""
            }`
        )
        .join("\n")}\n\n`;
      msg += `ขอข้อมูลรายการเดินบัญชีแบบอิเล็กทรอนิกส์แบบละเอียด เห็นชื่อบัญชีและเลขที่บัญชีแบบเต็มทุกรายการ ทั้งการโอนเงินเข้า -ออก หรือ ข้อมูลตามขอข้อมูลตาม template03 (ในรูปแบบไฟล์ excel)`;
      message += msg;
      break;
    case 4:
      msg += `หมายอายัดทั้งบัญชี R\nหมายเลขบัญชีที่ขอ\n\n${detail?.detail_bank_accounts
        ?.map(
          (bank) =>
            `เลขบัญชี ${bank.bank_account.number}${
              bank.bank_account.name ? " ชื่อ " + bank.bank_account.name : ""
            }`
        )
        .join("\n")}\n\n`;
      msg += `- อายัดบัญชีระงับการทำธุรกรรมขาออก (ห้ามถอน เบิก โอน จ่าย ตัดเงิน) ตามรายละเอียดข้างต้น ในทันทีที่ได้รับ หมายนี้\n`;
      msg +=
        "- อายัดช่องทางทำธุรกรรมผ่านอินเทอร์เน็ตทุกช่องทาง (Mobile banking , Internet banking)";
      message += msg;
      break;
    case 5:
      msg += `หมายอายัดเฉพาะยอดเงินที่ระบุ Q\nหมายเลขบัญชีที่ขอ\n\n${detail?.detail_bank_accounts
        ?.map(
          (bank) =>
            `เลขบัญชี ${bank.bank_account.number}${
              bank.bank_account.name ? " ชื่อ " + bank.bank_account.name : ""
            } จำนวน ${thousands_separators(bank.amount)} บาท`
        )
        .join("\n")}\n\n`;
      msg += `อายัดยอดเงินรวมกรณีมีเงินในบัญชี ให้อายัดเฉพาะยอดเงินตามรายละเอียดข้างต้น / หากยอดเงินไม่พออายัด ให้อายัดการทำ ธุรกรรมขาออก (ห้ามถอน เบิก โอน จ่าย ตัดเงิน)`;
      message += msg;
      break;
    default:
      return null;
  }

  message +=
    "\n\nศูนย์ปราบปรามอาชญากรรมทางเทคโนโลยีสารสนเทศ สำนักงานตำรวจแห่งชาติ (ศปอส.ตร.)\nPCT ฝ่ายประสารงานธนาคาร";

  return message;
};
