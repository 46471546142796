import _ from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOTP } from "../../ducks/verifyOTP";

export default function Google2FAPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = React.useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [isShowQr, setIsShowQr] = useState(false);
  const [google2faQr, setGoogle2faQr] = useState(<></>);

  useEffect(() => {
    if (location) {
      setEmail(_.get(location, "state.user.email"));
      setToken(_.get(location, "state.token.token"));
      setIsShowQr(_.get(location, "state.token.is_show_qr"));
      setGoogle2faQr(_.get(location, "state.token.google2fa_qr"));
    }
  }, [location]);

  const onSubmit = () => {
    return dispatch(
      verifyOTP({
        email,
        token,
        otp,
        type: "google2fa",
      })
    ).then((data) => {
      if (data) {
        toast.success("ยืนยันตัวตนเสร็จสิ้น");
        navigate("/me");
      } else toast.error("รหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
    });
  };

  useEffect(() => {
    otp.length === 6 && onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const onCancel = () => navigate("/login");

  return (
    <div className="bg-[#F5F5F5] h-screen grid justify-center items-center ">
      {/* <CssBaseline /> */}
      <div className="bg-white rounded-[32px] py-8 px-16 relative mx-4 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] w-[480px]">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-16 p-1">
          <div className="h-24 w-24 rounded-full bg-[#E8FFEE] p-4">
            <img src="/images/two-factor-icon.svg" alt="" />
          </div>
        </div>
        <div>
          <h2 className="text-xl font-medium text-center">
            Two-factor Authentication
          </h2>
          <p className="mt-3 font-bold">
            Step 1 : Download an authenticator app
          </p>
          <p className="mt-3">
            Download and install any authenticator app(Eg. Google Authenticator)
            on your phone. If Authy is already installed, you can skip this
            step!
          </p>
          {isShowQr && (
            <>
              <p className="mt-3 font-bold">Step 2 : Scan the QR code</p>
              <p className="mt-3">
                Open the authenticatior app and scan the image below using your
                phone’s camera.
              </p>
              <div
                className="flex content-center justify-center my-5"
                dangerouslySetInnerHTML={{ __html: google2faQr }}
              />
            </>
          )}
          <p className="mt-3 font-bold">
            Step {isShowQr ? 3 : 2} : Verify your code
          </p>
          <p className="mt-3">
            Enter the 6-digit verification code generated by the app
          </p>
        </div>
        <div className="mt-7">
          <OtpInput
            placeholder="******"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={{
              justifyContent: "center",
            }}
            renderSeparator={
              <span
                style={{
                  // fontSize: "7px",
                  marginLeft: "6px",
                  marginRight: "6px",
                }}
              ></span>
            }
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "50px",
              marginTop: "0px",
              marginBottom: "0px",
              height: "50px",
              // borderTop: "none",
              // borderLeft: "none",
              // borderRight: "none",
              // backgroundColor: "transparent",
              // outline: "none",
              borderRadius: "8px",
              fontSize: 30,
              fontFamily: "Kanit",
              borderWidth: "1px",
              borderColor: "#000",
              textAlign: "center",
            }}
          />
        </div>
        <div className="flex justify-around items-center gap-12 mt-7">
          <button
            onClick={onCancel}
            className="bg-white border border-[#0395FF] py-2 w-full rounded-md text-[#0395FF]"
          >
            Cancle
          </button>
          <button
            onClick={onSubmit}
            className=" border bg-[#0395FF] py-2 w-full rounded-md text-white"
          >
            Verify Code
          </button>
        </div>
      </div>
    </div>
  );
}
