// confirm bank-case-id which bank account owner request to freeze
import { Grid, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useFormState } from "react-final-form";
import { useQuery } from "react-query";
import api from "../../../../apis";
import countries from "../../../../data/selects/countries";
import { getTextMask } from "../../../../helpers/imask";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldCheckBox from "../../../form-fields/FieldCheckBox";
import FieldInput from "../../../form-fields/FieldInput";
import LetterFormH from "./sub-forms/LetterFormH";
import { useParams } from "react-router-dom";

const bankCaseIdMask = getTextMask({
  // mask: "0000aaa[aa]00000",
  mask: /^[A-Z0-9]+$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});

const passportMask = getTextMask({
  mask: /^[0-9A-Z]{1,30}$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});

const cardIdTextMask = getTextMask({
  mask: "0000000000000",
  unmask: true,
  overwrite: true,
});

export default function ConfirmCfrRequest({
  readOnly,
  hiddenUserForm = false,
}) {
  const form = useForm();
  const params = useParams();
  const caseId = parseInt(params.id);

  const { values, pristine } = useFormState();
  const { bank_case_id, is_foreigner: isForeigner, request_type_id } = values;

  useEffect(() => {
    if (readOnly || pristine) {
      return undefined;
    }

    form.batch(() => {
      form.change("victim_card_id", undefined);
      form.change("nationality", isForeigner ? undefined : "TH");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForeigner, readOnly]);

  const {
    isLoading,
    data: bankOptions,
    error,
  } = useQuery(
    [
      "select",
      "/api/banks",
      { value: "id", label: "fullname", short_name: "short_name" },
    ],
    () =>
      api.get("/api/banks", { params: { type: "bank" } }).then((res) =>
        res.data.rows.map((row) => ({
          value: row.id,
          label: `${row.name} (${row.short_name})`,
          short_name: row.short_name,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    if (!bankOptions) return undefined;

    let found = null;
    if (!bank_case_id || !(found = bank_case_id.match(/[A-Z]{3,5}/))) {
      form.change("bank_id", undefined);
      return undefined;
    }

    const bank = bankOptions.find((b) => b.short_name === found[0]);

    if (bank) form.change("bank_id", bank.value);
    else form.change("bank_id", undefined);

    return undefined;
  }, [bankOptions, bank_case_id, form]);

  useEffect(() => {
    if (!request_type_id === 8)
      api.get(`/api/cases/${caseId}`).then(({ data }) => {
        if (data.is_tpo > 0)
          api.get(`/api/cases/${caseId}/bank_accounts`).then(({ data }) => {
            const res = data.rows[0];
            form.change("bank_case_id", res.number + res.bank.short_name);
          });
      });
  }, [bank_case_id]);

  if (error)
    return (
      <Grid item xs={12} md={6}>
        เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
      </Grid>
    );

  const idMask = isForeigner ? passportMask : cardIdTextMask;

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="bank_case_id"
              label="Bank Case Id"
              required
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              inputProps={{
                placeholder: "6603XXX00001",
                inputComponent: bankCaseIdMask,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="bank_id"
              label="ธนาคาร"
              options={bankOptions}
              readOnly={true}
              controlProp={{
                fullWidth: true,
              }}
              required
              // disabled={loading}
              placeholder="โปรดกรอกข้อมูล Bank Case Id ให้ถูกต้อง"
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="victim_card_id"
              label={
                isForeigner ? "passport no. ผู้แจ้ง" : "เลขบัตรประชาชน ผู้แจ้ง"
              }
              required
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              inputProps={{
                placeholder: isForeigner ? "ABC1234" : "123456789xxxx",
                inputComponent: idMask,
                endAdornment: (
                  <FieldCheckBox
                    name="is_foreigner"
                    label={`ต่างด้าว${isForeigner ? "" : "?"}`}
                    readOnly={readOnly}
                  />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {isForeigner && (
              <FieldAutoComplete
                name="nationality"
                label="สัญชาติ"
                options={countries}
                readOnly={readOnly}
                controlProp={{
                  fullWidth: true,
                }}
                required
                // disabled={loading}
                placeholder="พิมเพื่อค้นหา"
                inputProps={{
                  disableClearable: true,
                }}
              />
            )}
          </Grid>
        </>
      )}
      <LetterFormH readOnly={readOnly} hiddenUserForm={hiddenUserForm} />
    </>
  );
}
