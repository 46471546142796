import { Divider, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: 350,
  width: 500,
};

function Hr03MeetingModal({
  open,
  handleClose,
  item,
  title,
  step,
  listLength,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.0)",
          },
          timeout: 500,
        }}
      >
        <Box sx={{ ...style }}>
          <h3 className=" font-medium text-lg text-center my-2">{title}</h3>
          <Divider />
          <Box
            sx={{
              height: 180,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {["clear"].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.data ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :{" "}
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[1].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :{" "}
                  <span className=" text-green-400">{item?.count_success ?? 0}</span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[2].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :{" "}
                  <span className=" text-green-400">{item?.count_success ?? 0}</span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ซ้ำ :{" "}
                  <span className=" text-red-400">
                    {item?.duplicate_count ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[3].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :{" "}
                  <span className=" text-green-400">{item?.count ?? 0}</span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ซ้ำ :{" "}
                  <span className=" text-red-400">{item?.dup_type1 ?? 0}</span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[4].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.count_rtp_reject ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[5].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.count_rtp_reject ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[6].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.count_rtp_reject ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[7].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.count_rtp_reject ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[8].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count_all ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.step3_berfore ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[9].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.seq_in_meeting ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :
                  <span className=" text-green-400">
                    {item?.count_success ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ Reject :{" "}
                  <span className=" text-red-400">
                    {item?.ref_code_error_count ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนที่ไม่สำเร็จ :{" "}
                  <span className=" text-red-400">
                    {item?.fail_count ?? 0}
                  </span>{" "}
                  รายการ
                </h3>
              </>
            )}

            {[10].includes(step) && (
              <>
                <h3 className=" text-lg font-medium">
                  จำนวนทั้งหมด : <span>{item?.count ?? 0}</span> รายการ
                </h3>
                <h3 className=" text-lg font-medium">
                  จำนวนสำเร็จ :{" "}
                  <span className=" text-green-400">{item?.count_success ?? 0}</span>{" "}
                  รายการ
                </h3>
              </>
            )}
          </Box>
          <Divider />
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Button onClick={handleClose} variant="contained">
              ตกลง
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default Hr03MeetingModal;
