import { Typography, styled } from "@mui/material";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import React from "react";
import { Field } from "react-final-form";

// hide select all checkbox
const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    {
      display: "none",
    },
}));

export default function FieldDataGridRadio({
  name,
  parse,
  format,
  readOnly = false,
  ...rest
}) {
  return (
    <Field name={name} parse={parse || undefined} format={format || undefined}>
      {({ input, meta }) => (
        <>
          {(meta.error ||
            (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
            meta.touched && (
              <Typography variant="caption" color="error.main">
                {meta.error || meta.submitError}
              </Typography>
            )}
          <DataGrid
            {...rest}
            checkboxSelection={!readOnly}
            onSelectionModelChange={(newSelectionModel) => {
              input.onChange(
                newSelectionModel.length
                  ? [newSelectionModel[newSelectionModel.length - 1]]
                  : []
              );
              input.onBlur();
            }}
            selectionModel={input.value || []}
            disableSelectionOnClick={readOnly}
          />
        </>
      )}
    </Field>
  );
}
