import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ options, series,height ="380px" }) => {
  return (
    <Chart
      options={options}
      series={series ?? []}
      type="line"
      height={height}
      style={{ width: "100%", paddingTop: "20px" ,paddingLeft:"10px" }}
    />
  );
};

export default LineChart;
