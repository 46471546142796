import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useMatch, useResolvedPath } from "react-router-dom";

export default function CollapseMenuItemBotton({
  children,
  to,
  exact,
  icon,
  text,
  defaultOpen = true,
  ...props
}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: exact ? true : false });

  const [open, setOpen] = useState(defaultOpen);
  return (
    <>
      <ListItemButton
        selected={match ? true : false}
        onClick={() => setOpen(!open)}
        {...props}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}
