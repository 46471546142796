import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Add, Search } from "@mui/icons-material";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";
import api from "../../../../apis";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import { useDispatch, useSelector } from "react-redux";


export default function UserSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  usersData
}) {
  const status = [
    { value: "true", label: "เปิดใช้งาน" },
    { value: "false", label: "ปิดใช้งาน" },
  ];

  const qState = useSelector((state) => state.auth.user.permissions);




  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };



 

  
  const { data: externaltype } = useQuery(
    ["externaltype"],
    () => 
      api.get(`/api/externaltype`).then((res) =>
        res.data.map((item) => {
          return {
            value: item.value,
            label: item.name,
          };
        })
      )
    // { staleTime: 60 * 1000 }
  );

  const { data: bank } = useQuery(
    ["bank"],
    () => 
      api.get(`/api/banks`).then((res) =>  res?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label:`${item.name}(${item.short_name}) `,
        };
      }))
    // { staleTime: 60 * 1000 }
  );

 

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.setting_external?.includes("search") && ( <Grid container sx={{ mb: 3 }} spacing={2}>
        {showStatusField && (
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={status}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} xl={4}>
        <FieldAutoComplete
              name="external_org_type"
              label="ประเภทหน่วยงาน"
              options={externaltype}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          
          {/* <FieldAsyncAutoComplete
            name="external_org_type"
            label="ประเภทหน่วยงาน"
            required
            
            controlProp={{ fullWidth: true }}
            basePath="/api/externaltype"
            itemFormat={(row) => {
              return {
                value: row,
                label: row.name,
              };
            }}
          /> */}
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
        <FieldAutoComplete
              name="external_org"
              label="หน่วยงานภายนอก"
              options={bank}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          {/* <FieldAsyncAutoComplete
            name="external_org"
            label="หน่วยงานภายนอก"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/banks"
            itemFormat={(row) => {
              return {
                value: {id: row.id, org_type: row.type},
                label: `${row.name}(${row.short_name})`,
              };
            }}
          /> */}
        </Grid>
      
        <Grid item xs={12} lg={8} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
      
        
        {/* <Grid item xs={12} md={6} lg={4} xl={4}>
          <FieldAutoComplete
            name="role"
            label="ตำเเหน่ง"
            options={roles || []}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            //   inputProps={{ sx: { fullWidth: true } }}
          />
        </Grid> */}
      </Grid>)
      }

      <Grid container spacing={2} mb={2}>
        {qState?.setting_external?.includes("search") &&  <Grid item md={12} lg={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
              
            >
              Reset
            </Button>
          </Stack>
        </Grid> }
        {qState?.setting_external?.includes("create") &&   <Grid item md={12} lg={ qState?.setting_external?.includes("search") ? 8 : 12}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
           
            <Button
              variant="contained"
              startIcon={<Add />}
              color="success"
              component={Link}
              to="/userExternal/add"
            >
              เพิ่ม
            </Button>
          </Stack>
        </Grid> }

      </Grid>
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
