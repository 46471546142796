import api from "../apis";
import _ from "lodash";
import { isAfter, isBefore, parseISO } from "date-fns";
// Actions
const FETCH = "sumReqList/FETCH";
const CLEAR_RESULT = "csReqList/CLEAR_RESULT";
const MARK_LOADING = "csReqList/MARK_LOADING";
const SET_FILTER = "csReqList/SET_FILTER";
const FORCE_RELOAD = "csReqList/FORCE_RELOAD";
const CHANGE_PAGE_SIZE = "csReqList/CHANGE_PAGE_SIZE";

// client-side paging

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    forceReloadRef: 0,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
        },
      };
    case CLEAR_RESULT:
      return {
        ...defaultState,
      };
    case MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
        },
      };
    case FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReloadRef: ++state.meta.forceReloadRef,
        },
      };
    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        meta: {
          ...state.meta,
          pageSize: action.payload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const fetch =
  (filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(markLoading(true));

    try {
      response = await api.get(`/api/pol_request/list_bank`, {
        params: {
          ...filter,
        },
      });

      const rows = _.reduce(
        response.data.rows,
        (bankSum, requestTypes, bankCode) => {
          const reqlist = _.map(requestTypes, (requests) => {
            return requests.reduce(
              (requestSum, req) => {
                requestSum.req_ids.push(req.id);
                requestSum.bank = req.bank;
                requestSum.bank_id = req.bank_id;
                requestSum.request_type = req.request_type;
                requestSum.request_type_id = req.request_type_id;
                requestSum.id = req.bank_id + ":" + req.request_type_id;

                requestSum.min_date ??= parseISO(req.created_at);
                const createdAt = parseISO(req.created_at);
                requestSum.min_date = isBefore(createdAt, requestSum.min_date)
                  ? createdAt
                  : requestSum.min_date;

                requestSum.max_date ??= parseISO(req.created_at);
                requestSum.max_date = isAfter(createdAt, requestSum.max_date)
                  ? createdAt
                  : requestSum.max_date;

                return requestSum;
              },
              { req_ids: [] }
            );
          });

          bankSum = [...bankSum, ...reqlist];
          return bankSum;
        },
        []
      ).filter(
        (row) =>
          !excludeBanks.includes(row.bank?.short_name) &&
          [2, 3].includes(row.request_type_id)
      );

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: FETCH,
          payload: { rows },
        });
      }
    } catch (e) {
      const { response } = e;
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      dispatch(clearResult());
      console.log(e);
      throw e;
    }
  };

export const markLoading = (isLoading = true) => {
  return { type: MARK_LOADING, payload: isLoading };
};

export const clearResult = () => {
  return { type: CLEAR_RESULT };
};

export const setFilter = (filterValue) => {
  return { type: SET_FILTER, payload: filterValue };
};

export const forceReload = () => {
  return { type: FORCE_RELOAD };
};

export const changePageSize = (pageSize) => {
  return { type: CHANGE_PAGE_SIZE, payload: pageSize };
};

export const excludeBanks = ["SCBT", "EXIM", "BOC", "NONE"];
