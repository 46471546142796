import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { Form } from "react-final-form";
import _ from "lodash";
import Joi from "joi";
import validationMsg from "../../../helpers/validationMsg";
import SubmitBtn from "../../SubmitBtn";
import { Check, DriveFileMove } from "@mui/icons-material";
import FieldInput from "../../form-fields/FieldInput";
import { getTextMask } from "../../../helpers/imask";
import api from "../../../apis";
import { useDispatch, useSelector } from "react-redux";
import { setPid } from "../../../ducks/tpoCase";
import { validate as caseFormValidate } from "./CaseForm";
import { format, parseISO } from "date-fns";
import { nameComposer } from "../../../helpers/stringFormater";
import { validate as accountFormValidate } from "../accounts/AccountForm";
import tpoBanks from "../../../data/tpoBanks";
import { forceReload } from "../../../ducks/caseSearch";
import AlertIconDialog from "../../dialogs/AlertIconDialog";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../dialogs/ConfirmDialog";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "auto",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const caseidMask = getTextMask({
  mask: /^[0-9A-Z]{1,13}$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});

export default function TpoCaseSearchForm({ open, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPid = useSelector((state) => state.tpo.pid);
  const authUser = useSelector((state) => state.auth.user);

  const [caseId, setCaseId] = useState(null);
  const [complete, setComplete] = useState(false);

  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const handleGotoCase = async () => {
    try {
      const response = await api.get("/api/cases", {
        params: {
          page_size: 1,
          page: 0,
          search: duplicateDialog,
        },
      });

      const { id } = response.data.rows[0];

      navigate(`/cases/${id}/requests/addH`);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDialogClose = () => {
    navigate(`/cases/${caseId}/requests/addH`);
  };

  const onSubmit = async (formData, form, callback) => {
    let res = null;

    // get data from tpo
    try {
      res = await api.get(`/api/tpo/${formData.case_id}`);
    } catch ({ response: errorRes }) {
      return { [FORM_ERROR]: "เกิดข้อผิดพลาด" };
    }

    if (res.data.statusCode === 400) {
      return {
        [FORM_ERROR]: "ไม่พบข้อมูล",
        case_id: "โปรดตรวจสอบข้อมูล",
        pid: "โปรดตรวจสอบข้อมูล",
      };
    }

    const preData = extractTpoData(res.data.value, authUser);

    // create case
    const caseErrors = caseFormValidate(preData.caseForm);
    if (!_.isEmpty(caseErrors)) {
      return {
        [FORM_ERROR]:
          "ไม่สามารถนำเข้าข้อมูลได้ โปรดสร้าง case โดยใช้ช่องทางปกติ",
      };
    }
    let caseId = null;
    try {
      const caseCreateRes = await api.post("/api/cases", {
        ...preData.caseForm,
        is_tpo: true,
      });
      caseId = caseCreateRes.data.id;
      setCaseId(caseId);
    } catch (e) {
      const response = e.response;

      if (response?.status === 422) {
        setDuplicateDialog(formData.case_id);
        return { [FORM_ERROR]: response?.data.message };
      }

      return {
        [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
      };
    }

    // create accounts
    let accountErrorsCount = 0;
    if (_.isArray(preData.accounts)) {
      for (const acc of preData.accounts) {
        if (!_.isEmpty(accountFormValidate(acc))) {
          accountErrorsCount++;
          continue;
        }
        try {
          await api
            .post(`/api/cases/${caseId}/bank_accounts`, acc)
            .then(() => new Promise((resolve) => setTimeout(resolve, 300)));
        } catch (e) {
          accountErrorsCount++;
        }
      }
    }

    setComplete(accountErrorsCount);
    dispatch(forceReload());
    onClose();
    // succeed set pid to use next time
    if (!userPid) dispatch(setPid(formData.pid));
    form.reset();
    callback();
  };

  return (
    <>
      <AlertIconDialog
        open={complete !== false}
        // open={true}
        onClose={handleDialogClose}
        title="สร้าง case สำเร็จ"
        maxWidth="xs"
      >
        {complete > 0 && (
          <Typography color="warning.main" variant="subtitle2">
            ตรวจพบบางบัญชีไม่สามารถนำเข้าระบบได้
            โปรดตรวจสอบข้อมูลต้นทางหรือท่านสามารถเพิ่ม/แก้ไขเองได้ที่หน้า case
            ของระบบนี้
          </Typography>
        )}
      </AlertIconDialog>
      <ConfirmDialog
        open={!!duplicateDialog}
        onClose={() => setDuplicateDialog(false)}
        cfBtnProps={{
          label: "ตกลง",
          color: "primary",
          startIcon: <DriveFileMove />,
        }}
        onConfirm={handleGotoCase}
        title={`ไม่สามารถเพิ่ม case: ${duplicateDialog}  ได้`}
      >
        เนื่องจากท่านมี case ดังกล่าวในระบบอยู่แล้ว โปรดกด "ตกลง" เพื่อไปที่
        case ดังกล่าวและดำเนินการสร้างการยืนยันรับแจ้งตาม พรกฯ
      </ConfirmDialog>
      <Form validate={validate} onSubmit={onSubmit}>
        {({
          handleSubmit,
          errors,
          error,
          submitError,
          submitting,
          pristine,
          initialValues,
          submitFailed,
          form,
          values,
        }) => {
          return (
            <Modal
              open={open}
              onClose={onClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  เพิ่ม case จากระบบ thaipoliceonline
                </Typography>
                <Typography
                  id="modal-modal-title"
                  variant="subtitle1"
                  component="h2"
                  color="primary"
                >
                  โปรดระบุ thaipoliceonline case id
                </Typography>
                <Paper
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                      {submitFailed && (error || submitError) && (
                        <Grid item xs={12}>
                          <Alert severity="warning" sx={{ width: "auto" }}>
                            {error || submitError}
                          </Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FieldInput
                          name="case_id"
                          label="เลข case id จากระบบ thaipoliceonline"
                          required
                          controlProps={{ fullWidth: true }}
                          inputProps={{
                            inputComponent: caseidMask,
                          }}
                          readOnly={submitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider></Divider>
                      </Grid>
                      <Grid item md={12}>
                        <Stack direction="row" spacing={2}>
                          <SubmitBtn
                            variant="contained"
                            startIcon={<Check />}
                            submitting={submitting}
                            pristine={pristine}
                          >
                            ตกลง
                          </SubmitBtn>
                          <Button
                            type="button"
                            variant="outlined"
                            disabled={submitting}
                            onClick={() => {
                              onClose();
                            }}
                          >
                            ยกเลิก
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Box>
            </Modal>
          );
        }}
      </Form>
    </>
  );
}

export const validateRules = () => ({
  case_id: Joi.string()
    .pattern(/^[A-Z]?\d{4,}$/)
    .max(13)
    .required(),
});

const schema = () =>
  Joi.object(validateRules()).error(
    validationMsg({
      case_id: { stringPattern: "รูปแบบไม่ถูกต้อง" },
    })
  );

const validate = (values) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

const extractTpoData = (data, authUser) => {
  const caseForm = {};

  caseForm.date = format(parseISO(data.create_date), "yyyy-MM-dd");
  caseForm.tpo_case_id = data.tracking_code;
  caseForm.victim_name = data.personal_full_name;
  caseForm.police_name = data.personal_fname_tha
    ? nameComposer(
        data.title_name,
        data.personal_fname_tha,
        data.personal_lname_tha
      )
    : nameComposer(authUser.rank, authUser.fname, authUser.lname);
  caseForm.policestation_org_code = data.org_code
    ? data.org_code.toString()
    : authUser.org.ORG_CODE;
  caseForm.job_title = data.po_position_name || authUser.job_title;

  const accounts = _.uniqWith(
    data.bank_detail?.map((acc) => ({
      channel: 1,
      number: acc.bank_account,
      bank_id: tpoBanks[acc.bank_id]?.id,
      name: acc.bank_account_name,
      type_id: acc.bank_type === "บัญชีคนร้าย" ? 2 : undefined,
    })),
    _.isEqual
  );

  return { caseForm, accounts };
};
