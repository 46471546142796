import { Grid, Paper, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import PermissionForm from "./PermissionFormEdit";

export default function PermissionEditPage() {
  const params = useParams();
  const itemId = parseInt(params.id);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onSubmit = async (formValues) => {
    let submitData = null;

    submitData = _.omit(formValues, ["pol_signature"]);

    if (submitData.is_foreigner) {
      submitData.eng_name = submitData.name;
      submitData.eng_midname = submitData.midname || undefined;
      submitData.eng_surname = submitData.surname;
    } else {
      submitData.nationality = "TH";
    }

    try {
      await api.put(`/api/permisssion/${itemId}`, submitData);
      queryClient.invalidateQueries(["settingpermission", itemId]);
      navigate(`/settingpermission`);
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          แก้ไขสิทธิ์การใช้งาน (ID: {itemId})
        </Typography>
        {/* <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSubmit}
            component={PermissionForm}
            id={itemId}
          />
        </Paper>
      </Grid>
    </>
  );
}
