import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../../apis";
import { Warning } from "@mui/icons-material";
import { authFetch } from "../../../ducks/auth";
import { useDispatch } from "react-redux";

export default function LineCallbackPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [result, setResult] = useState(null);

  const success = (
    <>
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/images/line-icon.png`}
        alt="Logo"
        width="auto"
        height="200px"
        mb={4}
      />
      <Typography variant="h6" gutterBottom>
        เชื่อมต่อรับการแจ้งเตือนผ่าน Line สำเร็จแล้ว
      </Typography>
      {/* <Typography variant="body">
        ท่านสามารถแก้ไขการรับการแจ้งเตือนนี้ได้ที่{" "}
        <Link to="/me">ข้อมูลผู้ใช้</Link>
      </Typography> */}
    </>
  );

  const error = (
    <>
      <Typography variant="h6" gutterBottom color="error">
        <Warning /> &nbsp;เกิดข้อผิดพลาด
      </Typography>
      <Typography variant="body">เกิดข้อผิดพลาดในการเชื่อมต่อ Line</Typography>
    </>
  );

  useEffect(() => {
    const code = searchParams.get("code");

    if (!code) {
      setResult(error);
      return;
    }

    api
      .get(`/api/line_callback`, { params: { code } })
      .then(({ data }) => {
        setResult(success);
        // dispatch(authFetch());
      })
      .catch(({ response }) => {
        setResult(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          // minWidth: 250,
          maxWidth: 500,
        }}
      >
        {result || <Typography variant="h5">Loading...</Typography>}
      </Paper>
    </Grid>
  );
}
