import { Add, Download, Print, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, Grid, Stack, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../../../apis";
import { policeSetFilter } from "../../../../ducks/masterDataList";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import fileDownload from "js-file-download";

export default function AgencySearchForm({
  loading, //mycustom
  onReset, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  pageSize,
}) {
  const dispatch = useDispatch();
  const qState = useSelector((state) => state.auth.user.permissions);

  // states
  const [bhCode, setBhCode] = useState(null);
  // const [bkCode, setBkCode] = useState(null);
  const [bhList, setBhList] = useState([]);
  const [bkList, setBkList] = useState([]);
  const [anchorElDowload, setAnchorElDowload] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const openDowload = Boolean(anchorElDowload);
  const [anchorElPrint, setAnchorElPrint] = useState(null);
  const openPrint = Boolean(anchorElPrint);
  // data
  const optionStatus = [
    { label: "เปิดใช้งาน", value: true },
    { label: "ปิดใช้งาน", value: false },
  ];
  // functions
  const onStatusChange = (e, value) => {
    form.change("status", value !== null ? value.value : "");
  };
  const onBHChange = (e, value) => {
    setBhCode(value);

    if (!value) setBkList([]);
    else getBKList(value);
    form.change("bh_code", value && value.BH_CODE ? value.BH_CODE : "");
  };
  const onBKChange = (e, value) => {
    // setBkCode(value);
    form.change("bk_code", value && value.BK_CODE ? value.BK_CODE : "");
  };
  const onChangeSearch = (e) => {};

  const getBHList = () => {
    api.get(`/api/orgs/bh`).then((res) => setBhList(res.data));
  };
  const getBKList = (bh) => {
    api
      .get(`/api/orgs/bk?bh_code=${_.get(bh, "BH_CODE", "")}`)
      .then((res) => setBkList(res.data));
  };

  useEffect(() => {
    getBHList();
  }, []);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const handleClickPrint = (event) => {
    setAnchorElPrint(event.currentTarget);
  };
  const handleClosePrint = () => {
    setAnchorElPrint(null);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      {qState?.setting_police_station?.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Field name="status">
              {({ input }) => (
                <Autocomplete
                  options={optionStatus}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="สถานะใช้งาน" />
                  )}
                  onChange={onStatusChange}
                  value={
                    optionStatus != null
                      ? optionStatus.find(
                          (option) => option.value === input.value
                        ) || null
                      : null
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={3}>
            <Field name="bh_code">
              {({ input }) => (
                <Autocomplete
                  options={bhList}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="หน่วยงาน บช" />
                  )}
                  onChange={onBHChange}
                  value={
                    bhList != null
                      ? bhList.find(
                          (option) => option.BH_CODE === input.value
                        ) || null
                      : null
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={3}>
            <Field name="bk_code">
              {({ input }) => (
                <Autocomplete
                  options={bkList}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="หน่วยงาน บก" />
                  )}
                  onChange={onBKChange}
                  value={
                    bkList != null
                      ? bkList.find(
                          (option) => option.BK_CODE === input.value
                        ) || null
                      : null
                  }
                  disabled={!bhCode}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={12} lg={8} xl={6}>
            <FieldInput
              onChange={onChangeSearch}
              name="search"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mb={2}>
        {qState?.setting_police_station?.includes("search") && (
          <Grid item md={12} lg={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  setBhCode(null);
                  //  setBkCode(null);
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        )}

        <Grid
          item
          md={12}
          lg={qState?.setting_police_station?.includes("search") ? 8 : 12}
        >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <LoadingButton
              id="export-button-pdf"
              type="button"
              variant="outlined"
              startIcon={<Print />}
              loadingPosition="start"
              aria-controls={openPrint ? "menu-print" : undefined}
              aria-haspopup="true"
              aria-expanded={openPrint ? "true" : undefined}
              onClick={handleClickPrint}
              loading={loadingPDF}
            >
              พิมพ์
            </LoadingButton>

            <Menu
              id="menu-print"
              anchorEl={anchorElPrint}
              open={openPrint}
              onClose={handleClosePrint}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  try {
                    setLoadingPDF(true);
                    handleClosePrint(false);
                    const res = await api.get(
                      "/api/policestations?export=pdf",
                      {
                        responseType: "blob",
                      }
                    );

                    // Create a URL from the Blob
                    const blob = new Blob([res.data], {
                      type: "application/pdf",
                    });
                    const url = URL.createObjectURL(blob);

                    // Open the PDF in a new tab and trigger the print dialog
                    const newWindow = window?.open(url);
                    if (newWindow) {
                      newWindow.onload = () => {
                        newWindow?.focus();
                        newWindow?.print();
                      };
                    }

                    // Optional: Revoke the URL to free up memory
                    URL?.revokeObjectURL(url);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingPDF(false);
                  }
                }}
              >
                Preview PDF
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  try {
                    handleClosePrint(false);
                    setLoadingPDF(true);

                    const res = await api.get("/api/policestations", {
                      responseType: "blob",
                      params: {
                        // page_size: pageSize,
                        // page: page,
                        export: "pdf",
                        ...values,
                      },
                    });

                    const contentDisposition =
                      res.headers["content-disposition"];

                    // Use a regular expression to match the filename
                    const filenameMatch =
                      contentDisposition.match(/filename="(.+?)"/);

                    let filename = "";
                    if (filenameMatch && filenameMatch[1]) {
                      filename = filenameMatch[1];
                    }

                    fileDownload(res.data, filename);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingPDF(false);
                  }
                }}
              >
                Dowload PDF
              </MenuItem>
            </Menu>
            {qState?.setting_police_station?.includes("download") && (
              <LoadingButton
                id="export-button-excel"
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                // loading={pdfDownloading}
                // disabled={loadingPDF}
                // onClick={() => handleDownload("/api/", setPdfDownloading)}
                aria-controls={openDowload ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={handleClickDowload}
                loading={loadingFile}
              >
                ดาวน์โหลด
              </LoadingButton>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  try {
                    handleCloseDownload(false);
                    setLoadingFile(true);

                    const res = await api.get("/api/policestations", {
                      responseType: "blob",
                      params: {
                        // page_size: pageSize,
                        // page: page,
                        export: "excel",
                        ...values,
                      },
                    });

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];
                    fileName =
                      fileName.at(-1) === '"'
                        ? fileName.slice(1, -1)
                        : fileName;
                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingFile(false);
                  }
                }}
              >
                Excel
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  try {
                    handleCloseDownload(false);
                    setLoadingFile(true);

                    const res = await api.get("/api/policestations", {
                      responseType: "blob",
                      params: {
                        // page_size: pageSize,
                        // page: page,
                        export: "csv",
                        ...values,
                      },
                    });

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];
                    fileName =
                      fileName.at(-1) === '"'
                        ? fileName.slice(1, -1)
                        : fileName;
                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingFile(false);
                  }
                }}
              >
                CSV
              </MenuItem>
            </Menu>
            {qState?.setting_police_station?.includes("create") && (
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                component={Link}
                onClick={() => dispatch(policeSetFilter({}))}
                to="/agency/add"
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
