import { Grid, Typography } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";
import FieldAccountAtm from "../../../form-fields/customs/FieldAccountAtm";
import LetterFormD from "./sub-forms/LetterFormD";

export default function AtmRequest({ readOnly, bankAccounts, hiddenUserForm = false }) {
  const bankIdField = useField("bank_id");

  if (!bankIdField.input.value) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="error">
          โปรดเลือกธนาคาร
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">รายการบัญชี</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldAccountAtm
          name="bank_accountsD"
          rows={bankAccounts}
          readOnly={readOnly}
        />
      </Grid>
      <LetterFormD readOnly={readOnly} hiddenUserForm={hiddenUserForm} />
    </>
  );
}
