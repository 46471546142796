import React from "react";
import { Navigate } from "react-router-dom";

export default function RedirectIfAuthenticate({ children, roleIds }) {
  const isAuthenticated = localStorage.getItem("access_token");

  if (isAuthenticated) {
    return <Navigate to="/me" />;
  }

  return children;
}
