import {
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { isArray } from "lodash";
import React, { useMemo } from "react";
import requestStatus, { masterSteps } from "../../data/hr03Status";
import { makeStyles } from "@material-ui/core/styles";

const isStepFail = (step) => step === "reject";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    width: "100%",
    maxWidth: "100%",
    overflow: "scroll",
    backgroundColor: theme.palette.background.paper,
  },
  stepper: {
    minWidth: 500,
    width: "100%",
  },
}));

export default function Hr03Stepper({ status, note, ...props }) {
  const classes = useStyles();
  const stepper = useMemo(() => {
    let activeStep = undefined;
    const steps = masterSteps.map((step, index) => {
      let st = null;
      if (isArray(step)) {
        st = step.find((e) => e === status) || step[0];
      } else {
        st = step;
      }
      const reqStatus = requestStatus[st];

      let stepComponent = (
        <StepLabel {...reqStatus.stepProps}>{reqStatus.label}</StepLabel>
      );

      if (reqStatus.desc) {
        stepComponent = (
          <Tooltip placement="top" arrow title={reqStatus.desc}>
            {stepComponent}
          </Tooltip>
        );
      }

      if (st === status) activeStep = index;

      return <Step key={step}>{stepComponent}</Step>;
    });

    if (activeStep === undefined) {
      // unknown status
      return undefined;
    }

    return (
      <div className={classes.stepperContainer}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps}
        </Stepper>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Stack direction="column" spacing={2}>
      {stepper}
      {note && (
        <>
          <Divider />
          <Typography
            variant="subtitle1"
            color={isStepFail(status) && "error"}
            paragraph
            sx={{ overflowWrap: "break-word" }}
          >
            หมายเหตุ: {note}
          </Typography>
        </>
      )}
    </Stack>
  );
}
