import TableViewIcon from "@mui/icons-material/TableView";
import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import CaseAssignHistoryInvestigatingModal from "./CaseAssignHistoryInvestigatingModal";

export default function CaseForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  isTpo = false,
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  caseID,
}) {
  const [isOpenModalHistory, setIsOpenModalHistory] = React.useState(false);

  const handleOpenModalHistory = () => setIsOpenModalHistory(true);
  const handleCloseModalHistory = () => setIsOpenModalHistory(false);

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider textAlign="center">ข้อมูลทั่วไป</Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name="date"
              label="วันที่"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="tpo_case_id"
              label="เลขอ้างอิง"
              required
              controlProps={{ fullWidth: true, disabled: !readOnly && !!isTpo }}
              readOnly={readOnly}
              disabled={readOnly}
              inputProps={{
                placeholder: "หมายเลข case จาก thaipoliceonline.com",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FieldInput
              name="victim_name"
              label="ชื่อ สกุล ผู้เสียหาย"
              required
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={12}>
              <Divider textAlign="center" sx={{ mt: 2, mr: 2 }}>
                <Box display="inline-flex" alignItems="center">
                  <Box flexGrow={1} textAlign="center">
                    พนักงานสอบสวน
                  </Box>
                  <Box position="absolute" right={0} pr={7}>
                    <TableViewIcon
                      sx={{
                        fontSize: 30,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleOpenModalHistory();
                      }}
                    />
                  </Box>
                </Box>
              </Divider>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldInput
              name="police_name"
              label="ยศ ชื่อ สกุล"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="job_title"
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FieldAsyncAutoComplete
              name="policestation_org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={readOnly}
              disabled={readOnly}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12} md={12}>
            <FieldInput
              name="note"
              label="รายละเอียดเบื้องต้น"
              controlProps={{ fullWidth: true }}
              inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </form>
      <CaseAssignHistoryInvestigatingModal
        open={isOpenModalHistory}
        handleClose={handleCloseModalHistory}
        caseID={caseID}
      />
    </>
  );
}
