import { Download, ForwardToInbox } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import api from "../../../apis";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import FieldInput from "../../form-fields/FieldInput";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  // height: "100%",
};

export default function CheckedSumRequestView({ sumReqId, handleCancel }) {
  const rows = useSelector((state) => state.sumRequestList.data);
  const qState = useSelector((state) => state.auth.user.permissions);

  const { height, width } = useWindowDimensions();
  const modalRef = useRef();

  const sumReq = useMemo(() => {
    return rows.find((sumReq) => sumReq.id === sumReqId);
  }, [rows, sumReqId]);

  const reqPacked = useMemo(() => {
    if (sumReq.bank?.short_name === "SCB" && sumReq.request_type_id === 3) {
      return 50;
    }
    return 50;
  }, [sumReq.bank?.short_name, sumReq.request_type_id]);

  // for fetch info
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [csvDownloading, setCsvDownloading] = useState(false);
  const handleConfirm = () => {
    setLoading(true);
    setSubmitting(true);
    api
      .post(`/api/mgt/pol_requests/change_status_pack`, {
        list_pol_request_id: sumReq.req_ids.slice(0, reqPacked),
        bank_id: sumReq.bank_id,
        request_type_id: sumReq.request_type_id,
        new_status: "sent_to_bank",
      })
      .then((response) => {
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch((e) => {
        handleCancel({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleDownload = (uri, setActionState) => {
    setLoading(true);
    setActionState(true);
    api
      .post(
        uri,
        {
          list_pol_request_id: sumReq.req_ids,
          bank_id: sumReq.bank_id,
          request_type_id: sumReq.request_type_id,
          new_status: "checked",
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoading(false);
        setActionState(false);
      });
  };

  const [modalDimensions, setModalDimensions] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    if (modalRef.current) {
      setModalDimensions({
        width: modalRef.current.offsetWidth,
        height: modalRef.current.offsetHeight,
      });
    }
  }, []);

  const modalStyle = {
    ...masterModalStyle,
    height: modalDimensions.height < height ? undefined : "100%",
    width: modalDimensions.width < width ? undefined : "80%",
  };

  const renderDateRange = (min, max) => {
    const minDate = min ? format(min, "dd/MM/yyyy") : "undefined";
    const maxDate = max ? format(max, "dd/MM/yyyy") : "undefined";
    return `${minDate} - ${maxDate}`;
  };

  const amountProps =
    sumReq.req_ids.length > reqPacked
      ? {
          controlProps: {
            fullWidth: true,
            margin: "normal",
            focused: true,
          },
          inputLabelProps: { color: "warning" },
          inputProps: { color: "warning" },
          helperTextProps: { sx: { color: "warning.main" } },
          helperText: `ระบบส่ง Email ได้ครั้งละ ${reqPacked} คำขอ`,
          showHelperTextReadOnly: true,
        }
      : {
          controlProps: {
            fullWidth: true,
            margin: "normal",
            focused: true,
          },
        };

  return (
    <Box sx={modalStyle} ref={modalRef}>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="div">
          ข้อมูลคำขอแบบกลุ่ม
        </Typography>
      </Stack>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        <Form
          onSubmit={() => {}}
          initialValues={{
            bank: sumReq.bank.name,
            request_type: sumReq.request_type.name,
            date_range: renderDateRange(sumReq.min_date, sumReq.max_date),
            amount: sumReq.req_ids.length,
          }}
        >
          {({ handleSubmit }) => (
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <FieldInput
                name="bank"
                label="ธนาคาร"
                controlProps={{ fullWidth: true, margin: "normal" }}
                readOnly
              />
              <FieldInput
                name="request_type"
                label="ประเภทคำขอ"
                controlProps={{ fullWidth: true, margin: "normal" }}
                readOnly
              />
              <FieldInput
                name="date_range"
                label="ช่วงวันที่"
                controlProps={{ fullWidth: true, margin: "normal" }}
                readOnly
              />
              <FieldInput
                name="amount"
                label="จำนวนคำขอ"
                readOnly
                {...amountProps}
              />
            </Box>
          )}
        </Form>
      </Paper>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} lg={4}>
            {qState?.approved_request_group?.includes("mailling") && (
              <LoadingButton
                type="button"
                variant="contained"
                startIcon={<ForwardToInbox />}
                loadingPosition="start"
                loading={submitting}
                disabled={loading}
                onClick={() => handleConfirm()}
              >
                ส่ง email
              </LoadingButton>
            )}
          </Grid>
          <Grid item md={12} lg={8}>
            {qState?.approved_request_group?.includes("download") && (
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <LoadingButton
                  type="button"
                  variant="outlined"
                  startIcon={<Download />}
                  loadingPosition="start"
                  loading={pdfDownloading}
                  disabled={loading}
                  onClick={() =>
                    handleDownload(
                      "/api/mgt/pol_requests/export_pdf_pack",
                      setPdfDownloading
                    )
                  }
                >
                  PDF
                </LoadingButton>
                <LoadingButton
                  type="button"
                  variant="outlined"
                  startIcon={<Download />}
                  loadingPosition="start"
                  loading={csvDownloading}
                  disabled={loading}
                  onClick={() =>
                    handleDownload(
                      "/api/mgt/pol_requests/export_template_pack",
                      setCsvDownloading
                    )
                  }
                >
                  template
                </LoadingButton>
              </Stack>
            )}
          </Grid>
          <Grid item md={12} lg={12}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={() => handleCancel()}>
                ปิด
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
