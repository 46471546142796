import { Chip, Tooltip } from "@mui/material";

const requestStatus = {
  new: { label: "New", color: "warning" },
  sent_to_contact_person: {
    label: "Contact Person",
    color: "info",
    desc: "ระบบส่งให้ Contact Person แล้ว",
  },
  checked: {
    label: "Checked",
    color: "info",
    desc: "contact person ได้ตรวจสอบแล้ว รอดำเนินการส่ง",
  },
  sent_to_bank: {
    label: "Sent",
    desc: "หน่วยงานปลายทางรับคำขอแล้ว อยู่ในระหว่างดำเนินการ",
    color: "info",
  },
  reject: {
    label: "Reject",
    color: "error",
    desc: "contact person ปฏิเสธการส่งต่อไปยังหน่วยงานปลายทาง",
  },
  recived_from_bank: {
    label: "Success",
    desc: "ระบบได้รับผลแล้ว สามารถดาวน์โหลดผลจากระบบและอยู่ในระหว่างดำเนินการส่งเข้าอีเมลของท่าน",
    color: "success",
  },
  sent_to_pol: {
    label: "Email Success",
    desc: "ระบบได้รับผลแล้ว สามารถดาวน์โหลดผลจากระบบและส่งอีเมลให้ท่านแล้ว หากผลลัพธ์มีปัญหากรุณาแจ้ง lineid: @pctsupport",
    color: "success",
  },
};

export default requestStatus;

export const getChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Tooltip placement="top" arrow title="ไม่พบสถานะ กรุณาติดต่อ admin">
        <Chip
          label="unknow"
          color="error"
          {...props}
          sx={{
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
              fontFamily: "Kanit",
            },
          }}
        />
      </Tooltip>
    );
  }

  let chip = (
    <Chip
      label={reqStatus.label}
      color={reqStatus.color}
      {...props}
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
          fontFamily: "Kanit",
        },
      }}
    />
  );

  if (reqStatus.desc) {
    chip = (
      <Tooltip placement="top" arrow title={reqStatus.desc}>
        {chip}
      </Tooltip>
    );
  }

  return chip;
};

export const getDescChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Chip
        label="ไม่พบสถานะ กรุณาติดต่อ admin"
        color="error"
        {...props}
        sx={{
          height: "auto",
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
            fontFamily: "Kanit",
          },
        }}
      />
    );
  }

  return (
    <Chip
      label={reqStatus.desc || reqStatus.label}
      color={reqStatus.color}
      {...props}
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
          fontFamily: "Kanit",
        },
      }}
    />
  );
};

export const masterSteps = [
  "new",
  "sent_to_contact_person",
  "checked",
  "sent_to_bank",
  "recived_from_bank",
  "sent_to_pol",
];
export const masterRejectSteps = ["new", "sent_to_contact_person", "reject"];
