import Joi from "joi";
import React from "react";
import _ from "lodash";
import validationMsg from "../../../helpers/validationMsg";
import { Button, Grid, Stack, Typography } from "@mui/material";
import FieldInput from "../../form-fields/FieldInput";
// import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Search } from "@mui/icons-material";
import { FORM_ERROR } from "final-form";
import thaiIdValidator from "../../../helpers/ThaiIdValidator";

export default function AccountSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  touched,
  dirty,
}) {
  const fieldInputError =
    error === "EMPTY_FORM" && dirty
      ? {
          inputLabelProps: { error: true },
          inputProps: { error: true },
        }
      : undefined;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        {fieldInputError && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="error">
              โปรดระบุอย่างน้อย 1 ช่อง
            </Typography>
          </Grid>
        )}
        {/* <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            // filter={{ search: "xxxx"}}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid> */}
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="accountno"
            label="เลขบัญชี"
            controlProps={{ fullWidth: true }}
            inputProps={{
              ...fieldInputError?.inputProps,
              placeholder: "ระบุครบทุกตัว",
            }}
            inputLabelProps={fieldInputError?.inputLabelProps}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="accountname"
            label="ชื่อบัญชี"
            controlProps={{ fullWidth: true }}
            {...fieldInputError}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประชาชน"
            controlProps={{ fullWidth: true }}
            inputProps={{
              ...fieldInputError?.inputProps,
              placeholder: "ระบุทั้ง 13 หลัก",
            }}
            inputLabelProps={fieldInputError?.inputLabelProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={
                (pristine && !modifiedSinceLastSubmit) ||
                !_.isEmpty(error) ||
                !_.isEmpty(errors)
              }
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });
  // console.log(vResult);
  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors._error = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    _.isEmpty(errors) &&
    !values.card_id &&
    !values.accountno &&
    !values.accountname
  ) {
    errors[FORM_ERROR] = "EMPTY_FORM";
  }

  return errors;
};

export const validateRules = {
  bank_id: Joi.number().allow(null),
  card_id: Joi.string()
  .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
  // .required()
  .messages({
    "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
  }),
  accountno: Joi.string()
    .pattern(/^\d{10,}$/)
    .max(12)
    .allow(null),
  accountname: Joi.string().min(5).max(50).allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
