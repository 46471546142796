import { Refresh, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  forceReload,
  setFilter,
} from "../../../ducks/accountSearch";
import AccountSearchForm, { validate } from "./AccountSearchForm";
import _ from "lodash";

export default function AccountSearchPage() {
  const search_account = useSelector(
    (state) => state.auth.user?.permissions?.search_account ?? []
  );
  const dispatch = useDispatch();

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.accountSearch.data);
  const total = useSelector((state) => state.accountSearch.meta.total);
  const loading = useSelector((state) => state.accountSearch.meta.loading);
  const forceReload = useSelector(
    (state) => state.accountSearch.meta.forceReload
  );
  const page = useSelector((state) => state.accountSearch.meta.page);
  const pageSize = useSelector((state) => state.accountSearch.meta.pageSize);
  const filter = useSelector((state) => state.accountSearch.meta.filter);

  useEffect(() => {
    if (_.isEmpty(filter)) {
      dispatch(clearResult());
      return undefined;
    }
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback((reason) => {
    if (reason) {
      if (reason.type === "error") {
        SetToastInfo(reason);
      } else {
        //   dispatch(mrqForceReload());
      }
    }
    setOpenModal(false);
  }, []);

  const onSearchSubmit = (data) => {
    dispatch(setFilter(data));
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Stack
            spacing={0.5}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            <IconButton
              type="button"
              variant="contained"
              size="small"
              disabled
              onClick={() => {
                //     handleOpenModal(
                //       <NoAccountView
                // 	handleCancel={handleCloseModal}
                // 	noAccId={params.id}
                //       />
                //     );
                console.log("to do: view record");
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <Tooltip placement="top" arrow title={params.row.response?.bank?.name}>
          <Typography>{params.row.response?.bank?.short_name}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 400,
    },
    {
      field: "accountname",
      headerName: "ชื่อบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวประชาชน",
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
    },
    {
      field: "recived_at",
      headerName: "วันที่ได้รับข้อมูล",
      sortable: false,
      width: 150,
      valueGetter: ({ row }) => {
        const value = row.response?.recived_at;
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5"> ค้นหาบัญชี</Typography>
        {/* <Typography variant="subtitle1" color="error">
          กรอกข้อมูลเฉพาะบัญชีที่ไม่ได้ขออายัดผ่านระบบ banking.ccib.go.th
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            height: 700,
            width: "100%",
          }}
        >
          {search_account.includes("list") && (
            <Form
              validate={validate}
              onSubmit={onSearchSubmit}
              component={AccountSearchForm}
              loading={loading}
              onReset={() => dispatch(setFilter({}))}
            />
          )}
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(forceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
