import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Field } from "react-final-form";

export default function FieldAutoComplete({
  name,
  label,
  options = [],
  required = false,
  readOnly = false,
  controlProp = {},
  inputProps = {},
  placeholder,
  disabled = false,
  sx
}) {
  const [inputValue, setInputValue] = useState("");
  return (
    <Field
      name={name}
      parse={(value) => value?.value}
      format={(value) =>
        value ? options.find((op) => value === op.value) : null
      }
    >
      {({ input, meta }) => {
        return (
          <FormControl
            variant="outlined"
            error={(meta.error || meta.submitError) && meta.touched}
            {...controlProp}
          >
            <InputLabel
              id={`${input.name}-select-label`}
              // required={required}
              error={(meta.error || meta.submitError) && meta.touched}
            ></InputLabel>
            <Autocomplete
              //       disablePortal
              readOnly={readOnly}
              options={options}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              value={input.value || null}
              onChange={(event, value) => {
                input.onChange(value);
              }}
              // filterOptions={(x) => x}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label={`${label}${required ? " *" : ""}`}
                    error={(meta.error || meta.submitError) && meta.touched}
                    placeholder={placeholder}
                    disabled={disabled}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                        {
                          backgroundColor: "rgba(0, 0, 0, 0.12)", // Customize background color when disabled
                          ...sx,
                        },
                   
                    }}
                  />
                  {(meta.error ||
                    (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
                    meta.touched && (
                      <FormHelperText
                        id={`${input.name}-error`}
                        variant="outlined"
                      >
                        {meta.error || meta.submitError}
                      </FormHelperText>
                    )}
                </>
              )}
              {...inputProps}
            />
          </FormControl>
        );
      }}
    </Field>
  );
}
