import { Grid, Typography } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";
import FieldAccountCctv from "../../../form-fields/customs/FieldAccountCctv";
import LetterFormE from "./sub-forms/LetterFormE";

export default function CctvRequest({
  readOnly,
  bankAccounts,
  hiddenUserForm = false,
}) {
  const bankIdField = useField("bank_id");

  if (!bankIdField.input.value) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="error">
          โปรดเลือกธนาคาร
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">รายการบัญชี</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldAccountCctv
          name="bank_accountsE"
          readOnly={readOnly}
          rows={bankAccounts}
        />
      </Grid>
      <LetterFormE readOnly={readOnly} hiddenUserForm={hiddenUserForm} />
    </>
  );
}
