import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Grid, Modal, Paper, Snackbar, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import {
  permissionAuditChangePage,
  permissionAuditChangePageSize,
  permissionAuditClearResult,
  permissionAuditFetch,
  permissionAuditForceReload,
  permissionAuditSetFilter,
} from "../../../../ducks/auditList";
import PermissionLogSearchForm from "./PermissionLogSearchForm";

export default function SettingPermissionLog() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(permissionAuditClearResult());
      dispatch(permissionAuditSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state?.auditList?.permission_audit?.data);
  const total = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.total
  );
  const loading = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.loading
  );
  const forceReload = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.forceReload
  );
  const page = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.page
  );
  const pageSize = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.pageSize
  );
  const filter = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.filter
  );

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      permissionAuditFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(permissionAuditForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 70,
    },
    {
      field: "auditable_id",
      headerName: "auditable_id",
      sortable: true,
      width: 70,
    },
    {
      field: "auditable_type",
      headerName: "auditable_type",
      sortable: true,
      width: 200,
    },
    {
      field: "user",
      headerName: "user",
      sortable: true,
      width: 100,
    },
    {
      field: "event",
      headerName: "event",
      sortable: true,
      width: 100,
    },
    {
      field: "ip_address",
      headerName: "IP_Address",
      sortable: true,
      width: 100,
    },
    {
      field: "new_values",
      headerName: "new_values",
      sortable: true,
      width: 300,
      renderCell: (params) => (
        <span>
          {Object.entries(JSON.parse(params.row.new_values || "{}")).map(
            (el) =>
              el[0] !== "created_at" &&
              el[0] !== "updated_at" && (
                <p key={el[1]}>
                  {el[0] +
                    " : " +
                    (el[0] === "rolesPermission" && JSON.parse(el[1])
                      ? JSON.parse(el[1])
                          .map((it) => it.role_name)
                          .join(", ")
                      : el[1])}
                </p>
              )
          )}
        </span>
      ),
    },
    {
      field: "old_values",
      headerName: "old_values",
      sortable: true,
      width: 300,
      renderCell: (params) => (
        <span>
          {Object.entries(JSON.parse(params.row.old_values || "{}")).map(
            (el) =>
              el[0] !== "created_at" &&
              el[0] !== "updated_at" && (
                <p key={el[1]}>
                  {el[0] +
                    " : " +
                    (el[0] === "rolesPermission" && JSON.parse(el[1])
                      ? JSON.parse(el[1])
                          .map((it) => it.role_name)
                          .join(", ")
                      : el[1])}
                </p>
              )
          )}
        </span>
      ),
    },
    {
      field: "created_at",
      headerName: "วันที่สร้าง",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
    {
      field: "updated_at",
      headerName: "วันที่อัพเดต",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(permissionAuditSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">Permission Log </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={PermissionLogSearchForm}
            loading={loading}
            onReset={() => dispatch(permissionAuditSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            initialValues={{}}
            rows={rows}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows ?? []}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(permissionAuditChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(permissionAuditChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state?.auditList?.metaPermissionAudit?.loading
  );

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(permissionAuditForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
