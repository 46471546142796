import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";

export default function FieldComponent({
  name,
  label,
  required = false,
  readOnly = false,
  pickerProps = {},
  input,
  meta,
  disabled = false,
  sx
}) {
  const [emptyValue, setEmptyValue] = useState(false);

  // Reset emptyValue when meta changes and the field is not pristine
  useEffect(() => {
    if (!meta.pristine && !meta.initial) {
      setEmptyValue(false);
    }
  }, [meta.pristine, meta.initial]);

  // Handle onBlur event
  const handleBlur = useCallback(() => {
    input.onBlur();
  }, [input]);

  // Handle input change event
  const handleChange = useCallback(
    (newValue) => {
      input.onChange(newValue);
      setEmptyValue(false);
    },
    [input]
  );

  return (
    <DatePicker
      {...pickerProps}
      label={`${label}${required ? " *" : ""}`}
      value={input.value || null} // Set null instead of undefined
      readOnly={readOnly}
      disabled={disabled}
      onChange={handleChange}
      renderInput={(params) => {
        const { onBlur: originalOnBlur, onChange: originalOnChange, ...inputProps } = params.inputProps;

        inputProps.onBlur = (e) => {
          input.onBlur();
          originalOnBlur?.(e);
        };

        inputProps.onChange = (e) => {
          if (emptyValue) {
            setEmptyValue(false);
          }
          originalOnChange?.(e);
        };

        if (!input.value && emptyValue) {
          inputProps.value = "";
        }

        params.error = (meta.error || meta.submitError) && meta.touched;
        if (params.error) {
          params.helperText = meta.error || meta.submitError;
        }

        return (
          <TextField
            sx={{
              width: "100%",
              ...sx,
              "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
              "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
            {...params}
          />
        );
      }}
      onClose={handleBlur}
      onOpen={() => {
        if (emptyValue) {
          setEmptyValue(false);
        }
      }}
    />
  );
}
