import {
  Delete,
  Refresh,
  RemoveRedEye
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Tooltip,
  Typography
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import api from "../../../apis";
import {
  usersClearResult,
  usersForceReload,
  usersSetFilter
} from "../../../ducks/setting";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import UserSearchForm from "./UploadSuspiciousSearchForm";

export default function Hr03RequestActive() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const navigate = useNavigate();

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(usersClearResult());
      dispatch(usersSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };
  // const rows = useSelector((state) => state?.setting?.users?.data);
  // const total = useSelector((state) => state?.setting?.metaUsers?.total);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);

  // const page = useSelector((state) => state?.setting?.metaUsers?.page);
  // const pageSize = useSelector((state) => state?.setting?.metaUsers?.pageSize);
  const filter = useSelector((state) => state?.setting?.metaUsers?.filter);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const qState = useSelector((state) => state.auth.user.permissions);
  // useEffect(() => {
  //   // Give this fetch an ID
  //   const fetchId = ++fetchIdRef.current;
  //   //filter overide by url status
  //   const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
  //   // Only update the data if this is the latest fetch
  //   dispatch(
  //     usersFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
  //   ).catch((e) => {
  //     SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(usersForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const usersRefetch = useCallback(
    async (page = 0, page_size = 10, filter = {}) => {
      try {
        setIsLoading(true);
        const res = await api.get(
          `/api/reasonable`,{
            params: {
              page:page,
              page_size:page_size,
              bank_id:filter.bank_id,
              number:filter.number,
              type:filter.type,
              search:filter.search ,
            },
          }
         
        );
        // &status=${_.get(
        //   filter,
        //   "status",
        //   true
        // )}
        setUsersData(res.data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/external/users/${cfDel}`)
      .then(() => {
        usersRefetch();
        dispatch(usersForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  useEffect(() => {
    usersRefetch(page, pageSize, filter);
  }, [page, pageSize, filter, usersRefetch]);

  const columns = [
    qState?.upload_suspicious?.includes("view")
      ? {
          field: "actions",
          headerName: "actions",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            return (
              <>
                <Tooltip title="ดูข้อมูล">
                  <IconButton
                    type="button"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/UploadSuspicious/edit/${params.id}`);
                    }}
                  >
                    <RemoveRedEye />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        }
      : null,
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },
    {
      field: "bank",
      headerName: "ธนาคาร",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.bank?.short_name}</>;
      },
    },
    {
      field: "number",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return <>{params.row.number}</>;
      },
    },
    {
      field: "type",
      headerName: "เหตุอันควรสงสัย",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row?.type?.name ?? "อื่นๆ"}</>;
      },
    },

    {
      field: "police_station",
      headerName: "หน่วยงานที่เเจ้ง",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.police_station?.ORG_ABBR ?? "-"}</>;
      },
    },

    {
      field: "date",
      headerName: "วันที่เเจ้งข้อมูล",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
  ].filter(Boolean);

  const onSearchSubmit = (formValues) => {
    dispatch(usersSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ(ID: {cfDel})
      </ConfirmDialog>
      <Grid item xs={12}>
        <Typography variant="h5">Upload เหตุอันควรสงสัย</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={UserSearchForm}
            loading={loading}
            onReset={() => dispatch(usersSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            usersData={usersData}
          />

          <DataGrid
            // density="compact"
            rowCount={usersData.total ?? 0}
            disableColumnMenu
            columns={columns}
            rows={_.get(usersData, "rows", [])}
            loading={loading || isLoading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(page) =>
              // dispatch(usersChangePage(page))
              setPage(page)
            }
            onPageSizeChange={(pageSize) => {
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.setting.metaUsers.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(usersForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
