import { Add, Edit, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { format, isAfter, parseISO, startOfToday } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormSpy } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clearFormState, updateFormState } from "../../../ducks/finalForm";
import { defaultJoiDates } from "../../../helpers/dateHelper";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import FieldTimePicker from "../../form-fields/FieldTimePicker";
import SubmitBtn from "../../SubmitBtn";
import AccountAddPage from "../accounts/AccountAddPage";
import AccountEditPage from "../accounts/AccountEditPage";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransactionForm({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();

  // add account modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const AddDesAccButtonPaper = ({ children }) => {
    return (
      <Paper>
        {children}
        <Box>
          <Button
            startIcon={<Add />}
            type="button"
            variant="text"
            sx={{ width: "100%" }}
            onClick={() => {
              handleOpenModal(
                <AccountAddPage
                  handleCancel={handleCloseModal}
                  tsFieldName="des_bank_account_id"
                />
              );
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            เพิ่มบัญชี
          </Button>
        </Box>
      </Paper>
    );
  };

  const AddSrcAccButtonPaper = ({ children }) => {
    return (
      <Paper>
        {children}
        <Box>
          <Button
            startIcon={<Add />}
            type="button"
            variant="text"
            sx={{ width: "100%" }}
            onClick={() => {
              handleOpenModal(
                <AccountAddPage
                  handleCancel={handleCloseModal}
                  tsFieldName="src_bank_account_id"
                />
              );
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            เพิ่มบัญชี
          </Button>
        </Box>
      </Paper>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearFormState("transaction"));
    };
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <FormSpy
        // subscription={{ pristine: true }}
        onChange={(state) =>
          dispatch(updateFormState("transaction", form, state))
        }
      />
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <FieldAsyncSelect
            name="src_bank_account_id"
            label="บัญชีต้นทาง"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath={`/api/cases/${caseId}/bank_accounts`}
            itemFormat={(row) => {
              return {
                value: row.id,
                label:
                  `${row.bank?.short_name} : ${row.number} : ${
                    row.name || ""
                  }` +
                  (row.type
                    ? `(${row.type?.type}${row.seq ? `:${row.seq}` : ""})`
                    : ""),
              };
            }}
            inputProps={{
              sx: { width: "100%" },
              PaperComponent: AddSrcAccButtonPaper,
              renderOption: (props, options) => {
                return (
                  <li
                    key={options.value}
                    {...props}
                    style={{ position: "relative" }}
                  >
                    <Box sx={{ pr: 3 }}>
                      <Typography>{options.label}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "block",
                        position: "absolute",
                        right: 0,
                        pr: 1,
                        textAlign: "right",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          handleOpenModal(
                            <AccountEditPage
                              handleCancel={handleCloseModal}
                              accountId={options.value}
                            />
                          );
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </li>
                );
              },
            }}
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <FieldAsyncSelect
            name="des_bank_account_id"
            label="บัญชีปลายทาง"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath={`/api/cases/${caseId}/bank_accounts`}
            itemFormat={(row) => {
              return {
                value: row.id,
                label:
                  `${row.bank?.short_name} : ${row.number} : ${
                    row.name || ""
                  }` +
                  (row.type
                    ? `(${row.type?.type}${row.seq ? `:${row.seq}` : ""})`
                    : ""),
              };
            }}
            inputProps={{
              sx: { width: "100%" },
              PaperComponent: AddDesAccButtonPaper,
              renderOption: (props, options) => {
                return (
                  <li
                    key={options.value}
                    {...props}
                    style={{ position: "relative" }}
                  >
                    <Box sx={{ pr: 3 }}>
                      <Typography>{options.label}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "block",
                        position: "absolute",
                        right: 0,
                        pr: 1,
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleOpenModal(
                            <AccountEditPage
                              handleCancel={handleCloseModal}
                              accountId={options.value}
                            />
                          );
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </li>
                );
              },
            }}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <FieldDatePicker
            name="transfered_at_date"
            label="วันที่"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy",
              views: ["year", "month", "day"],
              allowSameDateSelection: true,
              disableFuture: true,
              mask: "__/__/____",
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <FieldTimePicker
            name="transfered_at_time"
            label="เวลา"
            required
            pickerProps={{
              inputFormat: "HH:mm:ss",
              views: ["hours", "minutes", "seconds"],
              mask: "__:__:__",
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <FieldInput
            name="amount"
            label="จำนวนเงิน"
            required
            inputType="number"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <Stack direction="row" spacing={1}>
            <SubmitBtn
              variant="contained"
              startIcon={<Save />}
              submitting={submitting}
              pristine={pristine}
            >
              บันทึก
            </SubmitBtn>
            <Button
              type="button"
              variant="outlined"
              disabled={submitting}
              onClick={() => {
              
              form.reset();

              form.resetFieldState('src_bank_account_id'); 
              form.resetFieldState('des_bank_account_id');
              form.resetFieldState('transfered_at_date');
              form.resetFieldState('transfered_at_time');
              form.resetFieldState('amount');
              

              }}
            >
              clear
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // const now = new Date();

  if (!errors.transfered_at_date && !errors.transfered_at_time) {
    const transfered_at = parseISO(
      `${format(parseISO(values.transfered_at_date), "yyyy-MM-dd")} ${format(
        parseISO(values.transfered_at_time),
        "HH:mm:ss"
      )}`
    );
    const now = new Date();
    if (isAfter(transfered_at, now)) {
      errors.transfered_at_date = errors.transfered_at_time =
        "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (
    values.src_bank_account_id &&
    values.des_bank_account_id &&
    values.src_bank_account_id === values.des_bank_account_id
  ) {
    errors.src_bank_account_id = "บัญชีเดียวกัน";
    errors.des_bank_account_id = "บัญชีเดียวกัน";
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  src_bank_account_id: Joi.number().messages({
    "any.only": "กรุณาระบุบัญชีต้นทาง",
    "any.required": "กรุณาระบุบัญชีต้นทาง",
  }).required(),
  des_bank_account_id: Joi.number().messages({
    "any.only": "กรุณาระบุบัญชีปลายทาง",
    "any.required": "กรุณาระบุบัญชีปลายทาง",
  }).required().required(),
  transfered_at_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .messages({
      "any.only": "กรุณาระบุวันที่",
      "any.required": "กรุณาระบุวันที่",
    }).required()
    .required(),
  transfered_at_time: Joi.date().messages({
    "any.only": "กรุณาระบุเวลา",
    "any.required": "กรุณาระบุเวลา",
  }).required(),
  amount: Joi.number().min(0).messages({
    "any.only": "กรุณาระบุจำนวนเงิน",
    "any.required": "กรุณาระบุจำนวนเงิน",
  }).required(),
};

const schema = Joi.object(validateRules)
