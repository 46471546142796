import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import { usersCreateExternal } from "../../../../ducks/setting";
import UserForm, { validate } from "./UserForm";

export default function UserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [statusUser, setStatusUser] = useState(true);

  const onSubmit = (formData) => {
    const objData = {
      ...formData,
      active: statusUser,
      status: statusUser,
      roles: [],
    };

    return dispatch(usersCreateExternal(objData))
      .then(() => {
        navigate("/settinguserExternal");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">เพิ่มข้อมูลผู้ใช้งานหน่วยงานภายนอก</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              component={UserForm}
              setStatus={setStatusUser}
              page="add"
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
