import {
  DocumentScanner,
  KeyboardArrowDown,
  SaveAlt,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useMemo } from "react";
import downloadIcons from "../../data/requestDownloadIcons";
import api from "../../apis";
import fileDownload from "js-file-download";
import { LoadingButton } from "@mui/lab";

export default function ReqDownloadListBtn({ links, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openActionMenu = Boolean(anchorEl);
  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };
  const [loading, setLoading] = React.useState(false);

  const showLinks = useMemo(
    () => links?.filter((l) => !l.link?.match(/^\/api\/responses\/\d+/)),
    [links]
  );

  if (!showLinks?.length) {
    return null;
  }

  return (
    <>
     {showLinks?.filter((item) => item?.link !== "")?.length > 0 && (
      <LoadingButton
      id="action-menu-button"
      endIcon={<KeyboardArrowDown />}
      startIcon={<SaveAlt />}
      aria-controls={openActionMenu ? "action-menu-button" : undefined}
      aria-haspopup="true"
      aria-expanded={openActionMenu ? "true" : undefined}
      variant="contained"
      color="primary"
      disableElevation
      onClick={(event) => {
        setAnchorEl(event.currentTarget);
      }}
      loading={loading}
      loadingPosition="start"
    >
      {children || "ดาวน์โหลดเอกสาร"}
    </LoadingButton>
     ) }
      
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleActionMenuClose}
        MenuListProps={{
          "aria-labelledby": "action-menu-button",
        }}
      >
        {showLinks?.filter((item) => item?.link !== "")?.map((act, index) => {
          const DIcon = downloadIcons[act.icon] || DocumentScanner;
          return (
            <MenuItem
              key={index}
              onClick={() => {
                if (!act.link) {
                  handleActionMenuClose();
                  return;
                }

                setLoading(true);
                api
                  .get(act.link, {
                    responseType: "arraybuffer",
                  })
                  .then((response) => {
                    const contentDisposition =
                      response.headers["content-disposition"];
                    const filename = /filename=.+/.exec(contentDisposition)
                      ? decodeURI(
                          /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                        )
                      : "download";
                    fileDownload(response.data, filename);
                  })
                  .finally(() => {
                    setLoading(false);
                  });

                handleActionMenuClose();
              }}
            >
              <ListItemIcon>
                <DIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{act.text || "unknown"}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
