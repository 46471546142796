import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../apis";
import letterTypes from "../../../data/selects/letterTypes";
import requestStatusSelect from "../../../data/selects/requestStatus";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";
import { useQuery } from "react-query";
import { useFormState } from "react-final-form";

export default function DataReportSearchForm({
  loading,
  onReset,
  handleSubmit,
  form,
  pristine,
  SetOrgNameLabel,
  SetConditionTypeNameLabel,
  SetOrgName,
  SetConditionTypeName,
  search,
}) {
  const report_static_request_data = useSelector(
    (state) => state.auth.user?.permissions?.report_static_request_data ?? []
  );
  const formState = useFormState();
  const didRequest = useRef(false);
  const [fetchInfo, SetFetchInfo] = useState(false);
  const [reportType, setReportType] = useState(1);

  const { data: requestTypeOptions } = useQuery(
    [
      "request_types",
      "/api/reports/request_types",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/reports/request_types").then((res) => {
        return res?.data?.map((row) => ({
          value: row.key,
          label: row.value,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const { data: reportTypeOptions } = useQuery(
    [
      "report_type",
      "/api/reports/report_type",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/reports/report_type").then((res) => {
        return res?.data?.map((row) => ({
          value: row.key,
          label: row.value,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const { data: orgCodeOptions, refetch: orgCodeRefetch } = useQuery(
    [
      "reportType",
      "/api/reports/org_code",
      { value: "code", label: "fullname" },
    ],
    () =>
      reportType &&
      api.get(`/api/reports/org/${reportType}`).then((res) => {
        if (reportType === 1) {
          return res?.data?.map((row) => ({
            value: row.ORG_CODE,
            label: row.ORG_ABBR,
          }));
        }
        return res?.data?.map((row) => ({
          value: row.id,
          label: `${row.name} (${row.short_name})`,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const { data: conditionTypeOptions } = useQuery(
    [
      "condition_data",
      "/api/reports/condition_data",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/reports/condition_data").then((res) => {
        return res?.data?.map((row) => ({
          value: row.key,
          label: row.value,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    setReportType(formState.values["report_type"]);
    orgCodeOptions &&
      SetOrgNameLabel(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
    conditionTypeOptions &&
      SetConditionTypeNameLabel(
        conditionTypeOptions.filter(
          (el) => el.value === formState.values["condition_type"]
        )[0]?.label
      );
  }, [formState.values]);

  useEffect(() => {
    if (reportType) {
      orgCodeRefetch();
      reportType === 2 && form.change("org_code", "all");
    }
  }, [reportType, orgCodeRefetch]);

  useEffect(() => {
    if (search) {
      form.change("org_code", search);
      SetOrgName(orgCodeOptions.filter((el) => el.value === search)[0]?.label);
      SetConditionTypeName(
        conditionTypeOptions.filter(
          (el) => el.value === formState.values["condition_type"]
        )[0]?.label
      );
      form.submit();
    }
  }, [search]);

  useEffect(() => {
    if (!didRequest.current && orgCodeOptions && conditionTypeOptions) {
      SetOrgName(
        orgCodeOptions.filter(
          (el) => el.value === formState.values["org_code"]
        )[0]?.label
      );
      SetConditionTypeName(
        conditionTypeOptions.filter(
          (el) => el.value === formState.values["condition_type"]
        )[0]?.label
      );
      didRequest.current = true;
    }
  }, [conditionTypeOptions, orgCodeOptions]);

  // {url}/api/reports/list?
  // start_date=2024-01-01&
  // end_date=2024-10-01&
  // report_type={id}&
  // org_code={org_code}&
  // condition_type={id}

  // report_type->ใช่ key จาก หน่วยงานที่ดูแล
  // org_code->ใช่ org code ของหน่วยงานตำรวจ,code ของ bank
  // condition_type->

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {report_static_request_data.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="request_type"
              label="ประเภทหมายคำร้อง"
              options={requestTypeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="report_type"
              label="เลือกหน่วยงานที่ต้องการแสดงผล"
              options={reportTypeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="org_code"
              label="หน่วยงาน"
              options={orgCodeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              disabled={!reportType}
              readOnly={!reportType}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <FieldAutoComplete
              name="condition_type"
              label="เลือกเงื่อนไขการนับข้อมูล"
              options={conditionTypeOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              inputProps={{
                disableClearable: true,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={2}
            >
              <FieldDatePicker
                name="start_date"
                label="ตั้งแต่วันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
              <FieldDatePicker
                name="end_date"
                label="ถึงวันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
            </Stack>
          </Grid>
          <Grid item md={12} lg={4}>
            {report_static_request_data.includes("search") && (
              <Stack direction="row" spacing={2} alignItems="center">
                <SubmitBtn
                  variant="contained"
                  startIcon={<Search />}
                  submitting={loading}
                  pristine={pristine}
                >
                  ค้นหา
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={loading || pristine}
                  onClick={(event) => {
                    form.reset();
                    SetOrgName(
                      orgCodeOptions.filter(
                        (el) =>
                          el.value === form.getFieldState("org_code").initial
                      )[0]?.label
                    );
                    SetConditionTypeName(
                      conditionTypeOptions.filter(
                        (el) =>
                          el.value ===
                          form.getFieldState("condition_type").initial
                      )[0]?.label
                    );
                    if (onReset) onReset(event);
                  }}
                >
                  Reset
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
