import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import PermissionForm, { validate } from "./PermissionForm";
import { format, startOfToday } from "date-fns";
import { useDispatch } from "react-redux";
import { permissionCreate } from "../../../../ducks/setting";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import api from "../../../../apis";

export default function PermissionAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [initData, setInitData] = useState({});

  const [permissionList, setPermissionList] = useState();
  const [status, setStatus] = useState("1");
  const [other, setOther] = useState({
    seeData: "all",
    roleDefault: false,
    priorityAgency: false,
    crossAgency: false,
  });

  const onSubmit = (formData) => {
    const dataForm = {
      permissionName: formData.name,
      permissionDetail: formData.discription,
      permissionList: permissionList,
      permissionStatus: status,
      permissionOther: other,
    };
    return dispatch(permissionCreate(dataForm))
      .then(() => {
        navigate("/settingpermission");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {
        setInitData({
          police_name: `${data.rank ? data.rank + " " : ""}${
            data.fname ? data.fname + " " : ""
          }${data.lname || ""}`,
          job_title: data.job_title,
          policestation_org_code: data.policestation_org_code,
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">เพิ่มสิทธิ์การใช้งาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <PermissionForm />
          )}
        </Paper>
      </Grid>
    </>
  );
}
