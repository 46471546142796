import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export default function AlertDialog({
  title,
  children,
  onClose,
  isHideDialogActions = false,
  ...props
}) {
  return (
    <Dialog
      {...props}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {typeof children === "string" ? (
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      {!isHideDialogActions && (
        <DialogActions>
          <Button onClick={onClose}>OK</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
