import React from "react";
import { Field } from "react-final-form";
import FieldComponent from "./FieldComponent";

const FormField = (props) => {
  return (
    <Field
      {...props}
      component={FieldComponent}
      // parse={(value) => {
      //   return value
      //     ? value.map((item) => {
      //         return { bank_account_id: item };
      //       })
      //     : [];
      // }}
      // format={(value) => {
      //   return value ? value.map((item) => item.bank_account_id) : [];
      // }}
    />
  );
};

export default FormField;
