import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Close, UploadFile } from "@mui/icons-material";

const FieldComponent = ({
  name,
  required = false,
  readOnly = false,
  resizeImage,
  shouldResizeImage = () => true,
  dropZoneProps = {},
  showFileSize = true,
  placeholder,
  accept = "image/jpeg, image/png,application/pdf,application/zip",
  input,
  meta,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = (dropFiles) => {
    input.onBlur();
    if (!dropFiles[0]) {
      return;
    }

    if (
      resizeImage &&
      dropFiles[0].type?.startsWith("image/") &&
      shouldResizeImage(dropFiles[0])
    ) {
      setLoading(true);

      resizeImage(dropFiles[0])
        .then((resizeFile) => {
          input.onChange(resizeFile);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    input.onChange(dropFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept,
    multiple: false,
    ...dropZoneProps,
    disabled: loading || disabled,
  });



  const preview = useMemo(() => {
    if (loading) {
      return (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress />
            </Box>
            <CardContent>
              <Typography
                variant="subtitle2"
                component="div"
                textAlign={"center"}
                noWrap
              >
                Loading...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }

    if (!input.value) {
      return undefined;
    }

    const fileSizeMB = input.value?.size
      ? (input.value?.size / 1048576).toFixed(2)
      : null;

    return (
      <>
        <Card sx={{
         
    
        }} style={{ position: "relative" }}>
          <Box
            sx={{
              display: "block",
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                input.onChange(undefined);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
          {input.value.type?.startsWith("image/") ? (
            <CardMedia
              component="img"
              sx={{
                height: 140,
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              image={URL.createObjectURL(input.value)}
              alt="upload file"
            />
          ) : input.value ? (
            <CardMedia
              component="img"
              sx={{
                height: 140,
                width: "100%",
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              image={input?.value}
              alt="upload file"
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <UploadFile sx={{ fontSize: 80, mt: 3 }} />
            </Box>
          )}
          {input?.value?.name  && ( <CardContent>
            <Typography
              variant="subtitle2"
              component="div"
              textAlign={"center"}
              noWrap
            >
              {input?.value?.name || "upload file"}
            </Typography>
            {showFileSize && fileSizeMB && (
              <Typography
                variant="caption"
                component="div"
                textAlign={"center"}
                noWrap
              >
                ({fileSizeMB} MB)
              </Typography>
            )}
          </CardContent>)}
         
        </Card>
      </>
    );
  }, [input, loading, showFileSize]);

  useEffect(() => {
    console.log("input.value", input.value.type);
  }, [input.value]);
  return (
    <>
      <Stack direction="column" spacing={2}>
        {meta.invalid && meta.touched && (
          <Typography variant="caption" color="error.main">
            {meta.error || meta.submitError}
          </Typography>
        )}
        {preview}
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div
            className="text-wrap"
            style={{ width: "100%", textAlign: "center" }}
          >
            {placeholder || (
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.jpg,*.gif,*.png,*pdf,*zip) only
              </>
            )}
          </div>
        </div>
 
      </Stack>
    </>
  );
};

export default FieldComponent;



