import { Card, CardContent, Skeleton } from "@mui/material";
import React from "react";
const CardSkeleton = () => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "90px", // Adjust height
          marginTop: "10px",
        }}
      >
        <CardContent>
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
        </CardContent>
      </Card>
    </>
  );
};

export default CardSkeleton;
