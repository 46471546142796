import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import { hr03RequestActiveCreate } from "../../../../ducks/hr03List";
import Hr03RequestActiveForm from "./Hr03RequestActiveForm";
import { validate } from "./Hr03RequestActiveForm";

export default function UserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [idFromHr03, setIdFromHr03] = useState(null);

  const {
    data: bankOptions,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks?type=bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks?type=bank").then((res) =>
        res?.data?.rows?.map((row) => ({
          id: row.id,
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  const onSubmit = (data) => {
    const objData = { ...data };

    if (objData.type === 1 && !objData?.bankcode) {
      return {
        [FORM_ERROR]: "กรุณาตรวจสอบ เลข HR03",
      };
    }

    let bank_id = bankOptions.find((row) => row.value === objData.bank_id);

    const formData = new FormData();
    formData.append("type", objData.type);
    formData.append("hr03_id", idFromHr03);
    formData.append("note_interrogation", objData.note_interrogation);
    formData.append("book_message", objData.book_message);
    formData.append("card_scan", objData.card_scan);
    formData.append("infomation_reason", objData.infomation_reason);

    if (objData.type === 1) {
      formData.append("bank_id", bank_id?.id);
      formData.append("number", objData?.number);
      formData.append("card_id", objData?.card_id);
      formData.append("name", objData.name);
      formData.append("bank_name", objData.bank_name);
      formData.append("book_bank", objData.book_bank);
    } else {
      formData.append("card_id", objData.card_id);
      formData.append("name", objData.name);
      formData.append("banking", objData.banking);
    }

    return dispatch(hr03RequestActiveCreate(formData))
      .then(() => {
        navigate("/hr03/request-active");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          เพิ่มข้อมูลคำร้องขอเปิดใช้งานบัญชี HR03
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              page="add"
              idFromHr03={(id)=>{
                setIdFromHr03(id);
              }}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
