import { Button, Grid, Stack } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import _ from "lodash";
import validationMsg from "../../../../helpers/validationMsg";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import SubmitBtn from "../../../SubmitBtn";
import { Search, Download, Print, Add } from "@mui/icons-material";
import { isAfter, parseISO, startOfToday } from "date-fns";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import FieldInput from "../../../form-fields/FieldInput";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


export default function PermissionSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const qState = useSelector((state) => state.auth.user.permissions);

  const status = [
    { value: "1", label: "เปิดใช้งาน" },
    { value: "0", label: "ปิดใช้งาน" },
  ];
  return (
    <form onSubmit={handleSubmit} noValidate>
      {qState?.setting_permission?.includes("search") && ( <Grid container sx={{ mb: 3 }} spacing={2}>
        {showStatusField && (
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={status}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              // inputProps={{ sx: { fullWidth: true } }}
              // controlProp={{ sx: { fullWidth: true } }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
      </Grid>)
      }
     
      <Grid container spacing={2} mb={2}>
        {qState?.setting_permission?.includes("search") &&  ( <Grid item md={12} lg={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>)}
       
       {qState?.setting_permission?.includes("create")  && ( <Grid item md={12} lg={qState?.setting_permission?.includes("search") ? 8 : 12}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<Add />}
              color="success"
              component={Link}
              to="/permission/add"
            >
              เพิ่มข้อมูล
            </Button>
          </Stack>
        </Grid>)}
       
      </Grid>
      {/* <Stack
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <FieldAsyncSelect
          name="bank_id"
          label="ธนาคาร"
          controlProp={{
            sx: { width: { sm: "auto", md: "auto", lg: "30%" } },
          }}
          basePath={`/api/banks`}
          // filter={{ search: "xxxx"}}
          itemFormat={(row) => {
            return {
              value: row.id,
              label: `${row.name} (${row.short_name})`,
            };
          }}
        />
        <FieldAutoComplete
          name="type_id"
          label="ประเภทคำขอ"
          options={letterTypes}
          controlProp={{
            sx: { width: { sm: "auto", md: "auto", lg: "30%" } },
          }}
          //   inputProps={{ sx: { fullWidth: true } }}
        />
        <FieldDatePicker
          name="start_date"
          label="ตั้งแต่วันที่"
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
        />
        <FieldDatePicker
          name="end_date"
          label="ถึงวันที่"
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
        />
        <SubmitBtn
          variant="contained"
          startIcon={<Search />}
          submitting={loading}
          pristine={pristine && !modifiedSinceLastSubmit}
        >
          ค้นหา
        </SubmitBtn>
        <Button
          variant="outlined"
          disabled={loading}
          onClick={(event) => {
            form.reset();
            if (onReset) onReset(event);
          }}
        >
          Reset
        </Button>
      </Stack> */}
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
