import { Check } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Joi from "joi";
import React from "react";
import { Form } from "react-final-form";
import SubmitBtn from "../../../SubmitBtn";
import FieldDatePicker from "../../FieldDatePicker";
import validationMsg from "../../../../helpers/validationMsg";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import { isAfter, parseISO, startOfToday } from "date-fns";
import { defaultJoiDates } from "../../../../helpers/dateHelper";

export default function DateRangeForm({ onSubmit, handleCloseModal, header }) {
  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        ระบุช่วงวันในการขอข้อมูล
      </Typography>
      <Typography variant="subtitle1" color="info.main" gutterBottom>
        {header}
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form validate={validate} onSubmit={onSubmit}>
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  {submitFailed && (error || submitError) && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ width: "auto" }}>
                        {error || submitError}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <FieldDatePicker
                      name="from_date"
                      label="ตั้งแต่วันที่"
                      required
                      pickerProps={{
                        inputFormat: "dd/MM/yyyy",
                        disableFuture: true,
                        openTo: "year",
                        views: ["year", "month", "day"],
                        allowSameDateSelection: true,
                      }}
                      // readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldDatePicker
                      name="to_date"
                      label="ถึงวันที่"
                      required
                      pickerProps={{
                        inputFormat: "dd/MM/yyyy",
                        disableFuture: true,
                        openTo: "year",
                        views: ["year", "month", "day"],
                        allowSameDateSelection: true,
                      }}
                      // readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item md={12}>
                    <Stack direction="row" spacing={2}>
                      <SubmitBtn
                        variant="contained"
                        startIcon={<Check />}
                        submitting={submitting}
                        pristine={pristine}
                      >
                        ตกลง
                      </SubmitBtn>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={submitting}
                        onClick={() => {
                          handleCloseModal();
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Paper>
    </>
  );
}

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    !errors.from_date &&
    !errors.to_date &&
    isAfter(parseISO(values.from_date), parseISO(values.to_date))
  ) {
    errors.from_date = "ไม่ถูกต้อง";
    errors.to_date = "ไม่ถูกต้อง";
  }

  return errors;
};

const validateRules = {
  from_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
  to_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
};

const schema = Joi.object(validateRules).error(validationMsg());
