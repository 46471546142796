import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  Link,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../apis";
import {
  fetchUsageReport,
  usageReportClearResult,
  usageReportFilter,
  usageReportLoading,
} from "../../../ducks/report";
import UsageReportSearchForm from "./UsageReportSearchForm";
import { grey } from "@mui/material/colors";

const colorGrey = grey[400];

export default function UsageReport() {
  const dispatch = useDispatch();
  const viewerOrgCode = useSelector(
    (state) => state.auth.user?.viewer_org_code
  );

  const initialValues = {
    page_size: 10,
    page: 0,
    // start_date: format(new Date(), "yyyy-MM-dd"),
    // end_date: format(new Date(), "yyyy-MM-dd"),
    org_code: viewerOrgCode,
  };

  const [formState, setFormState] = useState(initialValues);

  const fetchIdRef = React.useRef(0);
  const forceReloadRef = React.useRef(0);
  const didRequest = React.useRef(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [orgName, SetOrgName] = useState();
  const [conditionTypeName, SetConditionTypeName] = useState();

  const [orgNameLabel, SetOrgNameLabel] = useState();
  const [conditionTypeNameLabel, SetConditionTypeNameLabel] = useState();

  const [search, setSearch] = React.useState("");

  useEffect(() => {
    return () => {
      dispatch(usageReportClearResult());
      dispatch(usageReportFilter({}));
    };
  }, [dispatch]);

  const report_static_assign_case = useSelector(
    (state) => state.auth.user?.permissions?.report_static_assign_case ?? []
  );

  const data = useSelector((state) => state?.report?.usage_report);

  const loading = useSelector((state) => state.report.metaUsageReport?.loading);
  const filter = useSelector((state) => state.report.metaUsageReport?.filter);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  React.useEffect(() => {
    dispatch(usageReportLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(
      fetchUsageReport(
        didRequest.current ? filter : initialValues,
        fetchId,
        fetchIdRef
      )
    )
      .catch(({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.error(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(usageReportClearResult());
        dispatch(usageReportLoading(false));
      })
      .finally(() => {
        didRequest.current = true;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchFormSubmit = (data) => {
    setFormState(data);
    orgNameLabel && SetOrgName(orgNameLabel);
    conditionTypeNameLabel && SetConditionTypeName(conditionTypeNameLabel);
    setSearch("");
    dispatch(usageReportFilter(data));
  };

  const handleDownload = (exportType) => {
    setLoadingPDF(true);
    setPdfDownloading(true);
    api
      .get(`/api/member_report`, {
        params: {
          // start_date: formState.start_date,
          // end_date: formState.end_date,
          export: exportType,
          org_code: formState.org_code,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setPdfDownloading(false);
      });
  };

  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };

  const columns = [
    {
      field: "department",
      headerName: "หน่วยงาน",
      flex: 1,
      renderCell: ({ row }) => {
        return row.ORG_TYPE === "BH" || row.ORG_TYPE === "BK" ? (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setSearch(row.ORG_CODE);
            }}
          >
            {row.department}
          </Link>
        ) : (
          <Typography variant="p">{row.department}</Typography>
        );
      },
    },
    {
      field: "total_user",
      headerName: "ตร. ที่สมัครเข้าใช้งานระบบ",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "bg-gray-200 hover:bg-gray-100",
      cellClassName: "bg-gray-200 hover:bg-gray-100",
    },
  ];

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">รายงานสถิติการใช้งานระบบ</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            onSubmit={onSearchFormSubmit}
            component={UsageReportSearchForm}
            loading={loading}
            onReset={() => {
              dispatch(usageReportFilter(initialValues));
              setSearch("");
            }}
            initialValues={initialValues}
            SetOrgNameLabel={SetOrgNameLabel}
            SetConditionTypeNameLabel={SetConditionTypeNameLabel}
            SetOrgName={SetOrgName}
            SetConditionTypeName={SetConditionTypeName}
            search={search}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: 700,
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            mb={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item md={9}>
              <Typography>
                สถิติการใช้งานระบบ {orgName ? orgName : "สำนักงานตำรวจแห่งชาติ"}
              </Typography>
            </Grid>
            <Grid item md={3}>
              {report_static_assign_case.includes("download") && (
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    startIcon={<Download />}
                    loadingPosition="start"
                    loading={pdfDownloading}
                    disabled={loadingPDF}
                    aria-controls={openDowload ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDowload ? "true" : undefined}
                    onClick={handleClickDowload}
                  >
                    ดาวน์โหลด
                  </LoadingButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElDowload}
                    open={openDowload}
                    onClose={handleCloseDownload}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleDownload("csv");
                      }}
                    >
                      CSV
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Grid>
          </Grid>
          <DataGrid
            disableColumnMenu
            getRowId={(row) => row.ORG_CODE}
            columns={columns}
            rows={data?.rows || []}
            rowCount={data?.total || 0}
            loading={loading}
            // rowsPerPageOptions={[10, 20]}
            pagination
            // {...rowsState}
            paginationMode="server"
          />
        </Paper>
      </Grid>
    </>
  );
}
