const countries = [
  { label: "ไทย(Thailand)", value: "TH" },
  
  { label: "อัฟกานิสถาน(Afghanistan)", value: "AF" },
  { label: "หมู่เกาะโอลันด์(Åland Islands)", value: "AX" },
  { label: "แอลเบเนีย(Albania)", value: "AL" },
  { label: "แอลจีเรีย(Algeria)", value: "DZ" },
  { label: "อเมริกันซามัว(American Samoa)", value: "AS" },
  { label: "อันดอร์รา(Andorra)", value: "AD" },
  { label: "แองโกลา(Angola)", value: "AO" },
  { label: "แองกวิลลา(Anguilla)", value: "AI" },
  { label: "แอนตาร์กติกา(Antarctica)", value: "AQ" },
  { label: "แอนทีกาและบาร์บิวดา(Antigua and Barbuda)", value: "AG" },
  { label: "อาร์เจนตินา(Argentina)", value: "AR" },
  { label: "อาร์มีเนีย(Armenia)", value: "AM" },
  { label: "อารูบา(Aruba)", value: "AW" },
  { label: "ออสเตรเลีย(Australia)", value: "AU" },
  { label: "ออสเตรีย(Austria)", value: "AT" },
  { label: "อาเซอร์ไบจาน(Azerbaijan)", value: "AZ" },
  { label: "บาฮามาส(Bahamas)", value: "BS" },
  { label: "บาห์เรน(Bahrain)", value: "BH" },
  { label: "บังกลาเทศ(Bangladesh)", value: "BD" },
  { label: "บาร์เบโดส(Barbados)", value: "BB" },
  { label: "เบลารุส(Belarus)", value: "BY" },
  { label: "เบลเยียม(Belgium)", value: "BE" },
  { label: "เบลีซ(Belize)", value: "BZ" },
  { label: "เบนิน(Benin)", value: "BJ" },
  { label: "เบอร์มิวดา(Bermuda)", value: "BM" },
  { label: "ภูฏาน(Bhutan)", value: "BT" },
  { label: "โบลิเวีย(Bolivia (Plurinational State of))", value: "BO" },
  {
    label: "เนเธอร์แลนด์แอนทิลลีส(Bonaire, Sint Eustatius and Saba)",
    value: "BQ",
  },
  { label: "บอสเนียและเฮอร์เซโกวีนา(Bosnia and Herzegovina)", value: "BA" },
  { label: "บอตสวานา(Botswana)", value: "BW" },
  { label: "เกาะบูเว(Bouvet Island)", value: "BV" },
  { label: "บราซิล(Brazil)", value: "BR" },
  {
    label: "บริติชอินเดียนโอเชียนเทร์ริทอรี(British Indian Ocean Territory)",
    value: "IO",
  },
  { label: "บรูไน(Brunei Darussalam)", value: "BN" },
  { label: "บัลแกเรีย(Bulgaria)", value: "BG" },
  { label: "บูร์กินาฟาโซ(Burkina Faso)", value: "BF" },
  { label: "บุรุนดี(Burundi)", value: "BI" },
  { label: "กาบูเวร์ดี(Cabo Verde)", value: "CV" },
  { label: "กัมพูชา(Cambodia)", value: "KH" },
  { label: "แคเมอรูน(Cameroon)", value: "CM" },
  { label: "แคนาดา(Canada)", value: "CA" },
  { label: "หมู่เกาะเคย์แมน(Cayman Islands)", value: "KY" },
  { label: "สาธารณรัฐแอฟริกากลาง(Central African Republic)", value: "CF" },
  { label: "ชาด(Chad)", value: "TD" },
  { label: "ชิลี(Chile)", value: "CL" },
  { label: "จีน(China)", value: "CN" },
  { label: "เกาะคริสต์มาส(Christmas Island)", value: "CX" },
  { label: "หมู่เกาะโคโคส(Cocos (Keeling) Islands)", value: "CC" },
  { label: "โคลอมเบีย(Colombia)", value: "CO" },
  { label: "คอโมโรส(Comoros)", value: "KM" },
  { label: "สาธารณรัฐคองโก(Congo)", value: "CG" },
  {
    label: "สาธารณรัฐประชาธิปไตยคองโก(Congo (Democratic Republic of the))",
    value: "CD",
  },
  { label: "หมู่เกาะคุก(Cook Islands)", value: "CK" },
  { label: "คอสตาริกา(Costa Rica)", value: "CR" },
  { label: "โกตดิวัวร์(Côte d'Ivoire)", value: "CI" },
  { label: "โครเอเชีย(Croatia)", value: "HR" },
  { label: "คิวบา(Cuba)", value: "CU" },
  { label: "กูราเซา(Curaçao)", value: "CW" },
  { label: "ไซปรัส(Cyprus)", value: "CY" },
  { label: "เช็กเกีย(Czechia)", value: "CZ" },
  { label: "เดนมาร์ก(Denmark)", value: "DK" },
  { label: "จิบูตี(Djibouti)", value: "DJ" },
  { label: "ดอมินีกา(Dominica)", value: "DM" },
  { label: "สาธารณรัฐโดมินิกัน(Dominican Republic)", value: "DO" },
  { label: "เอกวาดอร์(Ecuador)", value: "EC" },
  { label: "อียิปต์(Egypt)", value: "EG" },
  { label: "เอลซัลวาดอร์(El Salvador)", value: "SV" },
  { label: "อิเควทอเรียลกินี(Equatorial Guinea)", value: "GQ" },
  { label: "เอริเทรีย(Eritrea)", value: "ER" },
  { label: "เอสโตเนีย(Estonia)", value: "EE" },
  { label: "เอสวาตีนี(Eswatini)", value: "SZ" },
  { label: "เอธิโอเปีย(Ethiopia)", value: "ET" },
  { label: "หมู่เกาะฟอล์กแลนด์(Falkland Islands (Malvinas))", value: "FK" },
  { label: "หมู่เกาะแฟโร(Faroe Islands)", value: "FO" },
  { label: "ฟีจี(Fiji)", value: "FJ" },
  { label: "ฟินแลนด์(Finland)", value: "FI" },
  { label: "ฝรั่งเศส(France)", value: "FR" },
  { label: "เฟรนช์เกียนา(French Guiana)", value: "GF" },
  { label: "เฟรนช์พอลินีเชีย(French Polynesia)", value: "PF" },
  {
    label: "เฟรนช์เซาเทิร์นและแอนตาร์กติกแลนดส์(French Southern Territories)",
    value: "TF",
  },
  { label: "กาบอง(Gabon)", value: "GA" },
  { label: "แกมเบีย(Gambia)", value: "GM" },
  { label: "จอร์เจีย(Georgia)", value: "GE" },
  { label: "เยอรมนี(Germany)", value: "DE" },
  { label: "กานา(Ghana)", value: "GH" },
  { label: "ยิบรอลตาร์(Gibraltar)", value: "GI" },
  { label: "กรีซ(Greece)", value: "GR" },
  { label: "กรีนแลนด์(Greenland)", value: "GL" },
  { label: "กรีเนดา(Grenada)", value: "GD" },
  { label: "กัวเดอลุป(Guadeloupe)", value: "GP" },
  { label: "กวม(Guam)", value: "GU" },
  { label: "กัวเตมาลา(Guatemala)", value: "GT" },
  { label: "เกิร์นซีย์(Guernsey)", value: "GG" },
  { label: "กินี(Guinea)", value: "GN" },
  { label: "กินี-บิสเซา(Guinea-Bissau)", value: "GW" },
  { label: "กายอานา(Guyana)", value: "GY" },
  { label: "เฮติ(Haiti)", value: "HT" },
  {
    label: "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์(Heard Island and McDonald Islands)",
    value: "HM",
  },
  { label: "นครรัฐวาติกัน(Holy See)", value: "VA" },
  { label: "ฮอนดูรัส(Honduras)", value: "HN" },
  { label: "ฮ่องกง(Hong Kong)", value: "HK" },
  { label: "ฮังการี(Hungary)", value: "HU" },
  { label: "ไอซ์แลนด์(Iceland)", value: "IS" },
  { label: "อินเดีย(India)", value: "IN" },
  { label: "อินโดนีเซีย(Indonesia)", value: "ID" },
  { label: "อิหร่าน(Iran (Islamic Republic of))", value: "IR" },
  { label: "อิรัก(Iraq)", value: "IQ" },
  { label: "ไอร์แลนด์(Ireland)", value: "IE" },
  { label: "ไอล์ออฟแมน(Isle of Man)", value: "IM" },
  { label: "อิสราเอล(Israel)", value: "IL" },
  { label: "อิตาลี(Italy)", value: "IT" },
  { label: "จาเมกา(Jamaica)", value: "JM" },
  { label: "ญี่ปุ่น(Japan)", value: "JP" },
  { label: "เจอร์ซีย์(Jersey)", value: "JE" },
  { label: "จอร์แดน(Jordan)", value: "JO" },
  { label: "คาซัคสถาน(Kazakhstan)", value: "KZ" },
  { label: "เคนยา(Kenya)", value: "KE" },
  { label: "คิริบาส(Kiribati)", value: "KI" },
  {
    label: "เกาหลีเหนือ(Korea (Democratic People's Republic of))",
    value: "KP",
  },
  { label: "เกาหลีใต้(Korea (Republic of))", value: "KR" },
  { label: "คูเวต(Kuwait)", value: "KW" },
  { label: "คีร์กีซสถาน(Kyrgyzstan)", value: "KG" },
  { label: "ลาว(Lao People's Democratic Republic)", value: "LA" },
  { label: "ลัตเวีย(Latvia)", value: "LV" },
  { label: "เลบานอน(Lebanon)", value: "LB" },
  { label: "เลโซโท(Lesotho)", value: "LS" },
  { label: "ไลบีเรีย(Liberia)", value: "LR" },
  { label: "ลิเบีย(Libya)", value: "LY" },
  { label: "ลีชเทินชไตน์(Liechtenstein)", value: "LI" },
  { label: "ลิทัวเนีย(Lithuania)", value: "LT" },
  { label: "ลักเซมเบิร์ก(Luxembourg)", value: "LU" },
  { label: "มาเก๊า(Macao)", value: "MO" },
  { label: "มาดากัสการ์(Madagascar)", value: "MG" },
  { label: "มาลาวี(Malawi)", value: "MW" },
  { label: "มาเลเซีย(Malaysia)", value: "MY" },
  { label: "มัลดีฟส์(Maldives)", value: "MV" },
  { label: "มาลี(Mali)", value: "ML" },
  { label: "มอลตา(Malta)", value: "MT" },
  { label: "หมู่เกาะมาร์แชลล์(Marshall Islands)", value: "MH" },
  { label: "มาร์ตีนิก(Martinique)", value: "MQ" },
  { label: "มอริเตเนีย(Mauritania)", value: "MR" },
  { label: "มอริเชียส(Mauritius)", value: "MU" },
  { label: "มายอต(Mayotte)", value: "YT" },
  { label: "เม็กซิโก(Mexico)", value: "MX" },
  { label: "ไมโครนีเชีย(Micronesia (Federated States of))", value: "FM" },
  { label: "มอลโดวา(Moldova (Republic of))", value: "MD" },
  { label: "โมนาโก(Monaco)", value: "MC" },
  { label: "มองโกเลีย(Mongolia)", value: "MN" },
  { label: "มอนเตเนโกร(Montenegro)", value: "ME" },
  { label: "มอนต์เซอร์รัต(Montserrat)", value: "MS" },
  { label: "โมร็อกโก(Morocco)", value: "MA" },
  { label: "โมซัมบิก(Mozambique)", value: "MZ" },
  { label: "พม่า(Myanmar)", value: "MM" },
  { label: "นามิเบีย(Namibia)", value: "NA" },
  { label: "นาอูรู(Nauru)", value: "NR" },
  { label: "เนปาล(Nepal)", value: "NP" },
  { label: "เนเธอร์แลนด์(Netherlands)", value: "NL" },
  { label: "นิวแคลิโดเนีย(New Caledonia)", value: "NC" },
  { label: "นิวซีแลนด์(New Zealand)", value: "NZ" },
  { label: "นิการากัว(Nicaragua)", value: "NI" },
  { label: "ไนเจอร์(Niger)", value: "NE" },
  { label: "ไนจีเรีย(Nigeria)", value: "NG" },
  { label: "นีวเว(Niue)", value: "NU" },
  { label: "เกาะนอร์ฟอล์ก(Norfolk Island)", value: "NF" },
  { label: "มาซิโดเนียเหนือ(North Macedonia)", value: "MK" },
  {
    label: "หมู่เกาะนอร์เทิร์นมาเรียนา(Northern Mariana Islands)",
    value: "MP",
  },
  { label: "นอร์เวย์(Norway)", value: "NO" },
  { label: "โอมาน(Oman)", value: "OM" },
  { label: "ปากีสถาน(Pakistan)", value: "PK" },
  { label: "ปาเลา(Palau)", value: "PW" },
  { label: "ปาเลสไตน์(Palestine, State of)", value: "PS" },
  { label: "ปานามา(Panama)", value: "PA" },
  { label: "ปาปัวนิวกินี(Papua New Guinea)", value: "PG" },
  { label: "ปารากวัย(Paraguay)", value: "PY" },
  { label: "เปรู(Peru)", value: "PE" },
  { label: "ฟิลิปปินส์(Philippines)", value: "PH" },
  { label: "หมู่เกาะพิตแคร์น(Pitcairn)", value: "PN" },
  { label: "โปแลนด์(Poland)", value: "PL" },
  { label: "โปรตุเกส(Portugal)", value: "PT" },
  { label: "ปวยร์โตรีโก(Puerto Rico)", value: "PR" },
  { label: "กาตาร์(Qatar)", value: "QA" },
  { label: "เรอูว์นียง(Réunion)", value: "RE" },
  { label: "โรมาเนีย(Romania)", value: "RO" },
  { label: "รัสเซีย(Russian Federation)", value: "RU" },
  { label: "รวันดา(Rwanda)", value: "RW" },
  { label: "แซ็ง–บาร์เตเลมี(Saint Barthélemy)", value: "BL" },
  {
    label: "เซนต์เฮเลนา(Saint Helena, Ascension and Tristan da Cunha)",
    value: "SH",
  },
  { label: "เซนต์คิตส์และเนวิส(Saint Kitts and Nevis)", value: "KN" },
  { label: "เซนต์ลูเชีย(Saint Lucia)", value: "LC" },
  { label: "เซนต์มาร์ติน(Saint Martin (French part))", value: "MF" },
  { label: "แซงปีแยร์และมีเกอลง(Saint Pierre and Miquelon)", value: "PM" },
  {
    label: "เซนต์วินเซนต์และเกรนาดีนส์(Saint Vincent and the Grenadines)",
    value: "VC",
  },
  { label: "ซามัว(Samoa)", value: "WS" },
  { label: "ซานมารีโน(San Marino)", value: "SM" },
  { label: "เซาตูแมอีปริงซีป(Sao Tome and Principe)", value: "ST" },
  { label: "ซาอุดีอาระเบีย(Saudi Arabia)", value: "SA" },
  { label: "เซเนกัล(Senegal)", value: "SN" },
  { label: "เซอร์เบีย(Serbia)", value: "RS" },
  { label: "เซเชลส์(Seychelles)", value: "SC" },
  { label: "เซียร์ราลีโอน(Sierra Leone)", value: "SL" },
  { label: "สิงคโปร์(Singapore)", value: "SG" },
  { label: "ซินต์มาร์เติน(Sint Maarten (Dutch part))", value: "SX" },
  { label: "สโลวาเกีย(Slovakia)", value: "SK" },
  { label: "สโลวีเนีย(Slovenia)", value: "SI" },
  { label: "หมู่เกาะโซโลมอน(Solomon Islands)", value: "SB" },
  { label: "โซมาเลีย(Somalia)", value: "SO" },
  { label: "แอฟริกาใต้(South Africa)", value: "ZA" },
  {
    label:
      "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช(South Georgia and the South Sandwich Islands)",
    value: "GS",
  },
  { label: "ซูดานใต้(South Sudan)", value: "SS" },
  { label: "สเปน(Spain)", value: "ES" },
  { label: "ศรีลังกา(Sri Lanka)", value: "LK" },
  { label: "ซูดาน(Sudan)", value: "SD" },
  { label: "ซูรินาม(Suriname)", value: "SR" },
  { label: "‎สฟาลบาร์และยานไมเอน(Svalbard and Jan Mayen)", value: "SJ" },
  { label: "สวีเดน(Sweden)", value: "SE" },
  { label: "สวิตเซอร์แลนด์(Switzerland)", value: "CH" },
  { label: "ซีเรีย(Syrian Arab Republic)", value: "SY" },
  { label: "ไต้หวัน(Taiwan, Province of China)", value: "TW" },
  { label: "ทาจิกิสถาน(Tajikistan)", value: "TJ" },
  { label: "แทนซาเนีย(Tanzania, United Republic of)", value: "TZ" },
  { label: "ติมอร์-เลสเต(Timor-Leste)", value: "TL" },
  { label: "โตโก(Togo)", value: "TG" },
  { label: "โตเกเลา(Tokelau)", value: "TK" },
  { label: "ตองงา(Tonga)", value: "TO" },
  { label: "ตรินิแดดและโตเบโก(Trinidad and Tobago)", value: "TT" },
  { label: "ตูนิเซีย(Tunisia)", value: "TN" },
  { label: "ตุรกี(Turkey)", value: "TR" },
  { label: "เติร์กเมนิสถาน(Turkmenistan)", value: "TM" },
  { label: "หมู่เกาะเติกส์และเคคอส(Turks and Caicos Islands)", value: "TC" },
  { label: "ตูวาลู(Tuvalu)", value: "TV" },
  { label: "ยูกันดา(Uganda)", value: "UG" },
  { label: "ยูเครน(Ukraine)", value: "UA" },
  { label: "สหรัฐอาหรับเอมิเรตส์(United Arab Emirates)", value: "AE" },
  {
    label:
      "สหราชอาณาจักร(United Kingdom of Great Britain and Northern Ireland)",
    value: "GB",
  },
  { label: "สหรัฐ(United States of America)", value: "US" },
  {
    label:
      "เกาะเล็กรอบนอกของสหรัฐอเมริกา(United States Minor Outlying Islands)",
    value: "UM",
  },
  { label: "อุรุกวัย(Uruguay)", value: "UY" },
  { label: "อุซเบกิสถาน(Uzbekistan)", value: "UZ" },
  { label: "วานูวาตู(Vanuatu)", value: "VU" },
  { label: "เวเนซุเอลา(Venezuela (Bolivarian Republic of))", value: "VE" },
  { label: "เวียดนาม(Viet Nam)", value: "VN" },
  { label: "หมู่เกาะบริติชเวอร์จิน(Virgin Islands (British))", value: "VG" },
  { label: "หมู่เกาะเวอร์จินของสหรัฐ(Virgin Islands (U.S.))", value: "VI" },
  { label: "วอลิสและฟูตูนา(Wallis and Futuna)", value: "WF" },
  { label: "เวสเทิร์นสะฮารา(Western Sahara)", value: "EH" },
  { label: "เยเมน(Yemen)", value: "YE" },
  { label: "แซมเบีย(Zambia)", value: "ZM" },
  { label: "ซิมบับเว(Zimbabwe)", value: "ZW" },
];

export default countries;
