import hr03Status from "../hr03Status";
import _ from "lodash";

const excludes = ["rtp_process1", "rtp_process2","rtp_process4", "rtp_process3-1","rtp_process3-2","rtp_process3-3","rtp_process3-4"];

const status = _.keys(hr03Status)
  .filter((status) => !excludes.includes(status))
  .map((status) => {
    const item = hr03Status[status];

    return { label: item.label, value: status };
  })

export default status;
