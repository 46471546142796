import { Alert, Box, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { getChip } from "../../../data/requestStatus";
import {
  fetchRequestReport,
  requestReportClearResult,
  requestReportFilter,
  requestReportLoading,
} from "../../../ducks/report";
import AnalyticsCase from "./cards/statistics/AnalyticsCase";
import RequestReportSearchForm from "./RequestReportSearchForm";

export default function RequestReport() {
  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);
  const forceReloadRef = React.useRef(0);

  useEffect(() => {
    return () => {
      dispatch(requestReportClearResult());
      dispatch(requestReportFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state?.report?.request_report?.rows);
  const total = useSelector((state) => state?.report?.metaRequestReport?.total);
  const loading = useSelector(
    (state) => state.report.metaRequestReport.loading
  );
  const filter = useSelector((state) => state.report.metaRequestReport.filter);

  const statusNew = rows?.filter((v) => v.status === "new").length;
  const statusContact = rows?.filter(
    (v) => v.status === "sent_to_contact_person"
  ).length;
  const statusCheck = rows?.filter((v) => v.status === "checked").length;
  const statusBank = rows?.filter((v) => v.status === "sent_to_bank").length;
  const statusReject = rows?.filter((v) => v.status === "reject").length;
  const statusRecived = rows?.filter(
    (v) => v.status === "recived_from_bank"
  ).length;
  const statusEmail = rows?.filter((v) => v.status === "sent_to_pol").length;

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  React.useEffect(() => {
    dispatch(requestReportLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(
      fetchRequestReport(
        rowsState.pageSize,
        rowsState.page,
        filter,
        fetchId,
        fetchIdRef
      )
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(requestReportClearResult());
      dispatch(requestReportLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filter,
    rowsState.page,
    rowsState.pageSize,
    forceReloadRef.current,
  ]);

  const onSearchFormSubmit = (data) => {
    dispatch(requestReportFilter(data));
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      maxWidth: 100,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 180,
      maxWidth: 180,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: true,
      maxWidth: 100,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: true,
      minWidth: 320,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: true,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
    {
      field: "description",
      headerName: "รายละเอียด",
      sortable: true,
      minWidth: 300,
      maxWidth: 400,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">รายการคำขอ</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            onSubmit={onSearchFormSubmit}
            component={RequestReportSearchForm}
            loading={loading}
            onReset={() => dispatch(requestReportFilter({}))}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="All"
          count={total}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="New"
          count={statusNew}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Contact"
          count={statusContact}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Checked"
          count={statusCheck}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Bank"
          count={statusBank}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Reject"
          count={statusReject}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Success"
          count={statusRecived}
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={1.5}>
        <AnalyticsCase
          title="Email Success"
          count={statusEmail}
          percentage={59.3}
          color="error"
        />
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 700,
            width: "100%",
          }}
        >
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[10, 20]}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            onPageSizeChange={(pageSize) =>
              setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
            }
          />
        </Paper>
      </Grid>
    </>
  );
}
