import { Search } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import { isAfter, parseISO, startOfToday } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { defaultJoiDates } from "../../../helpers/dateHelper";
import validationMsg from "../../../helpers/validationMsg";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

export default function CaseAssignSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const assign_case_waiting = useSelector(
    (state) => state.auth.user.permissions.assign_case_waiting ?? []
  );
  const assign_case_assigned = useSelector(
    (state) => state.auth.user.permissions.assign_case_assigned ?? []
  );
  const status = [
    ...(assign_case_assigned.includes("list")
      ? [{ value: "assigned", label: "มอบหมายเเล้ว" }]
      : []),
    ...(assign_case_waiting.includes("list")
      ? [{ value: "waiting", label: "รอมอบหมาย" }]
      : []),
  ];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <FieldAutoComplete
            name="status"
            label="สถานะ"
            options={status}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/assign/policestation"
            itemFormat={(row) => {
              return { value: row.ORG_CODE, label: row.ORG_ABBR };
            }}
          />
        </Grid>

        <Grid item xs={12} xl={4} lg={4}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            spacing={2}
          >
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  const now = new Date();
  if (!errors.start_date && values.start_date) {
    const startDate = parseISO(values.start_date);
    if (isAfter(startDate, now)) {
      errors.start_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (!errors.end_date && values.end_date) {
    const endDate = parseISO(values.end_date);
    if (isAfter(endDate, now)) {
      errors.end_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (
    !errors.start_date &&
    !errors.end_date &&
    values.start_date &&
    values.end_date &&
    isAfter(parseISO(values.start_date), parseISO(values.end_date))
  ) {
    errors.start_date = "ไม่ถูกต้อง";
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  type_id: Joi.number().min(1).max(10).allow(null),
  bank_id: Joi.number().allow(null),
  start_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .allow(null),
  end_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
