import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import api from "../../../apis";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "80%",
  width: "70%",
};

function CaseAssignHistoryInvestigatingModal({ open, handleClose, caseID }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);



  const {
    data: item,
  } = useQuery(
    ["assignHistory", caseID],
    () =>
      api.get(`/api/assign/history/${caseID}`).then((res) => 
        res?.data?.map(item => ({
           id: item?.id,
           caseID: item?.case_id,
           history_date: item.history_date,
           fname: `${item.user?.rank ?? ""} ${item.user?.fname ?? ""} ${item.user?.lname ?? ""}`,
           job_title: item.user?.job_title,
           policestation: item.current_org,
          leaving_date: item.leaving_date
         }) )),
    { staleTime: 60 * 1000 }
  );

  const columns = [
    {
      field: "caseID",
      headerName: "Case ID",
      sortable: true,
      width: 70,
    },

    {
      field: "history_date",
      headerName: "วันที่ดูเเลเคส",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return '-';
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : '-';
      },
    },

    {
      field: "fname",
      headerName: "ชื่อ พนง.ดูเเลเคส",
      minWidth: 220,
     
    },
    {
      field: "job_title",
      headerName: "ตำเเหน่ง",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.job_title
          ? params.row.job_title
          : "-";
      },
    },
    {
      field: "policestation",
      headerName: "หน่วยงาน",
      minWidth: 150,
      renderCell: (params) => {
        return params.row.policestation?.ORG_ABBR ? params.row.policestation?.ORG_ABBR : "-";
      },
    },
    {
      field: "leaving_date",
      headerName: "วันที่ย้ายออก/เปลี่ยนหน่วยงาน",
      sortable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (!value) {
          return '-';
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : '-';
      },
    },
  ];

  

  

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{
            marginY:2
          }}>
          {/* <Typography id="modal-modal-title" variant="h6" component="div">
              ประวัติพนักงานสอบสวนที่ดูแลเคส (Case ID: {caseID})
            </Typography> */}
            <h2 className=" text-xl">  ประวัติพนักงานสอบสวนที่ดูแลเคส (Case ID: {caseID})</h2>

          </Box>

            <DataGrid
            
            disableColumnMenu
            columns={columns}
            rows={item?.length === 1 && item[0] === null ? [] : item ?? []}
            loading={false}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => {
              setPageSize(pageSize);
            }}
          
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
            <Button onClick={handleClose} variant="outlined">
              ปิด
              </Button>

            </Box>

         

          
        </Box>
      </Modal>
    </div>
  );
}

export default CaseAssignHistoryInvestigatingModal;
