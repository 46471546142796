import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";

export default function DescFormDialog({
  open, //mycustom
  onClose, //mycustom
  title, //mycustom
  content, //mycustom
  cfBtnProps = {}, //mycustom
  ccBtnProps = {}, //mycustom
  fieldProps = { name: "desc" },
  dialogProps = {},
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  const { label: confirmLabel, ...confirmBtnProps } = cfBtnProps;
  const { label: cancelLabel = "ยกเลิก", ...cancelBtnProps } = ccBtnProps;

  const { name: fieldName, ...inputProps } = fieldProps;

  const activeDialogProps = { fullWidth: true, maxWidth: "xs", ...dialogProps };
  return (
    <Dialog
      open={open ? true : false}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...activeDialogProps}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {typeof content === "string" || content instanceof String ? (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          ) : (
            content
          )}
          <FieldInput
            name={fieldName}
            controlProps={{ margin: "dense", fullWidth: true }}
            {...inputProps}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            {...cancelBtnProps}
            onClick={onClose}
            disabled={submitting}
          >
            {cancelLabel}
          </Button>
          <SubmitBtn
            variant="contained"
            submitting={submitting}
            pristine={pristine}
            startIcon={<CheckCircle />}
            {...confirmBtnProps}
          >
            {confirmLabel}
          </SubmitBtn>
        </DialogActions>
      </form>
    </Dialog>
  );
}
