import {
  BackupTable,
  Download,
  Pending,
  PictureAsPdf,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { compareDesc, format, isValid, parseISO } from "date-fns";
import fileDownload from "js-file-download";
import React, { useMemo, useState } from "react";
import api from "../../apis";

export default function ResponseList({ items = [] }) {
  const [pageSize, setPageSize] = useState(5);
  const rows = useMemo(
    () =>
      items
        .sort((a, b) =>
          compareDesc(
            parseISO(a.response?.created_at || ""),
            parseISO(b.response?.created_at || "")
          )
        )
        .map((item, index) => ({ ...item, id: index })),
    [items]
  );
  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        if (!params.row.link) {
          return undefined;
        }

        if (
          params.row.link?.match(/^\/api\/responses\/\d+\/tp04_download\/\d+$/)
        ) {
          return (
            <Stack
              spacing={1}
              direction="row"
              justifyContent="left"
              sx={{ width: "100%" }}
            >
              <Tp04DownloadButton link={params.row.link} />
            </Stack>
          );
        }

        return (
          <Stack
            spacing={1}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            <DownloadButton link={params.row.link} />
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "ชื่อ",
      sortable: false,
      width: 400,
      valueGetter: (params) => {
        return params.row.response?.response_note;
      },
    },
    {
      field: "recived_at",
      headerName: "วันที่ได้รับ",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.response?.recived_at) {
          return undefined;
        }

        const date = parseISO(params.row.response?.recived_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ upload",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.response?.created_at) {
          return undefined;
        }

        const date = parseISO(params.row.response?.created_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];
  return (
    <DataGrid
      columns={columns}
      disableColumnMenu
      rows={rows}
      rowsPerPageOptions={[5, 10]}
      pageSize={pageSize}
      // pagination
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      autoHeight
    />
  );
}

const DownloadButton = ({ link }) => {
  const [loading, setLoading] = useState(false);

  return (
    <IconButton
      disabled={loading}
      type="button"
      variant="contained"
      size="small"
      onClick={() => {
        setLoading(true);
        api
          .get(link, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            const contentDisposition = response.headers["content-disposition"];
            const filename = /filename=.+/.exec(contentDisposition)
              ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
              : "download";
            fileDownload(response.data, filename);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      {loading ? <Pending /> : <Download color="primary" />}
    </IconButton>
  );
};

const Tp04ToolTip = ({ title, children, ...props }) => {
  return title ? (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const Tp04DownloadButton = ({ link }) => {
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openActionMenu = Boolean(anchorEl);
  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (dlLink) =>
    api
      .get(dlLink, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
          : "download";
        fileDownload(response.data, filename);
      });

  let noPdfLink = link;
  // Ensure existingPath ends with a slash
  if (!noPdfLink.endsWith("/")) {
    noPdfLink += "/"; // Add a slash if it's not already present
  }
  noPdfLink += "no_pdf";

  return (
    <>
      <Tp04ToolTip title={loading ? "โปรดรอ" : null} placement="top" arrow>
        <span>
          <IconButton
            disabled={loading}
            type="button"
            variant="contained"
            size="small"
            aria-controls={openActionMenu ? "action-menu-button" : undefined}
            aria-haspopup="true"
            aria-expanded={openActionMenu ? "true" : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            {loading ? <Pending /> : <Download color="primary" />}
          </IconButton>
        </span>
      </Tp04ToolTip>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleActionMenuClose}
        MenuListProps={{
          "aria-labelledby": "action-menu-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleActionMenuClose();
            setLoading(true);
            handleDownload(noPdfLink).finally(() => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <BackupTable fontSize="small" />
          </ListItemIcon>
          <ListItemText>ข้อมูล xlsx</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleActionMenuClose();
            setLoading(true);
            handleDownload(link).finally(() => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <PictureAsPdf fontSize="small" />
          </ListItemIcon>
          <ListItemText>ข้อมูล pdf (อาจใช้เวลานาน)</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
