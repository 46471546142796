import { Delete, List } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  policeChangeActive,
  policeDelete,
  policeFetch,
  policeLoading,
  policeSetFilter,
} from "../../../../ducks/masterDataList";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import { IOSSwitch } from "../../../form-fields/customs/Switch/IOSSwitch";
import AgencySearchForm from "./AgencySearchForm";

export default function SettingAgency() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchIdRef = useRef(0);

  const rows = useSelector((state) => state.masterData.police_station.rows);
  const total = useSelector((state) => state.masterData.metaPolice.total);
  const loading = useSelector((state) => state.masterData.metaPolice.loading);
  const reload = useSelector(
    (state) => state.masterData.metaPolice.forceReload
  );
  const [reloadData, setReloadData] = useState(0);
  const filter = useSelector((state) => state.masterData.metaPolice.filter);
  const qState = useSelector((state) => state.auth.user.permissions);
  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    setReloadData(reloadData + 1);
    dispatch(policeDelete(cfDel));
  };

  const columns = [
    {
      field: "actions",
      headerName: "จัดการ",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {  qState?.setting_police_station?.includes('edit') || qState?.setting_police_station?.includes('view') ? (<Tooltip title="แก้ไข">
              <IconButton
                type="button"
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  dispatch(policeSetFilter({}));
                  navigate(`/agency/edit/${params.row.ORG_CODE}`);
                }}
              >
                <List />
              </IconButton>
            </Tooltip>) : "" }
            
            {qState?.setting_police_station?.includes("delete") && ( <Tooltip title="ลบ">
              <IconButton
                type="button"
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  setCfDel(params.row.ORG_CODE);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>)}
           
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      width: 70,
    },
    {
      field: "status",
      headerName: "สถานะใช้งาน",
      sortable: false,
      align: "center",
      // minWidth: 180,
      // maxWidth: 180,
      renderCell: (params) => (
        <>
          <Grid sx={{ textAlign: "center" }}>
            <IOSSwitch
              checked={_.get(params, "row.status", false)}
              onClick={(e) => {
                const { checked } = e.target;
                const { ORG_CODE } = params.row;
                dispatch(policeChangeActive(ORG_CODE, checked));
              }}
            />
          </Grid>
        </>
      ),
    },
    {
      field: "ORG_CODE",
      headerName: "รหัสหน่วยงาน",
      sortable: false,
      width: 150,
    },
    {
      field: "ORG",
      headerName: "ชื่อหน่วยงาน",
      sortable: false,
      width: 400,
    },
    {
      field: "ORG_ABBR",
      headerName: "ชื่อย่อหน่วยงาน",
      sortable: false,
      width: 150,
    },
    {
      field: "ORG_TYPE",
      headerName: "ประเภท",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.ORG_TYPE === "BH") {
          return <Typography>บช.</Typography>;
        } else if (params.row.ORG_TYPE === "BK") {
          return <Typography>บก.</Typography>;
        } else if (params.row.ORG_TYPE === "KK") {
          return <Typography>กก.</Typography>;
        } else if (params.row.ORG_TYPE === "OTHER") {
          return <Typography>อื่นๆ</Typography>;
        } else {
          return <Typography>{params.row.ORG_TYPE}</Typography>;
        }
      },
    },
    {
      field: "BH_ORG",
      headerName: "บช",
      sortable: false,
      width: 150,
    },
    {
      field: "BK_ORG",
      headerName: "บก",
      sortable: false,
      width: 150,
    },
  ];
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const onSearchSubmit = (formValues) => {
    dispatch(policeSetFilter(formValues));
  };

  useEffect(() => {
    // tableRefetch();
    dispatch(policeLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(
      policeFetch(
        rowsState.pageSize,
        rowsState.page,
        filter,
        fetchId,
        fetchIdRef
      )
    ).catch(({ response }) => {
      console.log("error", response);
      dispatch(policeLoading(false));
    });
  }, [dispatch, filter, rowsState.page, rowsState.pageSize, reload,reloadData]);

  return (
    <>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ หน่วยงานตำรวจ(ID: {cfDel})
      </ConfirmDialog>

      <Grid item xs={12}>
        <Typography variant="h5">ข้อมูลหน่วยงานตำรวจ</Typography>
        <p>Master Data หน่วยงานสำหรับใช้งานร่วมกันภายในองค์กร</p>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          
            <Form
              onSubmit={onSearchSubmit}
              loading={false}
              onReset={() => dispatch(policeSetFilter({}))}
              showStatusField={true}
              component={AgencySearchForm}
              page={rowsState.page}
              pageSize={rowsState.pageSize}

            />
          

          <DataGrid
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
            onPageSizeChange={(pageSize) =>
              setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
            }
          />
        </Paper>
      </Grid>
    </>
  );
}
