import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import UserForm, { mapToInit, validateEdit } from "./UserForm";

export default function UserEditPage() {
  const params = useParams();
  const itemId = parseInt(params.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [statusUser, setStatusUser] = useState("1");
  const [dataPermission, setDataPermission] = useState([]);
  const [item, setItem] = useState({});
  const [modalError, setModalError] = useState(false);

  const callAPiGetInfoUser = useCallback(async (id) => {
    try {
      const res = await api.get(`/api/external/users/${id}`);
      const resBank = await api.get(`/api/banks/${res?.data?.external_org}`);
      const resExternalOrgType = await api.get(`/api/externaltype`);
      const external_org_type = resExternalOrgType?.data?.filter(
        (item) => item.value === res?.data?.external_org_type
      );

      const data = {
        ...res?.data,
        external_org: resBank?.data.id,
        external_org_type: external_org_type[0].value,
      };
      setItem(data);
    } catch (error) {
      setModalError(true);
    }
  }, []);

  useEffect(() => {
    if (itemId) {
      callAPiGetInfoUser(itemId);
    }
  }, [callAPiGetInfoUser, itemId]);

  const onSubmit = async (formValues, form) => {
    // // console.log(formValues);

    delete formValues.status;
    const objData = {
      ...formValues,
      status: JSON.parse(statusUser),
      active: JSON.parse(statusUser),
    };

    try {
      await api.put(`/api/external/users/${itemId}`, objData);
      queryClient.invalidateQueries(["settinguserExternal", itemId]);
      navigate(`/settinguserExternal`);
      toast.success("แก้ไขบัญชีผู้ใช้งานหน่วยงานภายนอกสำเร็จ");
      form.reset({});
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  const renderContent = () => {
    // if (isLoading)
    //   return (
    //     <>
    //       <Skeleton animation="wave" />
    //       <Skeleton animation="wave" />
    //       <Skeleton animation="wave" />
    //     </>
    //   );

    // if (isError)
    //   return (
    //     <Alert severity="error">
    //       <AlertTitle>Error</AlertTitle>
    //       เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
    //     </Alert>
    //   );

    return (
      <Form
        validate={validateEdit}
        onSubmit={onSubmit}
        setStatus={setStatusUser}
        initialValues={mapToInit(item)}
        component={UserForm}
        setDataPermission={setDataPermission}
        editMode
      />
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
        แก้ไขบัญชีผู้ใช้งานหน่วยงานภายนอก 
        </Typography>
        {/* <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {renderContent()}
        </Paper>
      </Grid>
      <Dialog
        open={modalError}
        onClose={() => setModalError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalError(false)}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
