import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import countryOptions from "../../../data/selects/countries";
import validationMsg from "../../../helpers/validationMsg";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import thaiIdValidator from "../../../helpers/ThaiIdValidator";

export default function PersonalForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  setNationality,
}) {
  const [type, setType] = useState(1); // 1 = คนไทย 2 = คนต่างชาติ
  const handleType = (e) => {
    const val = +e.target.value;
    setType(val);
    form.change("nationality", val);
  };

  useEffect(() => {
    setType(values.nationality);
  }, [values]);
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Field name="nationality" defaultValue={1}>
            {({ input, meta }) => (
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={input.value ? input.value : 1}
                  onChange={handleType}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="คนไทย"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="คนต่างชาติ"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="นามสกุล"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="card_id"
            label={type === 2 ? "เลขหนังสือเดินทาง" : "เลขบัตรประชาชน"}
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="country_code"
            label="สัญชาติ/ประเทศ"
            required
            options={countryOptions}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภท"
            options={bankAccountTypes}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            
            // disabled={loading}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FieldInput
            name="seq"
            label="ลำดับการรับเงิน"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputType="number"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12}>
          <FieldInput
            name="notes"
            label="หมายเหตุ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                  onClick={() => {
                    setNationality(type);
                  }}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  fname: Joi.string()
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
    card_id: Joi.string()
    .when("nationality", {
      is: 1, // เมื่อ nationality เท่ากับ 1
      then: Joi.string()
        .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
        .required()
        .messages({
          "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
        }),
      otherwise: Joi.string().allow(null),
    })
    .when("nationality", {
      is: 2, // เมื่อ nationality เท่ากับ 2
      then: Joi.string()
        .pattern(/^[A-Z0-9]{8,9}$/) // ใช้ pattern สำหรับเลขหนังสือเดินทาง (8-9 อักขระ)
        .required()
        .messages({
          "any.required": "กรุณาระบุเลขหนังสือเดินทาง",
          "string.pattern.base":
            "เลขหนังสือเดินทางต้องประกอบด้วยตัวอักษรและตัวเลข 8 ถึง 9 หลัก",
        }),
      otherwise: Joi.string().allow(null),
    }),
  country_code: Joi.string().required()
  .messages({
    "any.required": "กรุณาระบุสัญชาติ/ประเทศ",

  }).required(),
  type_id: Joi.number().allow(null),
  // type_id: Joi.number()
  // .messages({
  //   "any.required": "กรุณาระบุประเภทบัญชี",

  // }).required(),
  nationality: Joi.number().required(),
  notes: Joi.string().allow(null),
};

const schema = Joi.object(validateRules);

// const schema = Joi.object(validateRules).error(validationMsg());
