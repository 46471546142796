import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import React from "react";

export default function LineConnectDialog({ onClose, link, ...props }) {
  return (
    <Dialog
      // open={open}
      // onClose={handleClose}
      {...props}
      onClose={onClose}
      aria-labelledby="lineconnect-dialog-title"
      aria-describedby="lineconnect-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="lineconnect-dialog-title">
        ขั้นตอนการรับแจ้งเตือนทาง Line
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="lineconnect-dialog-description">
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/line-direction.jpeg`}
            alt="Logo"
            height="80%"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          component={Link}
          rel="noopener"
          href={link}
          autoFocus
        >
          ไปหน้าเชื่อมต่อ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
