import {
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import requestStatus, {
  masterRejectSteps,
  masterSteps,
} from "../../data/requestStatus";
import { makeStyles } from "@material-ui/core/styles";

const isStepFail = (step) => step === "reject";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    width: "100%",
    maxWidth: "100%",
    overflow: "scroll",
    backgroundColor: theme.palette.background.paper,
  },
  stepper: {
    minWidth: 500,
    width: "100%",
  },
}));

export default function RequestStepper({ status, note, ...props }) {
  const classes = useStyles();
  let activeStep = null;
  let steps = null;

  if (status === "reject") {
    steps = masterRejectSteps;
    activeStep = 2;
  } else {
    activeStep = masterSteps.findIndex((step) => step === status);
    if (activeStep >= 0) {
      steps = masterSteps;
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {steps && (
        <div className={classes.stepperContainer}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label, index) => {
              const labelProps = {};
              if (isStepFail(label)) {
                labelProps.error = true;
              }

              const reqStatus = requestStatus[label];

              let stepComponent = (
                <StepLabel {...labelProps}>{reqStatus.label}</StepLabel>
              );

              if (reqStatus.desc) {
                stepComponent = (
                  <Tooltip placement="top" arrow title={reqStatus.desc}>
                    {stepComponent}
                  </Tooltip>
                );
              }

              return <Step key={label}>{stepComponent}</Step>;
            })}
          </Stepper>
        </div>
      )}
      {/* {note && (
        <>
          <Divider />
          <Typography
            variant="subtitle1"
            color={isStepFail(status) && "error"}
            paragraph
            sx={{ overflowWrap: "break-word" }}
          >
            หมายเหตุ: {note}
          </Typography>
        </>
      )} */}
    </Stack>
  );
}
