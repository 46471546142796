import { combineReducers } from "redux";
import caseSearchReducer from "../ducks/caseSearch";
import caseReducer from "../ducks/case";
import caseAssignReducer from "../ducks/caseAssign";
import authReducer from "../ducks/auth";
import transactionSearchReducer from "../ducks/transactionSearch";
import caseRequestListReducer from "../ducks/caseRequestList";
import mgtRequestListReducer from "../ducks/mgtRequestList";
import accountListReducer from "../ducks/accountList";
import finalFormReducer from "../ducks/finalForm";
import appReducer from "../ducks/appData";
import sumRequestReducer from "../ducks/sumRequestList";
import noAccountListReducer from "../ducks/noAccountList";
import requestAccountsPickerFieldReducer from "../ducks/requestAccountsPickerField";
import requestAccountsPickerFieldEReducer from "../ducks/requestAccountsPickerFieldE";
import accountSearchReducer from "../ducks/accountSearch";
import hr03Reducer from "../ducks/hr03List";
import hr03MgtReducer from "../ducks/hr03MgtList";
import shReqReducer from "../ducks/searchRequestList";
import settingList from "../ducks/setting";
import auditList from "../ducks/auditList";
import masterData from "../ducks/masterDataList";
import personalList from "../ducks/personalList";
import tpoCaseReducer from "../ducks/tpoCase";
import externalReducer from "../ducks/external";
import reportReducer from "../ducks/report";

export default combineReducers({
  // form: formReducer,
  // searchIticket: iticketSearchReducer,
  // isOpenModal: modalReducer,
  // iupload: instantUploadReducer,
  app: appReducer,
  auth: authReducer,
  case: caseReducer,
  caseAssign: caseAssignReducer,
  caseSearch: caseSearchReducer,
  transactionSearch: transactionSearchReducer,
  caseRequestList: caseRequestListReducer,
  mgtRequestList: mgtRequestListReducer,
  accountList: accountListReducer,
  finalForm: finalFormReducer,
  sumRequestList: sumRequestReducer,
  noAccountList: noAccountListReducer,
  req18f: requestAccountsPickerFieldReducer,
  req18e: requestAccountsPickerFieldEReducer,
  accountSearch: accountSearchReducer,
  hr03: hr03Reducer,
  hr03Mgt: hr03MgtReducer,
  shReq: shReqReducer,
  setting: settingList,
  auditList: auditList,
  masterData: masterData,
  personalList: personalList,
  tpo: tpoCaseReducer,
  external: externalReducer,
  report: reportReducer,
});
