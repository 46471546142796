import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import { uploadSuspiciousCreate } from "../../../ducks/uploadSuspicious";
import Hr03RequestActiveForm from "./UploadSuspiciousActiveForm";

export default function UserAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const itemId = parseInt(params.id);

  const [loading, setLoading] = useState(true);

  const onSubmit = (data) => {
    const objData = { ...data };

    const formData = new FormData();
    formData.append("bank", objData.bank);
    formData.append("date", objData.date);
    formData.append("file", objData.file);
    formData.append("notes", objData.notes);
    formData.append("type", objData.type);
    formData.append(
      "type_note",
      objData.type_note === "undefined" ? "-" : objData.type_note
    );

    return dispatch(uploadSuspiciousCreate(formData))
      .then(() => {
        navigate("/UploadSuspicious");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Upload เหตุอันควรสงสัย</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              // validate={validate}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              page="edit"
              id={itemId}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
