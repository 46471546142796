import _ from "lodash";

// Actions
export const UPDATE_FORM_STATE = "finalForm/UPDATE_FORM_STATE";
const CLEAR_FORM_STATE = "finalForm/CLEAR_FORM_STATE";

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_STATE:
      return {
        ...state,
        [action.formName]: action.payload,
      };
    case CLEAR_FORM_STATE:
      return _.omit(state, action.formName);
    default:
      return state;
  }
}

// Action Creators
export const updateFormState = (formName, form, state) => ({
  type: UPDATE_FORM_STATE,
  formName,
  payload: { form, state },
});

export const clearFormState = (formName) => ({
  type: CLEAR_FORM_STATE,
  formName,
});

// Selectors
export const getFormState = (state, form) =>
  (state && state.finalForm && state.finalForm[form]) || {};
