const selects = [
  { label: "1.หลอกซื้อขายสินค้าหรือบริการ ที่ไม่เป็นขบวนการ", value: 1 },
  { label: "2.หลอกเป็นบุคคลอื่นเพื่อยืมเงิน", value: 2 },
  { label: "3.หลอกให้รักแล้วโอนเงิน (Romance Scam)", value: 3 },
  {
    label: "4.หลอกให้โอนเงินเพื่อรับรางวัล หรือเพื่อวัตถุประสงค์อื่นๆ",
    value: 4,
  },
  { label: "5.หลอกให้กู้เงิน", value: 5 },
  { label: "6.หลอกให้โอนเงินเพื่อทำงานหารายได้พิเศษ", value: 6 },
  { label: "7.ข่มขู่ทางโทรศัพท์ให้เกิดความกลัวแล้วหลอกให้โอนเงิน", value: 7 },
  {
    label:
      "8.กระทำต่อระบบหรือข้อมูลคอมพิวเตอร์โดยผิดกฎหมายเพื่อฉ้อโกง กรรโชก หรือรีดเอาทรัพย์",
    value: 8,
  },
  {
    label:
      "9.เข้ารหัสข้อมูลคอมพิวเตอร์ของผู้อื่นโดยมิชอบเพื่อกรรโชก หรือรีดเอาทรัพย์ (Ransomware)",
    value: 9,
  },
  {
    label: "10.หลอกให้ติดตั้งโปรแกรมเพื่อควบควมระบบในเครื่องโทรศัพท์",
    value: 10,
  },
  { label: "11.หลอกเกี่ยวกับสินทรัพย์ดิจิทัล", value: 11 },
  { label: "12.หลอกให้ลงทุนผ่านระบบคอมพิวเตอร์", value: 12 },
  { label: "13.หลอกซื้อขายสินค้าหรือบริการ ที่มีลักษณะเป็นขบวนการ", value: 13 },
  {
    label:
      "14.หลอกให้ลงทุนที่เป็นความผิดตาม พ.ร.ก.การกู้ยืมเงินที่เป็นการฉ้อโกงประชาชน พ.ศ.2527",
    value: 14,
  },
];

export default selects;
