import {
  Alert,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { differenceInYears, isValid, parse, startOfToday } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import api from "../../../../apis";
import countryOptions from "../../../../data/selects/countries";
import hr03SubCaseType from "../../../../data/selects/hr03SubCaseType";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import { getTextMask } from "../../../../helpers/imask";
import validationMsg from "../../../../helpers/validationMsg";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldCheckBox from "../../../form-fields/FieldCheckBox";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import FieldSingleFileDropZone from "../../../form-fields/FieldSingleFileDropZone";
import SignaturePreview from "../../requests/request-forms/SignaturePreview";
import thaiIdValidator from "../../../../helpers/ThaiIdValidator";
const accountNoMask = getTextMask({
  mask: /^\d{1,50}$/,
  unmask: true,
  overwrite: true,
});
const tpoCaseIdMask = getTextMask({
  mask: /^[0-9A-Z]{1,30}$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});
const cardIdMask = getTextMask({
  mask: "0000000000000",
  unmask: true,
  overwrite: true,
});
const passportMask = getTextMask({
  mask: /^[0-9A-Z]{1,30}$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});
const engNameMask = getTextMask({
  mask: /^[A-Z-]{1,30}$/,
  unmask: true,
  overwrite: true,
  prepare: function (str) {
    return str.toUpperCase();
  },
});

export default function AccountForm({
  readOnly = false, // mycustom
  // handleCancel, //mycustom
  editMode,
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  const { is_foreigner } = values;

  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    ["select", "/api/banks/list/bank", { value: "code", label: "fullname" }],
    () =>
      api.get("/api/banks/list/bank").then((res) =>
        res.data.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    if (readOnly) {
      return undefined;
    }

    if (values.hr_type === 2) {
      form.batch(() => {
        form.change("case_no", undefined);
        form.change("case_org_code", undefined);
      });
    }
  }, [values.hr_type, form, readOnly]);

  useEffect(() => {
    if (readOnly || pristine) {
      return undefined;
    }

    form.batch(() => {
      form.change("card_id", undefined);
      form.change("title", undefined);
      form.change("name", undefined);
      form.change("midname", undefined);
      form.change("surname", undefined);
      form.change("eng_name", undefined);
      form.change("eng_midname", undefined);
      form.change("eng_surname", undefined);
      form.change("nationality", undefined);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_foreigner, readOnly]);

  const dob =
    values.birth_date && parse(values.birth_date, "yyyy-MM-dd", new Date());

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider textAlign="center" sx={{ mt: 2 }}>
            ข้อมูลทั่วไป
          </Divider>
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldSelect
            name="hr_type"
            label="ประเภท"
            options={[
              {
                value: 1,
                label: "HR03-1",
              },
              {
                value: 2,
                label: "HR03-2",
              },
              {
                value: 3,
                label: "HR03-1(AOC)",
              },
            ]}
            readOnly={readOnly}
            required
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldInput
            name="tpo_case_id"
            label="caseId"
            required={values.hr_type === 2}
            controlProps={{ fullWidth: true, disabled: !values.hr_type }}
            readOnly={readOnly}
            inputProps={{
              placeholder: "หมายเลข case จาก thaipoliceonline.com",
              inputComponent: tpoCaseIdMask,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldDatePicker
            name="case_date"
            label="วันที่รับแจ้ง"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy",
              disableFuture: true,
              openTo: "year",
              views: ["year", "month", "day"],
              allowSameDateSelection: true,
            }}
            readOnly={readOnly}
          />
        </Grid>
        {values.hr_type === 1 && (
          <>
            <Grid item xs={12} md={4}>
              <FieldInput
                name="case_no"
                label="เลขคดี"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  placeholder: "เช่น 23/2566",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldAsyncAutoComplete
                name="case_org_code"
                label="หน่วยงานเจ้าของคดี"
                required
                controlProp={{ fullWidth: true }}
                readOnly={readOnly}
                basePath="/api/policestations"
                requestParams={{ page_size: undefined, page: undefined }}
                // itemFilter={(row) =>
                //   !row.ORG_CODE?.startsWith("0") &&
                //   !row.ORG_CODE?.startsWith("1")
                // }
                itemFormat={(row) => {
                  return { value: row.ORG_CODE, label: row.ORG_ABBR };
                }}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} lg={6}>
          <FieldSelect
            name="case_type"
            label="ประเภทความผิดมูลฐาน"
            options={[
              {
                value: 1,
                label: "ฉ้อโกงประชาชน",
              },
              {
                value: 2,
                label: "ฉ้อโกง(เป็นปกติธุระ)",
              },
            ]}
            readOnly={readOnly}
            required
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <FieldAutoComplete
            name="hr_sub_type"
            label="ลักษณะคดี"
            required
            controlProp={{ fullWidth: true }}
            inputProps={{ sx: { width: "100%" } }}
            options={hr03SubCaseType}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="note"
            label="รายละเอียดคดี"
            required
            controlProps={{ fullWidth: true }}
            inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="other"
            label="ชั้นตอนเกี่ยวกับคดี"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              placeholder: "รับเลขคดี/ชั้นอัยการ",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center" sx={{ mt: 2 }}>
            <Typography variant="body">
              ข้อมูลบัญชี&nbsp;
              <b style={{ color: "darkorange" }}>คนร้ายที่รับโอนเงิน</b>
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <FieldAutoComplete
            name="bankcode"
            label="ธนาคาร"
            required
            controlProp={{ fullWidth: true }}
            inputProps={{
              sx: { width: "100%" },
              loading: isBankOptionsLoading || bankOptionsError,
              loadingText: bankOptionsError ? "Error" : "Loading…",
            }}
            options={bankOptions}
            readOnly={readOnly}
          />
          {/* <FieldAsyncSelect
            name="bankcode"
            label="ธนาคาร"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath={`/api/banks`}
            // filter={{ search: "xxxx"}}
            itemFormat={(row) => {
              return {
                value: row.code,
                label: `${row.name} (${row.short_name})`,
              };
            }}
            inputProps={{ sx: { width: "100%" } }}
          /> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="accountno"
            label="หมายเลขบัญชี"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              inputComponent: accountNoMask,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldSelect
            name="accounttype"
            label="ประเภทบัญชี"
            options={[
              { value: "bank", label: "bank" },
              { value: "promtpay", label: "promtpay" },
              { value: "wallet", label: "wallet" },
            ]}
            readOnly={readOnly}
            required
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center" sx={{ mt: 2 }}>
            <Typography variant="body">
              ข้อมูลเจ้าของบัญชี&nbsp;
              <b style={{ color: "darkorange" }}>คนร้ายที่รับโอนเงิน</b>
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldInput
            name="card_id"
            label={is_foreigner ? "passport no." : "เลขบัตรประชาชน"}
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              placeholder: is_foreigner ? "ABC1234" : "123456789xxxx",
              inputComponent: is_foreigner ? passportMask : cardIdMask,
              endAdornment: readOnly ? undefined : (
                <FieldCheckBox
                  name="is_foreigner"
                  label={"ต่างด้าว" + (is_foreigner ? "" : "?")}
                  readOnly={readOnly}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={8} md={3}>
          <FieldDatePicker
            name="birth_date"
            label="วันเกิด(ค.ศ.)"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy",
              disableFuture: true,
              openTo: "year",
              views: ["year", "month", "day"],
              allowSameDateSelection: true,
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            label="อายุ"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">ปี</InputAdornment>
              ),
              readOnly: true,
            }}
            value={isValid(dob) ? differenceInYears(new Date(), dob) : "-"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FieldInput
            name="title"
            label="คำนำหน้า"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              placeholder: "เช่น นาย,นาง,นางสาว",
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldInput
            name="name"
            label={`ชื่อ${is_foreigner ? "(eng)" : ""}`}
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              inputComponent: is_foreigner ? engNameMask : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldInput
            name="midname"
            label={`ชื่อกลาง${is_foreigner ? "(eng)" : ""}`}
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              inputComponent: is_foreigner ? engNameMask : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FieldInput
            name="surname"
            label={`นามสกุล${is_foreigner ? "(eng)" : ""}`}
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              inputComponent: is_foreigner ? engNameMask : undefined,
            }}
          />
        </Grid>
        {is_foreigner ? (
          <Grid item xs={12} md={4}>
            <FieldAutoComplete
              name="nationality"
              label="สัญชาติ/ประเทศ"
              required
              options={countryOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              readOnly={readOnly}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              <FieldInput
                name="eng_name"
                label="ชื่อ(eng)"
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: engNameMask,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldInput
                name="eng_midname"
                label="ชื่อกลาง(eng)"
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: engNameMask,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldInput
                name="eng_surname"
                label="นามสกุล(eng)"
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
                inputProps={{
                  inputComponent: engNameMask,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider textAlign="center" sx={{ mt: 2 }}>
            ข้อมูลพนักงานสอบสวน
          </Divider>
        </Grid>
        <Grid item xs={12} md={2}>
          <FieldInput
            name="rank"
            label="ยศ"
            controlProps={{ fullWidth: true }}
            required
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FieldInput
            name="pol_name"
            label="ชื่อ"
            controlProps={{ fullWidth: true }}
            required
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FieldInput
            name="pol_surname"
            label="สกุล"
            controlProps={{ fullWidth: true }}
            required
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="pol_job_title"
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            required
            readOnly
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAsyncAutoComplete
            name="pol_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath="/api/policestations"
            requestParams={{ page_size: undefined, page: undefined }}
            // itemFilter={(row) =>
            //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
            // }
            itemFormat={(row) => {
              return { value: row.ORG_CODE, label: row.ORG_ABBR };
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="pol_tel"
            label="โทรศัพท์"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">ลายเซ็น</Typography>
          {readOnly || editMode ? (
            <SignaturePreview name="pol_signature" />
          ) : (
            <FieldSingleFileDropZone
              name="pol_signature"
              accept="image/jpeg, image/png"
              placeholder={
                <>
                  Drag 'n' drop some files here, or click to select files <br />
                  (*.jpg,*.gif,*.png) only
                </>
              }
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (!errors.amount && values.type_freeze === 2 && (values.amount ?? -1) < 0) {
    errors.amount = "ต้องการ";
  }

  return errors;
};

export const validateRules = () => ({
  hr_type: Joi.number().valid(1, 2).required(),
  case_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
  case_type: Joi.number().valid(1, 2).required(),
  hr_sub_type: Joi.number().min(1).max(14).required(),
  accountno: Joi.string().pattern(/^\d+$/).max(50).required(),
  accounttype: Joi.string().max(20).required(),
  bankcode: Joi.string().length(3).required(),
  is_foreigner: Joi.boolean().allow(null),
  card_id: Joi.when("is_foreigner", {
    is: Joi.valid(true).required(),
    then: Joi.string().pattern(/^[0-9A-Z]{1,30}$/),
    otherwise: Joi.string()
      .custom(thaiIdValidator) // เรียกใช้ function ตรวจสอบบัตรประชาชน
      .required()
      .messages({
        "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
      }),
  })
    .required()
    .messages({
      "any.required": "กรุณาระบุเลขบัตร",
    }),
  title: Joi.string().max(50).required(),
  name: Joi.string().max(100).required(),
  midname: Joi.string().max(100).allow(null),
  surname: Joi.string().max(100).required(),
  eng_name: Joi.string().max(100).allow(null),
  eng_midname: Joi.string().max(100).allow(null),
  eng_surname: Joi.string().max(100).allow(null),
  birth_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
  nationality: Joi.when("is_foreigner", {
    is: Joi.valid(true).required(),
    then: Joi.string().uppercase().length(2).required(),
    otherwise: Joi.forbidden(),
  }),
  note: Joi.string().max(1000).required(),
  other: Joi.string().max(200).required(),
  rank: Joi.string().max(50).required(),
  pol_name: Joi.string().max(100).required(),
  pol_surname: Joi.string().max(100).required(),
  pol_job_title: Joi.string().max(100).allow(null),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .required(),
  pol_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required(),
  pol_signature: Joi.any().required(),
  // tpo_case_id: Joi.string().alphanum().uppercase().max(30).required(),
  tpo_case_id: Joi.when("hr_type", {
    is: Joi.valid(1).required(),
    then: Joi.string().alphanum().uppercase().max(30).allow(null),
    otherwise: Joi.string().alphanum().uppercase().max(30).required(),
  }),
  case_no: Joi.when("hr_type", {
    is: Joi.valid(1).required(),
    then: Joi.string().max(10).required(),
    otherwise: Joi.forbidden(),
  }),
  case_org_code: Joi.when("hr_type", {
    is: Joi.valid(1).required(),
    then: Joi.string()
      .pattern(/^\d{5}$/)
      .required(),
    otherwise: Joi.forbidden(),
  }),
});

const schema = () => Joi.object(validateRules()).error(validationMsg());

export const mapToInit = (item) => {
  let initValue = _.pick(item, Object.keys(validateRules()));
  if (initValue.nationality === "TH") {
    initValue = _.omit(initValue, ["nationality"]);
  } else {
    initValue.is_foreigner = true;
    initValue = _.omit(initValue, ["eng_name", "eng_midname", "eng_surname"]);
  }

  return initValue;
};
