import {
  Alert,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  forceReload,
  setFilter,
} from "../../../ducks/searchRequestList";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { getChip } from "../../../data/requestStatus";
import { Refresh, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { format, isValid, parseISO } from "date-fns";
import SearchForm, { validate } from "./SearchForm";
import { Form } from "react-final-form";
import RequestView from "./RequestView";
import _ from "lodash";
// import RequestView from "./RequestView";

export default function RequestSearchPage() {
  const organize_request = useSelector(
    (state) => state.auth.user?.permissions?.organize_request ?? []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      // dispatch(setFilter({}));
    };
  }, [dispatch]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.shReq.data);
  const total = useSelector((state) => state.shReq.meta.total);
  const loading = useSelector((state) => state.shReq.meta.loading);
  const forceReload = useSelector((state) => state.shReq.meta.forceReload);
  const page = useSelector((state) => state.shReq.meta.page);
  const pageSize = useSelector((state) => state.shReq.meta.pageSize);
  const filter = useSelector((state) => state.shReq.meta.filter);

  const viewerOrgName = useSelector((state) => state.auth.user.viewerOrg?.ORG);
  // const [rowsState, setRowsState] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(forceReload());
        }
      }
      setOpenModal(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const renderAction = ({ row }) => {
    let modalView = null;

    // switch (row.status) {
    //   case "sent_to_contact_person":
    //     modalView = (
    //       <NewRequestView
    //         handleCancel={handleCloseModal}
    //         reqId={row.id}
    //         caseId={row.case_id}
    //       />
    //     );
    //     break;
    //   case "checked":
    //     modalView = (
    //       <CheckedRequestView
    //         handleCancel={handleCloseModal}
    //         reqId={row.id}
    //         caseId={row.case_id}
    //       />
    //     );
    //     break;
    //   default:
    //     modalView = (
    //       <RequestView
    //         handleCancel={handleCloseModal}
    //         reqId={row.id}
    //         caseId={row.case_id}
    //       />
    //     );
    // }

    modalView = (
      <RequestView
        handleCancel={handleCloseModal}
        reqId={row.id}
        caseId={row.case_id}
      />
    );
    // modalView = null;

    return (
      <IconButton
        type="button"
        variant="contained"
        size="small"
        onClick={() => {
          handleOpenModal(modalView);
        }}
      >
        <Visibility />
      </IconButton>
    );
  };

  const renderTitle = () => {
    return <Typography variant="h5">คำขอของหน่วยงาน</Typography>;
  };

  const columns = [
    ...(organize_request.includes("view")
      ? [
          {
            field: "actions",
            headerName: "actions",
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  {renderAction(params)}
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 280,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
    {
      field: "police_name",
      headerName: "ชื่อ พงส.",
      sortable: false,
      minWidth: 160,
      maxWidth: 180,
      valueGetter: ({ row }) => {
        const { user } = row;

        if (!user) {
          return null;
        }

        return `${user.rank ? user.rank + " " : ""}${
          user.fname ? user.fname + " " : ""
        }${user.lname || ""}`;
      },
    },
    {
      field: "user.policestation.ORG_ABBR",
      headerName: "ตำแหน่ง",
      sortable: false,
      minWidth: 150,
      maxWidth: 180,
      valueGetter: ({ row }) => {
        const { user } = row;

        if (!user) {
          return null;
        }

        return user?.policestation?.ORG_ABBR;
      },
    },
    {
      field: "description",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
  ];

  const onSearchSubmit = (data) => {
    let filter = { ...data };
    if (data.policestation_org_code) {
      const { org_type, org_code } = filter.policestation_org_code;
      filter = _.omit(filter, ["policestation_org_code"]);
      // filter.policestation_org_code = org_code;
      switch (org_type) {
        case "BH":
          filter.bh_code = org_code;
          break;
        case "BK":
          filter.bk_code = org_code;
          break;
        default:
          filter.kk_code = org_code;
      }
    }
    dispatch(setFilter(filter));
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        {renderTitle()}
        <Typography variant="subtitle1">{viewerOrgName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 1000,
            width: "100%",
          }}
        >
          {organize_request.includes("search") && (
            <Form
              validate={validate}
              onSubmit={onSearchSubmit}
              component={SearchForm}
              loading={loading}
              onReset={() => dispatch(setFilter({}))}
              // initialValues={filter}
              // showStatusField={reqStatus ? false : true}
            />
          )}
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.shReq.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(forceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
