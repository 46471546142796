import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../apis";
import hrStatusSelect from "../../../data/selects/hr03Status";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";

const statusSelect = hrStatusSelect.filter((item) => item.value !== "new");

export default function RequestReportSearchForm({
  loading,
  onReset,
  handleSubmit,
  form,
  pristine,
}) {
  const report_HR03_data = useSelector(
    (state) => state.auth.user?.permissions?.report_HR03_data ?? []
  );
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleDownload = (uri, setActionState) => {
    setLoadingPDF(true);
    setActionState(true);
    api
      .post(
        uri,
        {
          // new_status: "sent_to_bank",
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setActionState(false);
      });
  };
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {report_HR03_data.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <FieldAutoComplete
              name="hr_type"
              label="ประเภท"
              options={[
                {
                  value: 1,
                  label: "HR03-1",
                },
                {
                  value: 2,
                  label: "HR03-2",
                },
                {
                  value: 3,
                  label: "HR03-1(AOC)",
                },
              ]}
              required
              controlProp={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={statusSelect}
              controlProp={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={2}
            >
              <FieldDatePicker
                name="start_date"
                label="ตั้งแต่วันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
              <FieldDatePicker
                name="end_date"
                label="ถึงวันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          {report_HR03_data.includes("search") && (
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || pristine}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item md={12} lg={8}>
          {report_HR03_data.includes("download") && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <LoadingButton
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                loading={pdfDownloading}
                disabled={loadingPDF}
                onClick={() => handleDownload("/api/", setPdfDownloading)}
              >
                ดาวน์โหลด
              </LoadingButton>
            </Stack>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
