import { Login, Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { MdLockOpen } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  Link as RouterLink,
  useNavigate,
  useParams
} from "react-router-dom";
import api from "../../../apis";
import { authFetch, authLogin } from "../../../ducks/auth";
import AlertIconDialog from "../../dialogs/AlertIconDialog";
import WarningDialog from "../../dialogs/WarningDialog";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

const schema = Joi.object({
  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัสผ่านใหม่",
    })
    .required(),
  confirm_password: Joi.any()
    .valid(Joi.ref("password"))
    .messages({
      "any.only": "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น",
      "any.required": "กรุณาระบุรหัสผ่านใหม่",
    })
    .required(),
});

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};

export default function ForceSetPasswordPage() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState();
  const handleClose = () => processLogin();
  const handleChPass = () => {
    formRef.current.reset();
    setError(false);
    setSuccess(false);
  };

  const onSubmit = (formValues) => {
    const submitValues = { ...formValues, forgot_token: token };
    return api
      .post(`/api/forgot_password/set_password`, submitValues)
      .then((response) => {
        setSuccess(true);
        setEmail(response.data.email);
        setPassword(formValues.password);
      })
      .catch(({ response }) => {
        if (response.status === 422 || response.status === 403) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  const processLogin = () => {
    const user = { email: email, password: password };
    return dispatch(authLogin(user))
      .then((token) => {
        if (token.isVerify_2fa) {
          navigate("/twofactor", { state: { user, token } });
        } else {
          dispatch(authFetch());
          navigate("/me");
        }
      })
      .catch(({ response }) => {
        if (response.status === 429) {
          return {
            [FORM_ERROR]:
              "พบการเรียกใช้งานระบบเป็นจำนวนมาก กรุณากลับมาใช้งานใหม่ในอีก 5 นาที",
          };
        } else if (response.status === 401) {
          if (response.data.password_is_expire) {
            setToken(response.data.token);
            setError(
              "รหัสผ่านของท่านหมดอายุแล้ว กรุณาเปลี่ยนรหัสผ่านใหม่ เพื่อสามารถเข้าใช้งานได้ระบบได้อีกครั้ง"
            );
          } else {
            return {
              [FORM_ERROR]: "email หรือ password ไม่ถูกต้อง",
            };
          }
        } else if (response.status >= 400 && response.status < 500) {
          return {
            [FORM_ERROR]: "email หรือ password ไม่ถูกต้อง",
          };
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    if (params) {
      setToken(params.token);
    }
  }, [params]);

  return (
    <div className="bg-[#F5F5F5] h-screen grid justify-center items-center ">
      <WarningDialog error={error} onClose={handleChPass} />
      <AlertIconDialog
        open={success}
        onClose={handleClose}
        title="กำหนดรหัสผ่านสำเร็จ"
        maxWidth="sm"
        isHideDialogActions
      >
        <Grid container justifyContent={"center"}>
          <Button
            onClick={handleClose}
            variant="outlined"
            startIcon={<Login />}
          >
            เข้าสู่ระบบ
          </Button>
        </Grid>
      </AlertIconDialog>
      <div className="bg-white rounded-[32px] p-12 relative mx-4 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] max-w-[516px]">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 p-1">
          <MdLockOpen className="h-16 w-16 bg-[#1976D3] rounded-full p-3 text-white" />
        </div>
        <Typography className="text-center" component="h1" variant="h5">
          กำหนดรหัสผ่านใหม่
        </Typography>
        <Form validate={validate} onSubmit={onSubmit}>
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            formRef.current = form;
            return (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {submitFailed && (error || submitError) && (
                  <Alert severity="warning" sx={{ width: "100%" }}>
                    {error || submitError}
                  </Alert>
                )}
                <FieldInput
                  name="password"
                  label="รหัสผ่าน"
                  required
                  controlProps={{
                    fullWidth: true,
                    margin: "normal",
                    type: "password",
                  }}
                  inputType="password"
                  helperText={
                    <>
                      ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร{" "}
                      <br />
                      ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข
                      และตัวอักขระพิเศษได้
                    </>
                  }
                />
                <FieldInput
                  name="confirm_password"
                  label="ยืนยันรหัสผ่าน"
                  required
                  controlProps={{
                    fullWidth: true,
                    margin: "normal",
                    type: "password",
                  }}
                  inputType="password"
                  helperText={
                    "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น"
                  }
                />
                <SubmitBtn
                  variant="contained"
                  submitting={submitting}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<Save />}
                  size="large"
                >
                  เปลี่ยนรหัสผ่าน
                </SubmitBtn>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2">
                      กลับไปหน้าเข้าสู่ระบบ
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Form>
      </div>
    </div>
  );
}
