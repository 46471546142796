import {
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { policeCreate } from "../../../../ducks/masterDataList";
import WarningDialog from "../../../dialogs/WarningDialog";
import AgencyAddForm from "./AgencyAddForm";

export default function AgencyAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  const onSubmit = (values) => {
    if (values.ORG_TYPE === "BH") values.BH_CODE = values.ORG_CODE;

    dispatch(policeCreate(values))
      .then(() => {
        navigate("/settingagency");
      })
      .catch(({ response }) => {
        console.error("error", response);
        let str = response.data.message ?? ""
        let index = str.indexOf("(");
        if (index !== -1) {
          str = str.substring(0, index);
        }
        setError(str);
      });
  };

  return (
    <>
      <WarningDialog error={error} onClose={handleClose} />
      <Grid item xs={12}>
        <Typography variant="h5">เพิ่มข้อมูลหน่วยงาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            initialValues={{ ORG_TYPE: "BH", status: true }}
            onSubmit={onSubmit}
            component={AgencyAddForm}
          />
        </Paper>
      </Grid>
    </>
  );
}
