import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Box,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import TransactionForm, { validate } from "./TransactionForm";
import api from "../../../apis";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  clearResult,
  markLoading,
  search,
} from "../../../ducks/transactionSearch";
import { Delete, SaveAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { forceReload as reloadTransactionList } from "../../../ducks/transactionSearch";
import fileDownload from "js-file-download";
import { format, parseISO } from "date-fns";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransactionAdd() {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);
  const [pageSize, setPageSize] = React.useState(5);

  // delete record confirmation
  const [cfDel, SetCfDel] = useState(null);
  const [cfDelLoading, SetCfDelLoading] = useState(false);
  const handleCfDelClose = () => SetCfDel(null);
  const handleCfDelOk = () => {
    SetCfDelLoading(true);
    api
      .delete(`/api/cases/${caseId}/transactions/${cfDel.id}`)
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบสำเร็จ" });
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        dispatch(reloadTransactionList());
        // dispatch(reloadAccountList());
        handleCfDelClose();
        SetCfDelLoading(false);
      });
  };

  // edit transaction modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const onSubmit = (formValues, form) => {
    // combine transfer date and time field
    const transfered_at = `${format(
      parseISO(formValues.transfered_at_date),
      "yyyy-MM-dd"
    )} ${format(parseISO(formValues.transfered_at_time), "HH:mm:ss")}`;

    const submitValues = _.omit(formValues, [
      "transfered_at_date",
      "transfered_at_time",
    ]);
    submitValues.transfered_at = transfered_at;

    return api
      .post(`/api/cases/${caseId}/transactions`, submitValues)
      .then(() => {
        dispatch(reloadTransactionList());
        form.restart(
          _.omit(formValues, [
            // "src_bank_account_id",
            // "des_bank_account_id",
            "transfered_at_date",
            "transfered_at_time",
            "amount",
          ])
        );
        //form.restart(_.omit(formValues, ["transfered_at_time", "amount"]));
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, flex: 0.5 },
      {
        field: "src_bank",
        headerName: "ธนาคารต้นทาง",
        sortable: false,
        flex: 2,
        valueGetter: ({ row }) => {
          return (
            `${row.src_bank_account?.bank?.short_name} : ${
              row.src_bank_account?.number
            } : ${row.src_bank_account?.name || ""}` +
            (row.src_bank_account?.type
              ? `(${row.src_bank_account?.type?.type}${
                  row.src_bank_account?.seq
                    ? `:${row.src_bank_account?.seq}`
                    : ""
                })`
              : "")
          );
        },
      },
      {
        field: "des_bank",
        headerName: "ธนาคารปลายทาง",
        sortable: false,
        flex: 2,
        valueGetter: ({ row }) => {
          return (
            `${row.des_bank_account?.bank?.short_name} : ${
              row.des_bank_account?.number
            } : ${row.des_bank_account?.name || ""}` +
            (row.des_bank_account?.type
              ? `(${row.des_bank_account?.type?.type}${
                  row.des_bank_account?.seq
                    ? `:${row.des_bank_account?.seq}`
                    : ""
                })`
              : "")
          );
        },
      },
      {
        field: "amount",
        headerName: "จำนวนเงิน",
        sortable: false,
        flex: 1,
        valueGetter: (params) => {
          return params.row.amount;
        },
      },
      {
        field: "tranfered_at",
        headerName: "วัน เวลา โอน",
        sortable: false,
        flex: 1,
        valueGetter: (params) => {
          return params.row.transfered_at;
        },
      },
      ...(true //cases.includes("delete")
        ? [
            {
              field: "actions",
              headerName: "actions",
              sortable: false,
              flex: 1,
              renderCell: (params) => {
                return (
                  <Stack spacing={1} direction="row">
                    {/* <IconButton
                type="button"
                variant="contained"
                onClick={() => {
                  // handleOpenModal(
                  //   <AccountEditPage
                  //     handleCancel={handleCloseModal}
                  //     accountId={options.value}
                  //   />
                  // );
                }}
              >
                <Edit />
              </IconButton> */}
                    {/* {cases.includes("delete") && ( */}
                    <IconButton
                      type="button"
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        SetCfDel({
                          id: params.id,
                          msg: `ยืนยันการลบ Transaction (ID: ${params.id})`,
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                    {/* )} */}
                  </Stack>
                );
              },
            },
          ]
        : []),
    ];
  }, []);

  const rows = useSelector((state) => state.transactionSearch.data);
  // const total = useSelector((state) => state.transactionSearch.meta.total);
  const loading = useSelector((state) => state.transactionSearch.meta.loading);
  // const filter = useSelector((state) => state.transactionSearch.meta.filter);
  const forceReload = useSelector(
    (state) => state.transactionSearch.meta.forceReload
  );

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(markLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(search(0, 0, {}, fetchId, fetchIdRef, caseId)).catch(
      ({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.log(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(clearResult());
        dispatch(markLoading(false));
      }
    );
  }, [dispatch, caseId, forceReload]);

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Dialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการลบข้อมูล</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {cfDel?.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!cfDelLoading && <Button onClick={handleCfDelClose}>ยกเลิก</Button>}
          <LoadingButton
            onClick={handleCfDelOk}
            loading={cfDelLoading}
            loadingPosition="start"
            startIcon={<Delete />}
            variant="contained"
            autoFocus
            color="error"
          >
            ลบ
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Grid item container xs={12}>
        {/* <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography gutterBottom variant="h6">
            รายการเดินบัญชี (transactions)
          </Typography>
        </Grid> */}
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="center">เพิ่มรายการ เดินบัญชี</Divider>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={TransactionForm}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider>รายการ</Divider>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={rows}
            loading={loading}
            rowsPerPageOptions={[5, 10]}
            pageSize={pageSize}
            // pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            getRowHeight={() => 80}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const [downloading, setDownloading] = useState(false);
  const params = useParams();
  const caseId = parseInt(params.id);
  return (
    <GridToolbarContainer>
      <LoadingButton
        type="button"
        variant="text"
        startIcon={<SaveAlt />}
        loadingPosition="start"
        loading={downloading}
        onClick={() => {
          setDownloading(true);
          api
            .get(`/api/cases/${caseId}/transactions_export`, {
              responseType: "blob",
            })
            .then((response) => {
              let headerLine = response.headers["content-disposition"];
              let rawFilename = headerLine.split("filename=")[1];
              let filename = rawFilename.replace(/[^A-Za-z0-9.]/g, "");
              fileDownload(response.data, filename);
            })
            .finally(() => setDownloading(false));
        }}
      >
        xlsx
      </LoadingButton>
    </GridToolbarContainer>
  );
}
