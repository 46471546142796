// Function ตรวจสอบบัตรประชาชนไทย
const thaiIdValidator = (value, helpers) => {
    if (!/^\d{13}$/.test(value)) {
      return helpers.message("หมายเลขบัตรประชาชนต้องเป็นตัวเลข 13 หลัก");
    }
  
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(value[i]) * (13 - i);
    }
    
    const checkDigit = (11 - (sum % 11)) % 10;
    if (checkDigit !== parseInt(value[12])) {
      return helpers.message("หมายเลขบัตรประชาชนไม่ถูกต้อง");
    }
  
    return value; // ถ้าถูกต้อง ส่งค่าเดิมกลับไป
  };

  export default thaiIdValidator;