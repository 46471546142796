// material-ui
import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import api from "../../../apis";

export default function Monitor() {
  const ref = useRef();
  const [src, setSrc] = useState("");
  const [height, setHeight] = useState("0px");

  const { data } = useQuery(
    "accountHistory",
    () =>
      api
        .get(`/api/admin/systemsettings/system.monitoring`)
        .then((res) => res.data),
    { staleTime: 60 * 1000 }
  );
  useEffect(() => {
    if (data && data.value) {
      setSrc(data.value.url);
      setHeight(data.value.height);
    }
  }, [data]);
  const onLoad = () => {
    // setHeight(ref.current?.contentWindow?.document.body.scrollHeight + "px");
  };
  return (
    <>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12}>
          <Typography variant="h5">System Monitoring</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              // mt: 2,
              p: 1,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <iframe
              ref={ref}
              onLoad={onLoad}
              title="grafana_monitor"
              id="grafana_monitor"
              src={src}
              height={height}
              style={{ position: "relative", minHeight: 865, width: "100%" }}
              // scrolling="no"
              frameBorder="0"
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
