import { Grid, Paper, Tab, Typography } from "@mui/material";
import React from "react";
import CaseView from "../cases/CaseView";
import TransactionAdd from "./TransactionAdd";
import RequestListView from "../requests/RequestListView";
import AccountList from "../accounts/AccountList";
import PersonalList from "../personal/personalList";
import TelephoneList from "../personal/telephoneList";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function CaseMgtPage() {
  const [tabValue, setTabValue] = React.useState("acc");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">จัดการ Case</Typography>
      </Grid>
      <Grid item xs={12}>
        <CaseView />
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // minHeight: 500,
          }}
          id="tx-account"
        >
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label="รายการเดินบัญชี (Transactions)" value="tx" />
                <Tab label="บัญชีธนาคาร" value="acc" />
              </TabList>
            </Box>
            <TabPanel value="tx">
              <TransactionAdd />
            </TabPanel>
            <TabPanel value="acc">
              <AccountList />
            </TabPanel>
          </TabContext>
        </Paper>
      </Grid>
      {/* <Grid item xs={12}>
        <PersonalList />
      </Grid>
      <Grid item xs={12}>
        <TelephoneList />
      </Grid> */}
      <Grid item xs={12}>
        <RequestListView />
      </Grid>
    </>
  );
}
