import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import api from "../../../apis";

const FieldComponent = ({
  filter = {}, // my custom
  localFilter, // my custom
  label,
  required = false,
  readOnly = false,
  controlProp = {},
  inputProps = {},
  basePath,
  itemFormat,
  input,
  meta,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState({
    value: "",
    label: "กรุณาเลือก",
  });
  const [fetching, SetFetching] = useState(false);

  // store object value from server
  const itemObj = useRef(null);

  if (!input.value) {
    itemObj.current = null;
  }

  const fetchOptions = async () => {
    SetFetching(true);
    try {
      const { data } = await api.get(basePath, { params: { ...filter } });
      let options =
        data.rows && data.rows.length > 0
          ? data.rows
          : data && data.length > 0
          ? data
          : [];

      options = localFilter ? options.filter((r) => localFilter(r)) : options;
      options = options.map((item) => {
        return itemFormat(item);
      });

      const selected = options.filter((el) => el.value === input.value);
      if (selected.length > 0) setSelectedValues(...selected);

      setOptions(options);
    } catch (error) {
      setOptions([]);
    } finally {
      SetFetching(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  useEffect(() => {
    if (!input.value) {
      setInputValue("");
      setSelectedValues({
        value: "",
        label: "กรุณาเลือก",
      });
    }
  }, [input.value]);

  return (
    <FormControl
      variant="outlined"
      error={(meta.error || meta.submitError) && meta.touched}
      {...controlProp}
    >
      <InputLabel
        id={`${input.name}-select-label`}
        error={(meta.error || meta.submitError) && meta.touched}
      ></InputLabel>
      <Autocomplete
        readOnly={readOnly}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          input.onBlur();
        }}
        options={options}
        loading={fetching}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        value={selectedValues}
        onChange={(event, value) => {
          setSelectedValues(value);
          itemObj.current = value;
          input.onChange(value);
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={`${label}${required ? " *" : ""}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {fetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={(meta.error || meta.submitError) && meta.touched}
            />
            {(meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
              meta.touched && (
                <FormHelperText id={`${input.name}-error`} variant="outlined">
                  {meta.error || meta.submitError}
                </FormHelperText>
              )}
          </>
        )}
        {...inputProps}
      />
    </FormControl>
  );
};

FieldComponent.propTypes = {
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemFormat: PropTypes.func.isRequired,
};

export default FieldComponent;
