import { EnhancedEncryption, LockOpen } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useState } from "react";
import { Form } from "react-final-form";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import api from "../../../apis";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import { Link as RouterLink } from "react-router-dom";
import { MdLockOutline } from "react-icons/md";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
}).error(validationMsg());

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  const allowedEmaiTlds = [
    "police.go.th",
    "royalthaipolice.go.th",
    "rtp.go.th",
    "koder3.com",
    "gmail.com",
  ];
  const email = values.email;

  if (email != null) {
    // Extract the TLD from the email
    const emailTldMatch = email.match(/@(.+)$/);
    const emailTld = emailTldMatch ? emailTldMatch[1] : null;

    if (!errors.email && !allowedEmaiTlds.includes(emailTld)) {
      errors.email =
        "รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th เท่านั้น";
    }
  }

  return errors;
};

export default function ForgotPasswordPage() {
  const [success, setSuccess] = useState(false);

  const onSubmit = (formValues) => {
    return api
      .post(`/api/forgot_password`, formValues)
      .then(() => {
        setSuccess(formValues.email);
      })
      .catch(({ response }) => {
        if (response.status === 422 || response.status === 404) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <div className="grid justify-center items-center h-screen bg-[#F5F5F5]">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="bg-white p-12 rounded-[32px] shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] relative mx-4">
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <EnhancedEncryption />
        </Avatar> */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 p-1">
          <MdLockOutline className="h-16 w-16 bg-[#1976D3] rounded-full p-3 text-white" />
        </div>
        <h1 className="text-[28px]">ลืมรหัสผ่าน</h1>
        <p className="mt-3">
          โปรดระบุ email (username) ของท่านเพื่อส่งคำขอลืมรหัสผ่าน
        </p>
        {success ? (
          <Box sx={{ mt: 1 }}>
            <Alert severity="success" sx={{ width: "100%" }}>
              <AlertTitle>ระบบได้รับคำขอลืมรหัสแล้ว</AlertTitle>
              กรุณาตรวจสอบ email <strong>{success}</strong>{" "}
              เพื่อเข้าสู่กระบวนการกำหนดรหัสผ่านใหม่
            </Alert>
          </Box>
        ) : (
          <Form validate={validate} onSubmit={onSubmit}>
            {({
              handleSubmit,
              errors,
              error,
              submitError,
              submitting,
              pristine,
              initialValues,
              submitFailed,
              form,
              values,
            }) => {
              return (
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit}>
                  {submitFailed && (error || submitError) && (
                    <Alert severity="warning" sx={{ width: "100%" }}>
                      {error || submitError}
                    </Alert>
                  )}
                  <FieldInput
                    name="email"
                    label="Email"
                    required
                    controlProps={{ fullWidth: true, margin: "normal" }}
                    //       inputProps={{
                    //         placeholder: "email@police.go.th",
                    //       }}
                  />
                  <SubmitBtn
                    variant="contained"
                    submitting={submitting}
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<LockOpen />}
                    size="large">
                    ส่งคำขอลืมรหัสผ่าน
                  </SubmitBtn>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      <Link component={RouterLink} to="/login" variant="body2">
                        กลับไปหน้าเข้าสู่ระบบ
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Form>
        )}
      </Box>
    </div>
  );
}
