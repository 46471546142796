import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

export default function SubmitBtn({
  submitting = false,
  pristine = false,
  children,
  ...props
}) {
  let btn = null;

  if (submitting) {
    btn = (
      <LoadingButton loading type="button" loadingPosition="start" {...props}>
        {children}
      </LoadingButton>
    );
  } else if (pristine) {
    btn = (
      <Button type="button" disabled {...props}>
        {children}
      </Button>
    );
  } else {
    btn = (
      <Button type="submit" {...props}>
        {children}
      </Button>
    );
  }

  return btn;
}
