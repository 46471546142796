import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import { usersFetchById } from "../../../../ducks/setting";
import UserForm, { mapToInit, validateEdit } from "./UserForm";
import { CloudSync } from "@mui/icons-material";
import { authFetch } from "../../../../ducks/auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function UserEditPage() {
  const params = useParams();
  const itemId = parseInt(params.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userId = useSelector((state) => state.auth.user?.id);
  const [statusUser, setStatusUser] = useState("1");
  const [dataPermission, setDataPermission] = useState([]);
  const [item, setItem] = useState({});
  const [itemptm, setItemptm] = useState(null);
  const [modalError, setModalError] = useState(false);
  const [cardId, setCardId] = useState("");

  // const {
  //   isLoading,
  //   isError,
  //   data: item,
  // } = useQuery(
  //   ["users", itemId],
  //   () => api.get(`/api/users/${itemId}`).then((res) => res.data),
  //   { staleTime: 60 * 1000 }
  // );

  const callAPiGetInfo = useCallback(async (id) => {
    try {
      const res = await api.get(`/api/users/${id}`);
      setItem(res.data);
    } catch (error) {
      setModalError(true);
    }
  }, []);

  useEffect(() => {
    if (itemId) {
      callAPiGetInfo(itemId);
    }
  }, [callAPiGetInfo, itemId]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const callGetPolicestationsById = useCallback(async (id) => {
    const res = await api.get(`/api/policestations/${id}`);
    return {
      org_code: res.data.ORG_CODE,
      org_type: res.data.ORG_TYPE,
    };
  }, []);

  const onSubmit = async (formValues, form) => {
    if (
      !_.isObject(formValues?.agency_supervise) &&
      formValues?.agency_supervise
    ) {
      const res = await callGetPolicestationsById(formValues?.agency_supervise);
      formValues.agency_supervise = res;
    }

    if (!_.isObject(formValues?.agency_under)) {
      const res = await callGetPolicestationsById(formValues?.agency_under);
      formValues.agency_under = res;
    }

    const objData = {
      ...formValues,
      roles: dataPermission,
      status: statusUser,
    };
    try {
      await api.put(`/api/users/${itemId}`, objData);
      if (itemId === userId) dispatch(authFetch());
      queryClient.invalidateQueries(["settingusers", itemId]);
      navigate(`/settinguser`);
      toast.success("แก้ไขบัญชีผู้ใช้งานระบบสำเร็จ");
      form.reset({});
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  const fetchptm = () => {
    api
      .get(`/api/ptm`, { params: { card_id: cardId } })
      .then(({ data }) => {
        if (!_.isEmpty(data) && data) {
          setItemptm(data);
        } else {
          toast.error("ไม่พบข้อมูลผู้ใช้งานด้วยเลขบัตรประชาชนนี้");
        }
      })
      .catch(() => {})
      .finally(() => {
        // setLoading(false);
      });
  };

  // useEffect(() => {
  //   dispatch(usersFetchById(itemId)).catch((e) => {
  //     SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
  //   });
  // }, [dispatch, itemId]);

  const renderContent = () => {
    // if (isLoading)
    //   return (
    //     <>
    //       <Skeleton animation="wave" />
    //       <Skeleton animation="wave" />
    //       <Skeleton animation="wave" />
    //     </>
    //   );

    // if (isError)
    //   return (
    //     <Alert severity="error">
    //       <AlertTitle>Error</AlertTitle>
    //       เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
    //     </Alert>
    //   );

    return (
      <Form
        validate={validateEdit}
        onSubmit={onSubmit}
        setStatus={setStatusUser}
        initialValues={mapToInit(item)}
        component={UserForm}
        setDataPermission={setDataPermission}
        setCardId={setCardId}
        editMode
        itemptm={itemptm}
      />
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5">
              แก้ไขบัญชีผู้ใช้งานระบบ (ID: {itemId})
            </Typography>
            {/* <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography> */}
          </Grid>
          <Grid container xs={6} justifyContent="flex-end">
            <Button
              id="export-button-excel"
              variant="outlined"
              startIcon={<CloudSync />}
              aria-haspopup="true"
              onClick={fetchptm}
              disabled={!cardId}
            >
              ตรวจสอบข้อมูล
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {renderContent()}
        </Paper>
      </Grid>
      <Dialog
        open={modalError}
        onClose={() => setModalError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalError(false)}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
