import { Typography, Link, Box, Button } from "@mui/material";
import { Login, Save } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import fileDownload from "js-file-download";
import api from "../../apis";

export default function DowloadFilePage() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="bg-[#F5F5F5] h-screen grid justify-center items-center ">
      <div className="bg-white rounded-[32px] p-12 relative mx-4 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] max-w-[516px]">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 p-1">
          {/* <CheckCircleIcon className="h-16 w-16 bg-[#1976D3] rounded-full p-3 text-white" /> */}
          <DownloadForOfflineIcon
            sx={{
              fontSize: 80,
              color: "#4caf50",
            }}
          />
        </div>
        <p className="text-center" style={{
          fontFamily:"kanit",
        }} >
          ดาวน์โหลดไฟล์รายการคำขอทั้งหมด จากลิงก์ด้านล่าง
        </p>
        <p
          className=" cursor-pointer underline text-blue-500 text-center"
          style={{
            fontFamily:"kanit",
          }}
          onClick={async () => {

            try {
              const token = location?.pathname?.split("download/")[1];

              const res = await api.get(`/api/download/${token}`, {
                responseType: "blob",
              });
              const contentDisposition = res.headers["content-disposition"];
              const filename = /filename=.+/.exec(contentDisposition)
                ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                : "download.zip";
              fileDownload(res.data, filename);
              
            } catch (error) {
              console.log(error);
              
            }
           

          }}
        >
          dowload File 
        </p>

        <Box className="flex justify-center mt-10">
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            color="primary"
            component={Link}
            onClick={() => navigate("/")}
          >
            กลับสู่หน้าหลัก
          </Button>
        </Box>
      </div>
    </div>
  );
}
