import { ListItemButton } from "@mui/material";
import React from "react";
import { Link as ReactRouterLink, useMatch, useResolvedPath } from "react-router-dom";

export default function MenuItemBotton({
  component = ListItemButton,
  children,
  to,
  exact,
  ...props
}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: exact ? true : false });
  const Component = component;
  return (
    <Component
      component={ReactRouterLink}
      selected={match ? true : false}
      to={to}
      
      {...props}
    >
      {children}
    </Component>
  );
}
