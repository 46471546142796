import { Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import SubmitBtn from "../../../SubmitBtn";

export default function ChannelSendingEditPage() {
  const params = useParams();
  const itemId = Object.values(params);
  const navigate = useNavigate();
  const qState = useSelector((state) => state.auth.user.permissions);

  const { isLoading: itemLoading, data: item } = useQuery(
    ["externalsync_by_", itemId],
    () => api.get(`/api/externalsync/${itemId}`).then((res) => res.data)
    // { staleTime: 60 * 1000 }
  );

  // console.log({item})

  const onBack = () => {
    return navigate("/settingchannel");
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isLoading },
  } = useForm();
  const onSubmit = (data) => {
    // console.log({ data });
    let bodyJson = [];

    _.map(item.request_type, (value) => {
      if (
        _.get(data, `${_.get(value, "id")}.email`) === true ||
        _.get(data, `${_.get(value, "id")}.api`) === true
      )
        bodyJson.push({
          id: _.get(value, "org_connect.id", null),
          email: _.get(data, `${_.get(value, "id")}.email`, null),
          api: _.get(data, `${_.get(value, "id")}.api`, null),
          email_recipient: _.get(
            data,
            `${_.get(value, "id")}.email_recipient`,
            null
          ),
          api_endpoint: _.get(data, `${_.get(value, "id")}.api_endpoint`, null),
        });
    });
    onSaveData(bodyJson);
  };

  const onSaveData = (body) => {
    const fetchData = async () => {
      const response = await api
        .patch(`/api/externalsync/${itemId}`, body)
        .then((res) => {
          navigate("/settingchannel");
          return res;
        });
      return response;
    };
    const callFunction = fetchData();
    toast.promise(callFunction, {
      loading: <b>กำลังโหลด...</b>,
      success: <b>เพิ่มข้อมูลเสร็จสิ้น</b>,
      error: <b>ระบบเกิดความผิดพลาด กรุณาแจ้งเจ้าหน้าที่</b>,
    });
  };

  useEffect(() => {
    let type = "";
    switch (_.get(item, "type")) {
      case "bank":
        type = "ธนาคาร";
        break;
      case "telco":
        type = "โทรศัพท์";
        break;
      default:
        type = "บุคคล";
        break;
    }

    setValue("service_type", type);
    setValue("agency", _.get(item, "name"));
    _.map(_.get(item, "request_type"), (value) => {
      setValue(
        `${_.get(value, "id")}.email`,
        _.get(value, "org_connect.email")
      );
      setValue(`${_.get(value, "id")}.api`, _.get(value, "org_connect.api"));
      setValue(
        `${_.get(value, "id")}.email_recipient`,
        _.get(value, "org_connect.email_recipient")
      );
      setValue(
        `${_.get(value, "id")}.api_endpoint.method`,
        _.get(value, "org_connect.api_endpoint.method")
      );
      setValue(
        `${_.get(value, "id")}.api_endpoint.url`,
        _.get(value, "org_connect.api_endpoint.url")
      );
      setValue(
        `${_.get(value, "id")}.api_endpoint.token`,
        _.get(value, "org_connect.api_endpoint.token")
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const options = [
    { label: "GET", value: "get" },
    { label: "POST", value: "post" },
    { label: "PUT", value: "put" },
    { label: "PATCH", value: "patch" },
    { label: "DELETE", value: "delete" },
  ];

  // const renderContent = () => {
  //   return (
  //     <Form
  //       onSubmit={onSubmit}
  //       initialValues={mapToInit(item)}
  //       component={ChannelSendingForm}
  //       editMode
  //       setForm={setForm}
  //     />
  //   );
  // };

  const checkboxOnclickEmail = (id, clickto) => {
    if (watch(`${id}.email`) === true && watch(`${id}.api`) === false)
      return setValue(`${id}.api`, true);
  };

  const checkboxOnclickApi = (id, clickto) => {
    if (watch(`${id}.api`) === true && watch(`${id}.email`) === false)
      return setValue(`${id}.email`, true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          แก้ไขช่องทางการส่งหมาย (ID: {itemId})
        </Typography>
        <p>Master Data สำหรับตั้งค่าช่องทางการส่งหมายคำขอไปยังหน่วยงานภายนอก</p>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            // display: "flex",
            // flexDirection: "column",
            width: "100%",
          }}
        >
          {itemLoading || isLoading ? (
            <Grid container justifyContent="center" mt={5}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-3 justify-center items-center">
                <Controller
                  name={"service_type"}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label="ประเภทบริการ*"
                      placeholder="พิมพ์อีเมลและกด enter"
                      inputProps={{ readOnly: true }}
                    />
                  )}
                />
                <Controller
                  name={"agency"}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label="หน่วยงานภายนอก*"
                      placeholder="พิมพ์อีเมลและกด enter"
                      inputProps={{ readOnly: true }}
                    />
                  )}
                />
              </div>
              <Grid item xs={12}>
                <Divider textAlign="center" sx={{ mt: 2 }}>
                  <h2 className="font-medium">ตั้งค่า Email ผู้รับ</h2>
                </Divider>
              </Grid>
              <h3 className="font-medium mt-5">
                เลือกช่องทาง (อย่างน้อย 1 ช่องทางของแต่ละหมาย)
              </h3>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-7">
                <div className="ml-3">
                  {_.map(_.get(item, "request_type"), (data, i) => (
                    <div
                      key={_.get(data, "id")}
                      id={`form-input-${_.get(data, "id")}`}
                      className="mt-5"
                    >
                      <p>{`${i + 1}. ${_.get(data, "name", "")}`}</p>
                      <div className="flex gap-3 mt-3">
                        <div className="flex justify-start items-center gap-2">
                          <input
                            onClick={() =>
                              checkboxOnclickEmail(_.get(data, "id"))
                            }
                            {...register(`${_.get(data, "id")}.email`)}
                            type="checkbox"
                          />
                          <label>Email</label>
                        </div>
                        <div className="flex justify-start items-center gap-2">
                          <input
                            onClick={() =>
                              checkboxOnclickApi(_.get(data, "id"))
                            }
                            {...register(`${_.get(data, "id")}.api`)}
                            type="checkbox"
                          />
                          <label>API</label>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 justify-center items-start mt-3">
                        {watch(`${_.get(data, "id")}.email`) && (
                          <div>
                            <Controller
                              name={`${_.get(data, "id")}.email_recipient`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: "กรุณาระบุอีเมล",
                                },
                                pattern: {
                                  value:
                                    /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
                                  message: "รูปแบบอีเมลไม่ถูกต้อง",
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  sx={{ width: "100%" }}
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  label="อีเมลผู้รับ *"
                                  placeholder="หากมีมากกว่า 1 อีเมล พิมพ์อีเมลและคั่นด้วย ','"
                                />
                              )}
                            />
                            <small className="text-red-500 text-sm">
                              {_.get(
                                errors,
                                `${_.get(data, "id")}.email_recipient.message`
                              )}
                            </small>
                          </div>
                        )}
                        {api.patch}
                        {watch(`${_.get(data, "id")}.api`) && (
                          <div className="grid grid-cols-5 gap-3">
                            <div className="col-span-2">
                              <Controller
                                name={`${_.get(
                                  data,
                                  "id"
                                )}.api_endpoint.method`}
                                control={control}
                                render={({ field }) => (
                                  // <TextField
                                  //   {...field}
                                  //   sx={{ width: "100%" }}
                                  //   InputLabelProps={{ shrink: true }}
                                  //   variant="outlined"
                                  //   label="Method *"
                                  //   placeholder="พิมพ์ Method และกด enter"
                                  // />
                                  <Autocomplete
                                    getOptionSelected={(option, value) =>
                                      option._id === value.value
                                    }
                                    {...field}
                                    onChange={(e, value) => {
                                      field.onChange(value);
                                    }}
                                    disablePortal
                                    disableClearable
                                    options={options}
                                    sx={{ width: "full" }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Method *"
                                        placeholder="เลือก Method"
                                      />
                                    )}
                                  />
                                )}
                              />
                              <small className="text-red-500 text-sm">
                                {_.get(
                                  errors,
                                  `${_.get(
                                    data,
                                    "id"
                                  )}.api_endpoint.method.message`
                                )}
                              </small>
                            </div>
                            <div className="col-span-3">
                              <Controller
                                name={`${_.get(data, "id")}.api_endpoint.url`}
                                control={control}
                                rules={{ required: "กรอกข้อมูล" }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    sx={{ width: "100%" }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    label="API URL *"
                                    placeholder="พิมพ์ URL และกด enter"
                                  />
                                )}
                              />
                              <small className="text-red-500 text-sm">
                                {_.get(
                                  errors,
                                  `${_.get(
                                    data,
                                    "id"
                                  )}.api_endpoint.url.message`
                                )}
                              </small>
                            </div>
                            <div className="col-span-5">
                              <Controller
                                name={`${_.get(data, "id")}.api_endpoint.token`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    sx={{ width: "100%" }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    label="API Token"
                                    placeholder="พิมพ์ TOKEN และกด enter"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="mt-5" />
                {!qState?.setting_agency?.includes("edit") && (
                  <p className="text-red-400 my-2">คุณไม่มีสิทธิ์แก้ไข</p>
                )}
                <div className="flex gap-5 mt-5">
                  {qState?.setting_agency?.includes("edit") && (
                    <SubmitBtn
                      type="submit"
                      variant="contained"
                      startIcon={<Save />}
                    >
                      บันทึก
                    </SubmitBtn>
                  )}

                  <Button onClick={onBack} type="button" variant="outlined">
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
}
