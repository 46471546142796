import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import { hr03RequestActiveCreate } from "../../../../ducks/hr03List";
import Hr03RequestActiveForm, { validate } from "./Hr03RequestActiveForm";
import { useDispatch } from "react-redux";

export default function UserEditPage() {
  const params = useParams();
  const itemId = parseInt(params.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const onSubmit = (formData) => {
    const objData = { ...formData };

    return dispatch(hr03RequestActiveCreate(objData))
      .then(() => {
        navigate("/hr03/request-active");
        toast.success("แก้ไขสำเร็จ");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    api
      .get(`/api/me`)
      .then(({ data }) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">ข้อมูลคำร้องขอเปิดใช้งานบัญชี HR03</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              id={itemId}
              page="edit"
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
