const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function _getIaccessToken() {
    return localStorage.getItem("iaccess_token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _getIrefreshToken() {
    return localStorage.getItem("irefresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("iaccess_token");
    localStorage.removeItem("irefresh_token");
    localStorage.removeItem("username");
  }
  function _setItoken(tokenObj) {
    localStorage.setItem("iaccess_token", tokenObj.access_token);
    localStorage.setItem("irefresh_token", tokenObj.refresh_token);
  }

  function _setUsername(username) {
    localStorage.setItem("username", username);
  }
  function _getUsername() {
    return localStorage.getItem("username");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getIaccessToken: _getIaccessToken,
    getRefreshToken: _getRefreshToken,
    getIrefreshToken: _getIrefreshToken,
    clearToken: _clearToken,
    setItoken: _setItoken,
    setUsername: _setUsername,
    getUsername: _getUsername,
  };
})();
export default LocalStorageService;
