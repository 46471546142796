import {
  Add,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FiberManualRecord,
  PictureAsPdf,
  UploadFile,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import fileDownload from "js-file-download";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import {
  clearResult,
  forceReload as rdForceReload,
} from "../../../ducks/accountList";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import AccountAddPage from "./AccountAddPage";
import AccountEditPage from "./AccountEditPage";
import AccountListHistory from "./AccountListHistory";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalHistoryStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AccountList() {
  const cases = useSelector((state) => state.auth.user.permissions.cases ?? []);
  const params = useParams();
  const caseId = parseInt(params.id);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = useState({
    page: 1,
    pageSize: 5,
  });
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  const forceReload = useSelector(
    (state) => state.accountList.meta.forceReload
  );

  // add account modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const [openModalHistory, setOpenModalHistory] = useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = useCallback(() => {
    setOpenModalHistory(false);
  }, []);

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/cases/${caseId}/bank_accounts/${cfDel}`)
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบข้อมูลเรียบร้อยแล้ว" });
        dispatch(rdForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  const _columns = [
    ...(cases.includes("edit")
      ? [
          {
            field: "action",
            headerName: "จัดการ",
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Stack spacing={0} direction="row">
                  {cases.includes("edit") && (
                    <IconButton
                      type="button"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenModal(
                          <AccountEditPage
                            accountId={params?.id}
                            handleCancel={handleCloseModal}
                          />
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    type="button"
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setCfDel(params.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "number",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 400,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Button
              variant="text"
              onClick={() => {
                handleOpenModalHistory(
                  <AccountListHistory
                    handleCancel={handleCloseModalHistory}
                    data={params?.row}
                  />
                );
              }}
            >
              {params.row.number}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "ประเภท",
      sortable: false,
      minWidth: 80,
      maxWidth: 100,
      valueFormatter: ({ value }) => {
        return value?.type;
      },
    },
    {
      field: "name",
      headerName: "ชื่อบัญชี",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
    },
  ];

  const {
    data: bankHistory,
    refetch: bankHistoryRefresh,
    isLoading: bankHistoryLoading,
  } = useQuery(
    ["bankHistory", rowsState, caseId],
    () =>
      api
        .get(`/api/cases/${caseId}/bank_accounts`, {
          params: {
            page_size: rowsState?.pageSize,
            page: rowsState?.page,
          },
        })
        .then((res) => {
          setTotal(res.data.total);
          return res.data;
        }),
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  // ฟังก์ชันสำหรับสร้างคอลัมน์ใหม่และเพิ่มเข้าไปในคอลัมน์เดิม
  const generateAndAppendRequestColumns = (existingColumns, bankHistory) => {
    if (bankHistory?.request_column === undefined) return [];

    const newColumns = bankHistory?.request_column?.map((name) => ({
      field: name.toLowerCase(),
      headerName: name,
      sortable: false,
      maxWidth: 250,
      renderCell: (params) => {
        const row = params.row[params.field.toUpperCase()];
        const total = row.total || 0;
        return (
          <Stack alignItems="center">
            <Stack direction="row">({total})</Stack>
            <Stack direction="row">
              {row.status === null && <Chip label="ยังไม่ขอ" size="small" />}
              {row.status !== null && getChip(row.status, { size: "small" })}
            </Stack>
          </Stack>
        );
      },
    }));
    return [...existingColumns, ...newColumns];
  };

  const columns = generateAndAppendRequestColumns(_columns, bankHistory);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const refCsv = useRef();
  const [csvUploading, setCsvUploading] = useState(false);
  const [csv, setCsv] = useState();
  const [csvFile, setCsvFile] = useState();
  const [openModalImport, setOpenModalImport] = useState(false);
  const handleOpenModalImport = (page) => setOpenModalImport(page);
  const handleCloseModalImport = (payload) => {
    setOpenModalImport(false);
  };

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
    };
  }, [dispatch]);

  useEffect(() => {
    bankHistoryRefresh();
  }, [bankHistoryRefresh, rowsState, caseId, forceReload]);

  const handlePageChange = (newPage) => {
    setRowsState((prev) => ({ ...prev, page: newPage }));
  };

  const readCSVFile = async () => {
    setCsvUploading(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      setCsvFile();
      refCsv.current.files = null;
      refCsv.current.value = null;
      setCsv(e.target.result);
    };
    reader.onerror = function (e) {
      toast.error("เปิด csv ไม่สำเร็จ");
    };
    reader.readAsText(csvFile);
  };

  const uploadSubmit = async (text) => {
    try {
      const bank_accounts_csv = text;
      const res = await api.post(
        `/api/cases/${caseId}/import_bulk_bank_account`,
        { bank_accounts_csv }
      );
      handleOpenModalImport(
        <Box sx={modalStyle}>
          <Typography variant="h6" align="center">
            {res.data.message}
          </Typography>
          {res.data.unsave_bank_accounts.length > 0 && (
            <List>
              {res.data.unsave_bank_accounts.map((e) => (
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecord />
                  </ListItemIcon>
                  <ListItemText primary={e} />
                </ListItem>
              ))}
            </List>
          )}
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={handleCloseModalImport}>
              ปิด
            </Button>
          </Stack>
        </Box>
      );
    } catch (res) {
      toast.error("อัพโหลดไม่สำเร็จ");
    } finally {
      setCsvUploading(false);
      setCsv();
      bankHistoryRefresh();
    }
  };
  useEffect(() => {
    if (csv) {
      uploadSubmit(csv);
    }
  }, [csv]);

  useEffect(() => {
    if (csvFile) {
      readCSVFile(csvFile);
    }
  }, [csvFile]);

  return (
    <>
      <Modal
        open={openModalImport ? true : false}
        onClose={() => handleCloseModalImport()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModalImport}</div>
      </Modal>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Modal
        open={openModalHistory ? true : false}
        onClose={handleCloseModalHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalHistoryStyle}>{openModalHistory}</Box>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <DeleteIcon /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ บัญชีธนาคาร(ID: {cfDel})
      </ConfirmDialog>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {cases.includes("upload") && (
          <LoadingButton
            type="button"
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            color="warning"
            startIcon={<UploadFile />}
            loadingPosition="start"
            loading={csvUploading}
            disabled={csvUploading}
          >
            Import CSV
            <VisuallyHiddenInput
              ref={refCsv}
              type="file"
              accept=".csv"
              onChange={(event) => {
                if (!event.target && event.target.files.length < 1) return;
                setCsvFile(event.target.files[0]);
              }}
            />
          </LoadingButton>
        )}
        {cases.includes("download") && (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdf />}
            loadingPosition="start"
            loading={pdfDownloading}
            disabled={loadingPDF}
            onClick={async () => {
              try {
                setPdfDownloading(true);
                const res = await api.get(
                  `/api/cases/${caseId}/bank_accounts`,
                  {
                    responseType: "blob",
                    params: {
                      export: "pdf",
                    },
                  }
                );
                const contentDisposition = res.headers["content-disposition"];
                const filename = /filename=.+/.exec(contentDisposition)
                  ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                  : "download.pdf";
                fileDownload(res.data, filename);
              } catch (error) {
                console.log(error);
              } finally {
                setPdfDownloading(false);
              }
            }}
          >
            ดาวน์โหลด PDF
          </LoadingButton>
        )}
        {cases.includes("create") && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => {
              handleOpenModal(
                <AccountAddPage handleCancel={handleCloseModal} />
              );
            }}
          >
            เพิ่มบัญชี
          </Button>
        )}
      </Stack>
      <DataGrid
        disableColumnMenu
        columns={columns}
        rows={bankHistory?.rows ?? []}
        loading={bankHistoryLoading}
        rowsPerPageOptions={[5, 10, 20]}
        rowCount={total}
        pagination
        paginationMode="server"
        page={rowsState.page - 1}
        pageSize={rowsState.pageSize}
        onPageChange={(newPage) => handlePageChange(newPage + 1)}
        onPageSizeChange={(newPageSize) =>
          setRowsState((prev) => ({ ...prev, page: 1, pageSize: newPageSize }))
        }
        autoHeight
        getRowHeight={() => 80}
      />
    </>
  );
}
