import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../apis";
import validationMsg from "../../../helpers/validationMsg";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import FieldSelect from "../../form-fields/FieldSelect";
import FieldSingleFileDropZone from "../../form-fields/FieldSingleFileDropZone";
import SubmitBtn from "../../SubmitBtn";
import { format, parseISO } from "date-fns";

const Joi = require("joi").extend(require("@joi/date"));

// const receivedFroms = [
//   { value: "email", label: "Email" },
//   { value: "msTeam", label: "Microsoft Team" },
//   { value: "line", label: "Line" },
//   { value: "others", label: "อื่นๆ" },
// ];

export default function ResponseUploadForm({
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  page,
  id,
}) {
  // const letterTypes = useMemo(() => {
  //   return allLetterTypes.filter((lt) => {
  //     return [2, 3].includes(lt.value);
  //   });
  // }, []);
  const navigate = useNavigate();
  const [isError, setIsError] = React.useState(false);
  const qState = useSelector((state) => state.auth.user.permissions);

  const { data: Detail } = useQuery(
    ["hr03Detail", id],
    () => id && api.get(`/api/reasonable/${id}`).then((res) => res.data),
    { staleTime: 60 * 1000 }
  );

  // const {
  //   isLoading: isBankOptionsLoading,
  //   data: bankOptions,
  //   error: bankOptionsError,
  // } = useQuery(
  //   [
  //     "bankSelect",
  //     "/api/banks?type=bank",
  //     { value: "code", label: "fullname" },
  //   ],
  //   () =>
  //     api.get("/api/banks?type=bank").then((res) =>
  //       res?.data?.rows?.map((row) => ({
  //         value: row.code,
  //         label: `${row.name} (${row.short_name})`,
  //       }))
  //     ),
  //   { staleTime: 3 * 60 * 1000 }
  // );

  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) => {
        return res?.data?.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }));
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  const { data: typeOptions } = useQuery(
    ["selectType"],
    () =>
      api.get("/api/reasonable/type").then((res) => {
        return res?.data?.map((row) => {
          if (row.id === 0) {
            return {
              value: 99,
              label: `อื่นๆ`,
            };
          } else {
            return {
              value: row.id,
              label: `${row.name}`,
            };
          }
        });
      }),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    if (Detail) {
      form.change("notes", Detail.notes);
      form.change("type", Number(Detail.type) === 0 ? 99 : Number(Detail.type));
      form.change("bank", Detail.bank.code);
      form.change("number", Detail.number);
      form.change("AgencyType", 1);
      form.change("date", format(parseISO(Detail.date), "yyyy-MM-dd HH:mm"));
      form.change(
        "type_note",
        Detail.type_note === "undefined" ? "-" : Detail.type_note
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Detail]);

  const downloadFile = useCallback(async (fileUrl, fileName) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      // Create a temporary link element
      const link = document.createElement("a");
      const contentType = res.headers["content-type"];

      link.href = url;
      if (contentType.includes("text/csv")) {
        link.download = `${fileName}.csv`; // Set the desired file name for the download
      } else if (contentType.includes("application/zip")) {
        link.download = `${fileName}.zip`; // Set the desired file name for the download
      } else if (contentType.includes("text/plain")) {
        link.download = `${fileName}.txt`; // Set the desired file name for the download
      } else {
        link.download = `${fileName}.csv`;
      }

      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setIsError(true);
    }
  }, []);

  return (
    <>
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsError(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {submitFailed && (error || submitError) && (
            <Grid item xs={12}>
              <Alert severity="warning" sx={{ width: "auto" }}>
                {error || submitError}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={4}>
            <FieldSelect
              name="AgencyType"
              label="ประเภทหน่วยงาน"
              options={[{ value: 1, label: "ธนาคาร" }]}
              required
              controlProp={{ sx: { width: "100%" } }}
              readOnly={page === "edit"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FieldAutoComplete
              name="bank"
              label="หน่วยงานภายนอก"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading: isBankOptionsLoading || bankOptionsError,
                loadingText: bankOptionsError ? "Error" : "Loading…",
              }}
              options={bankOptions}
              readOnly={page === "edit"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FieldDatePicker
              name="date"
              label="วันที่เเจ้ง"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy HH:mm",
                disableFuture: true,
                openTo: "year",
                views: ["month", "day", "hours", "minutes"],
                allowSameDateSelection: true,
              }}
              readOnly={page === "edit"}
              storeFormat="yyyy-MM-dd HH:mm"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FieldInput
              name="number"
              label="เลขบัญชี"
              controlProps={{ fullWidth: true }}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FieldAutoComplete
              name="type"
              label="เหตุอันควรสงสัย"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading: isBankOptionsLoading || bankOptionsError,
                loadingText: bankOptionsError ? "Error" : "Loading…",
              }}
              options={typeOptions}
              readOnly={page === "edit"}
            />
            {/* <FieldAsyncSelect
              name="type"
              label="เหตุอันควรสงสัย"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/reasonable/type`}
              // filter={{ type: "bank" }}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: `${row.name}`,
                };
              }}
              readOnly={page === "edit"}
            /> */}
          </Grid>
          {values.type === 99 && (
            <Grid item xs={12} md={4}>
              <FieldInput
                name="type_note"
                label="ข้อมูลเพิ่มเติม"
                controlProps={{ fullWidth: true }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <FieldInput
              name="notes"
              label="หมายเหตุ"
              controlProps={{ fullWidth: true }}
              inputProps={{ multiline: true, maxRows: 6, minRows: 2 }}
              readOnly={page === "edit"}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography variant="subtitle1">เอกสารเเนบ*</Typography>
            {page === "add" ? (
              <>
                <FieldSingleFileDropZone
                  name="attachment"
                  accept="text/csv,.txt,application/zip"
                  placeholder={
                    <>
                      Drag 'n' drop some files here, or click to select files{" "}
                      <br />
                      (*.csv,*.txt,*.zip ) only (5 MB)
                    </>
                  }
                  // resizeImage={resizeFile}
                  // shouldResizeImage={(imageFile) => {
                  //   return (imageFile.size / 1048576).toFixed(2) > 1;
                  // }}
                  dropZoneProps={{ maxSize: 1048576 * 5 }}
                  readOnly={
                    page === "edit" ||
                    !qState?.upload_suspicious?.includes("upload")
                      ? true
                      : false
                  }
                  disabled={!qState?.upload_suspicious?.includes("upload")}
                />
                {qState?.upload_suspicious?.includes("upload") || (
                  <p className="text-red-500 font-norma text-sm my-2">
                    คุณไม่มีสิทธิ์ในการอัพโหลดไฟล์
                  </p>
                )}
              </>
            ) : (
              <>
                <Typography
                  style={{
                    textDecoration: "underline",

                    color: "blue",
                    cursor: "pointer",
                  }} // Optional: Custom styles
                  onClick={() => {
                    // if (qState?.upload_suspicious?.includes("download")) {
                    downloadFile(Detail?.attachment?.slice(1), "เอกสารเเนบ");
                    // }
                  }}
                >
                  ดาวน์โหลดไฟล์
                </Typography>
                {/* {qState?.upload_suspicious?.includes("download") || (
                  <p className="text-red-500 font-norma text-sm my-2">
                    คุณไม่มีสิทธิ์ในการดาวน์โหลดไฟล์
                  </p>
                )} */}
              </>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Divider></Divider>
          </Grid>
          <Grid item md={12}>
            <Stack direction="row" spacing={2}>
              <SubmitBtn
                variant="contained"
                startIcon={<Save />}
                submitting={submitting}
                {...(page === "edit" ? { disabled: true } : {})}
                // disabled={page === "edit"}
                pristine={pristine}
              >
                บันทึก
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={submitting}
                onClick={() => navigate("/UploadSuspicious")}
              >
                ยกเลิก
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const schema = Joi.object(validateRules()).error(
    validationMsg({ dateFormat: { recived_at: "dd/MM/yyyy HH:mm" } })
  );
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    !errors.template_file &&
    values.template_file?.size &&
    (values.template_file.size / 1024000).toFixed(2) > 10
  ) {
    errors.template_file = "ขนาดไม่เกิน 10 MB";
    errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  }

  if (
    !errors.response_letter_file &&
    values.response_letter_file?.size &&
    (values.response_letter_file.size / 1024000).toFixed(2) > 5
  ) {
    errors.response_letter_file = "ขนาดไม่เกิน 5 MB";
    errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  }

  return errors;
};

export const validateRules = () => ({
  AgencyType: Joi.alternatives()
    .try(Joi.number(), Joi.string())
    .required()
    .messages({
      "any.required": "กรุณาเลือกประเภทหน่วยงาน",
    }),
  bank: Joi.any()
    .messages({
      "string.empty": "กรุณาเลือกหน่วยงานภายนอก",
      "any.required": "กรุณาเลือกหน่วยงานภายนอก",
    })
    .required(),
  date: Joi.date().format(["YYYY-MM-DD HH:mm"]).required().messages({
    "date.base": "กรุณาเลือกวันที่เเจ้ง",
    "any.required": "กรุณาเลือกวันที่เเจ้ง",
  }),
  number: Joi.string().required().messages({
    "string.empty": "กรุณากรอกเลขบัญชี",
    "any.required": "กรุณากรอกเลขบัญชี",
  }),
  type: Joi.any().required().messages({
    "string.empty": "กรุณาเลือกเหตุอันควรสงสัย",
    "any.required": "กรุณาเลือกเหตุอันควรสงสัย",
  }),
  type_note: Joi.string().allow("").optional(),
  notes: Joi.string().allow("").optional(),
  attachment: Joi.any().required().messages({
    "any.required": "กรุณาเลือกเอกสารเเนบ",
  }),
});
