import { Card, CardActionArea, CardMedia } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

export default function SignaturePreview({ data, name = "signature" }) {
  const field = useField(name);

  if (!field.input.value || !typeof field.input.value === "string") return null;

  return (
    <Card sx={{ width: "100%" }}>
      <CardActionArea sx={{ display: "flex", justifyContent: "center" }}>
        <CardMedia
          component="img"
          sx={{ height: 70, width: "auto", p: 1 }}
          image={field.input.value}
          alt="upload file"
        />
      </CardActionArea>
    </Card>
  );
}
