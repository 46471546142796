import api from "../apis";
import _ from "lodash";
// Actions
const EXTERNAL_FETCH = "external/EXTERNAL_FETCH";
const EXTERNAL_CLEAR_RESULT = "external/EXTERNAL_CLEAR_RESULT";
const EXTERNAL_MARK_LOADING = "external/EXTERNAL_MARK_LOADING";
const EXTERNAL_SET_FILTER = "external/EXTERNAL_SET_FILTER";
const EXTERNAL_FORCE_RELOAD = "external/EXTERNAL_FORCE_RELOAD";
const EXTERNAL_CHANGE_PAGE = "external/EXTERNAL_CHANGE_PAGE";
const EXTERNAL_CHANGE_PAGE_SIZE = "external/EXTERNAL_CHANGE_PAGE_SIZE";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 20,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case EXTERNAL_FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case EXTERNAL_CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0, page: 0 },
      };
    case EXTERNAL_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case EXTERNAL_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
          page: 0,
        },
      };
    case EXTERNAL_FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    case EXTERNAL_CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case EXTERNAL_CHANGE_PAGE_SIZE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: 0,
          pageSize: action.payload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const externalFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(externalMarkLoading(true));

    try {
      response = await api.get(`/api/external/requests`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: EXTERNAL_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      dispatch(externalClearResult());
      throw e;
    }
  };

export const externalMarkLoading = (isLoading = true) => {
  return { type: EXTERNAL_MARK_LOADING, payload: isLoading };
};

export const externalClearResult = () => {
  return { type: EXTERNAL_CLEAR_RESULT };
};

export const externalSetFilter = (filterValue) => {
  return { type: EXTERNAL_SET_FILTER, payload: filterValue };
};

export const externalForceReload = () => {
  return { type: EXTERNAL_FORCE_RELOAD };
};

export const externalChangePage = (page) => {
  return { type: EXTERNAL_CHANGE_PAGE, payload: page };
};

export const externalChangePageSize = (pageSize) => {
  return { type: EXTERNAL_CHANGE_PAGE_SIZE, payload: pageSize };
};
