import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import AccountListlHistoryForm from "./AccountListlHistoryForm";
import { useQuery } from "react-query";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import { format, parseISO } from "date-fns";
import { ForwardToInbox, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function AccountListHistory({
  handleSubmit,
  handleCancel,
  data,
}) {
  // const params = useParams();
  // const caseId = parseInt(params.id);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 5,
  });

  const [total, setTotal] = useState(0);

  const rows = useSelector((state) => state.personalList?.telephone?.rows);
  const loading = useSelector(
    (state) => state.personalList.metaTelephone.loading
  );

  const { data: account, refetch: accountHistoryRefresh } = useQuery(
    ["accountHistory", data, rowsState?.page],
    () =>
      api
        .get(
          `/api/cases/${data?.case_id}/bank_accounts/${data?.id}?page=${rowsState?.page}&page_size=${rowsState?.pageSize}`
        )
        .then((res) => {
          setTotal(res.data.list_request.total);
          return res.data;
        }),
    { staleTime: 60 * 1000 }
  );

  useEffect(() => {
    accountHistoryRefresh();
  }, [rowsState?.page, rowsState?.pageSize, accountHistoryRefresh, data]);

  const columns = [
    {
      field: "action",
      headerName: "จัดการ",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            component={Link}
            to={`/cases/${data?.case_id}/requests/${params?.row?.id}`}
            type="button"
            variant="contained"
            size="small"
          // onClick={() => {
          //   handleOpenModal(
          //     <RequestView
          //       handleCancel={handleCloseModal}
          //       requestId={params.id}
          //     />
          //   );
          // }}
          >
            {params?.status === "new" ? (
              <ForwardToInbox color="primary" />
            ) : (
              <Visibility />
            )}
          </IconButton>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <p>{params?.row?.id}</p>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      minWidth: 120,
      width: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (paramse) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <p>{paramse?.row?.detail_id}</p>
          </Box>
        );
      },
    },
    {
      field: "equest_type",
      headerName: "ประเภทคำขอ",
      minWidth: 200,
      width: 300,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <p>{params?.row?.request_type?.name}</p>
            {/* <Typography>{params?.row?.request_type?.name}</Typography> */}
          </Box>
        );
      },
    },
    {
      field: "requestdate",
      headerName: "วันที่ขอ",
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <p>
              {format(parseISO(params?.row?.created_at), "dd/MM/yyyy HH:mm:ss")}
            </p>
          </Box>
        );
      },
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <p>{params?.row?.detail.no}</p>
          </Box>
        );
      },
    },
    {
      field: "status_note",
      headerName: "หมายเหตุ",
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <p>{params?.row?.status_note ? params?.row?.status_note : "-"}</p>
          </Box>
        );
      },
    },
  ];

  const onSubmit = (formValues) => {
    // return api
    //   .post(`/api/`, formValues)
    //   .then(({ data }) => {})
    //   .catch(({ response }) => {
    //     if (response.status === 422) {
    //       const errors = _.mapValues(response.data.errors, (e) => e[0]);
    //     } else {
    //       return {
    //         [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
    //       };
    //     }
    //   });
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        ข้อมูลบัญชีธนาคาร
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "90vh",
          minHeight: "450px",
          maxHeight: "auto",
        }}
      >
        <Form
          onSubmit={onSubmit}
          component={AccountListlHistoryForm}
          handleCloseModalHistory={handleCancel}
          data={account}
        />
        {/* <Box sx={{ minHeight:450,maxHeight:"800px",width:"100%"}}> */}
        <DataGrid
          disableColumnMenu
          getRowId={(row) => row.id}
          columns={columns}
          rows={account?.list_request?.row ?? []}
          loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={total}
          pagination
          paginationMode="server"
          {...rowsState}
          onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
          onPageSizeChange={(pageSize) =>
            setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
          }
          sx={{
            width: "100%",
            height: "90vh",
            minHeight: "450px",
            maxHeight: "auto",
          }}

        // autoHeight
        />
        {/* </Box> */}
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>
            ปิด
          </Button>
        </Stack>
      </Paper>
    </>
  );
}
